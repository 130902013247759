import {
  AppNotification,
  AppNotificationActionsTypes,
  CLEAR_CONSUMER_APP_NOTIFICATIONS_SUCCESS,
  DELETE_CONSUMER_APP_NOTIFICATIONS_SUCCESS,
  GET_ALL_CONSUMER_APP_NOTIFICATIONS_SUCCESS,
} from './types'

export function getAllConsumerAppNotificationSuccess(appNotifications: AppNotification[]): AppNotificationActionsTypes {
  return {
    type: GET_ALL_CONSUMER_APP_NOTIFICATIONS_SUCCESS,
    payload: {
      appNotifications: appNotifications,
    },
  }
}

export function deleteConsumerAppNotificationSuccess(appNotifications: AppNotification[]): AppNotificationActionsTypes {
  return {
    type: DELETE_CONSUMER_APP_NOTIFICATIONS_SUCCESS,
    payload: {
      appNotifications: appNotifications,
    },
  }
}

export function clearAllConsumerAppNotificationSuccess(): AppNotificationActionsTypes {
  return {
    type: CLEAR_CONSUMER_APP_NOTIFICATIONS_SUCCESS,
  }
}
