import { RootState } from '../index'
import { ConsumerPresenceActionTypes, ConsumerPresenceState, POST_CONSUMER_PRESENCE_SUCCESS } from './types'

const initialState: ConsumerPresenceState = {
  idOnly: null,
}

export function consumerPresenceReducer(
  state: ConsumerPresenceState = initialState,
  action: ConsumerPresenceActionTypes,
): ConsumerPresenceState {
  switch (action.type) {
    case POST_CONSUMER_PRESENCE_SUCCESS:
      return {
        ...state,
        idOnly: action.payload.idOnly,
      }
    default:
      return state
  }
}

export const currentPresence = (state: RootState) => {
  return state.consumerPresenceReducer.idOnly
}
