import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../store'

const useInitialLoading = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    tenantConfig,
    firebaseInitialized,
    brandingRequestCompleted,
    firebaseUser,
    currentUser,
    whoAmIRequestCompleted,
    consumerRequestCompleted,
  } = useSelector(
    (state: RootState) => ({
      tenantConfig: state.appConfigReducer.tenantConfig,
      firebaseInitialized: state.authReducer.firebaseInitialized,
      brandingRequestCompleted: state.brandingReducer.isRequestCompleted,
      firebaseUser: state.authReducer.firebaseUser,
      currentUser: state.authReducer.user,
      whoAmIRequestCompleted: state.authReducer.isWhoAmIRequestCompleted,
      consumerRequestCompleted: state.authReducer.isConsumerRequestCompleted,
    }),
    shallowEqual,
  )

  useEffect(() => {
    let isLoading: boolean = true
    if (tenantConfig) {
      if (firebaseInitialized && brandingRequestCompleted) {
        if (firebaseUser) {
          if (whoAmIRequestCompleted) {
            if (currentUser) {
              if (consumerRequestCompleted) {
                isLoading = false
              }
            } else {
              isLoading = false
            }
          }
        } else {
          isLoading = false
        }
      }
    }
    setIsLoading(isLoading)
  }, [
    tenantConfig,
    firebaseInitialized,
    brandingRequestCompleted,
    firebaseUser,
    whoAmIRequestCompleted,
    currentUser,
    consumerRequestCompleted,
  ])

  return isLoading
}

export default useInitialLoading
