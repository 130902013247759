import axios from 'axios'
import { AppThunk } from '..'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { getConsumerOrderedFacilitiesRequest, getConsumerOrderedFacilitiesSuccess } from './actions'
import { GET_CONSUMER_ORDERED_FACILITIES_API_RESOURCE_PATH } from './types'

export const getConsumerOrderedFacilities =
  (consumerId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(getConsumerOrderedFacilitiesRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    await axios
      .get(
        GET_CONSUMER_ORDERED_FACILITIES_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ),
        authConfig,
      )
      .then((response) => {
        dispatch(getConsumerOrderedFacilitiesSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }
