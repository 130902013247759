import { SESSION_STORAGE_KEY_PARKING_QUOTE_ID } from '../../common/types'
import {
  GET_QUOTE_FAILURE,
  GET_QUOTE_REQUEST,
  GET_QUOTE_SUCCESS,
  ParkingQuoteDto,
  RESET_QUOTE,
  SET_QUOTE_EXPIRED,
} from './types'

export interface QuoteState {
  quoteExpired: boolean
  quote: ParkingQuoteDto | null
  error: boolean
}

interface getQuoteRequestAction {
  type: typeof GET_QUOTE_REQUEST
}

interface getQuoteSuccessAction {
  type: typeof GET_QUOTE_SUCCESS
  payload: {
    quote: ParkingQuoteDto
  }
}

interface getQuoteFailureAction {
  type: typeof GET_QUOTE_FAILURE
}

interface setQuoteExpiredAction {
  type: typeof SET_QUOTE_EXPIRED
}

interface resetQuoteAction {
  type: typeof RESET_QUOTE
}

export type QuoteActionTypes =
  | getQuoteRequestAction
  | getQuoteSuccessAction
  | getQuoteFailureAction
  | setQuoteExpiredAction
  | resetQuoteAction

const initialState: QuoteState = {
  quoteExpired: false,
  quote: null,
  error: false,
}

export function quoteReducer(state: QuoteState = initialState, action: QuoteActionTypes): QuoteState {
  switch (action.type) {
    case GET_QUOTE_REQUEST:
      return {
        ...state,
        quoteExpired: false,
        error: false,
      }
    case GET_QUOTE_SUCCESS:
      sessionStorage.setItem(SESSION_STORAGE_KEY_PARKING_QUOTE_ID, action.payload.quote.id)
      return {
        ...state,
        quote: action.payload.quote,
        error: false,
      }
    case GET_QUOTE_FAILURE:
      return {
        ...state,
        quote: null,
        error: true,
      }
    case SET_QUOTE_EXPIRED:
      return {
        ...state,
        quoteExpired: true,
      }
    case RESET_QUOTE:
      return initialState
    default:
      return state
  }
}
