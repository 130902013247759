import ClearIcon from '@mui/icons-material/Clear'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { getSortedTerritories } from '../../../../common/utility'
import { RootState } from '../../../../store'
import { Territory } from '../../../../store/geo-feature/territories/types'

interface PlateIssuerSelectProps {
  selectedPlateIssuer: string | null
  onChangePlateIssuer: (event: SelectChangeEvent<string | null>) => void
  onClickClear: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  openPlateIssuerSelect: boolean
  setOpenPlateIssuerSelect: React.Dispatch<React.SetStateAction<boolean>>
  forExistingVehicleDialog?: boolean
}

const PlateIssuerSelect: React.FC<PlateIssuerSelectProps> = (props: PlateIssuerSelectProps) => {
  const {
    selectedPlateIssuer,
    onChangePlateIssuer,
    onClickClear,
    openPlateIssuerSelect,
    setOpenPlateIssuerSelect,
    forExistingVehicleDialog,
  } = props

  const { territories } = useSelector(
    (state: RootState) => ({
      territories: state.territoriesReducer.territories,
    }),
    shallowEqual,
  )

  return (
    <FormControl fullWidth onClick={() => setOpenPlateIssuerSelect(!openPlateIssuerSelect)}>
      <Select
        displayEmpty
        variant="outlined"
        name="plateIssuer"
        value={selectedPlateIssuer}
        open={openPlateIssuerSelect}
        onChange={onChangePlateIssuer}
        MenuProps={{
          className: 'Mui-custom-select',
        }}
        className={selectedPlateIssuer ? 'selected-plate-issuer' : undefined}
        IconComponent={
          selectedPlateIssuer
            ? () => <ClearIcon fontSize="small" onClick={onClickClear} style={{ color: '#0000008a' }} />
            : () => null
        }
        onClose={() => setOpenPlateIssuerSelect(false)}
      >
        {getSortedTerritories(territories).map((territory: Territory) => (
          <MenuItem key={territory.id} value={territory.code}>
            {territory.code}
          </MenuItem>
        ))}
        <MenuItem value="" key="blank" style={{ display: 'none' }}>
          {forExistingVehicleDialog ? (
            <span className="custom-placeholder">-</span>
          ) : (
            <span className="custom-placeholder">&nbsp;&nbsp;&nbsp;&nbsp;-</span>
          )}
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default PlateIssuerSelect
