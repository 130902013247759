import axios from 'axios'
import { AppThunk } from '..'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { getUrlConfigRequest, getUrlConfigSuccess } from './actions'
import { GET_URL_CONFIG_API_RESOURCE_PATH } from './types'

export const getUrlConfig = (): AppThunk<any> => async (dispatch, getState) => {
  dispatch(getUrlConfigRequest())
  const { operatorId } = findConfiguration(getState())
  const authConfig = await buildAxiosConfig(getState())
  await axios
    .get(GET_URL_CONFIG_API_RESOURCE_PATH.replace(':operatorId', operatorId), authConfig)
    .then((response) => {
      dispatch(getUrlConfigSuccess(response.data))
    })
    .catch((error: ErrorResponse | string) => {
      if (typeof error === 'object') {
        dispatch(setNotification(NotificationType.ERROR, error.message))
      }
    })
}
