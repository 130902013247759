import { MessagePayload, getMessaging, isSupported, onMessage } from 'firebase/messaging'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import deviceFingerPrintGenerator from '../../services/device-finger-print'
import { requestForToken } from '../../services/firebase-api/firebase'
import { putNotificationToIndexDB } from '../../services/idexed-db'
import { getAllNotificationFromIndexedDb } from '../../store/app-notification/thunk'
import { LocalStorageAppNotificationItem } from '../../store/app-notification/types'
import { tenantConfig } from '../../store/app/reducers'
import { currentConsumer } from '../../store/auth/reducers'
import { postPresence } from '../../store/consumer-presence/thunks'
import { MemberPresence } from '../../store/consumer-presence/types'

const useConsumerNotifications = () => {
  const dispatch = useDispatch()
  const config = useSelector(tenantConfig)
  const consumer = useSelector(currentConsumer)
  const [firebaseToken, setFirebaseToken] = useState<string | null>(null)
  const [notificationAllowed, setNotificationAllowed] = useState<boolean>(false)

  useEffect(() => {
    const initializePushNotifications = async () => {
      if (consumer) {
        try {
          if (await isSupported()) {
            const messaging = getMessaging()
            onMessage(messaging, handleMessage)
            console.info('FCM: Firebase messaging is supported, enabling push notification.')
            if ('Notification' in window) {
              const permission = await Notification.requestPermission()
              if (permission === 'granted') {
                setNotificationAllowed(true)
                console.log('FCM: Notification permission ==>' + permission)
              } else {
                console.error('FCM: Notification permission ==>' + permission)
              }
            } else {
              console.error('FCM: initializePushNotifications - Firebase push notification is not supported.')
            }
          } else {
            console.error('FCM: initializePushNotifications - Firebase messaging is not supported.')
          }
        } catch (error) {
          console.error(
            'FCM: initializePushNotifications - Error while push notification setup ==>',
            JSON.stringify(error),
          )
        }
      }
    }
    initializePushNotifications()

    // eslint-disable-next-line
  }, [consumer])

  useEffect(() => {
    if (config && notificationAllowed) {
      const getFirebaseRequestForToken = async () => {
        if (await isSupported()) {
          const messaging = getMessaging()
          try {
            if (messaging) {
              requestForToken(config.firebaseMessaging.vapidKey, messaging).then((token) => setFirebaseToken(token))
            }
          } catch (error) {
            console.error(
              'FCM: getFirebaseRequestForToken - Firebase messaging is not supported ==>' + JSON.stringify(error),
            )
          }
        } else {
          console.error('FCM: getFirebaseRequestForToken - Firebase messaging is not supported.')
        }
      }
      getFirebaseRequestForToken()
    }
  }, [config, notificationAllowed])

  useEffect(() => {
    if (firebaseToken && consumer) {
      const deviceFingerprint = deviceFingerPrintGenerator()
      const memberPresence: MemberPresence = {
        id: null,
        messagingToken: firebaseToken,
        deviceFingerprint: deviceFingerprint,
        deviceDetails: null,
        userAgent: null,
      }
      dispatch(postPresence(consumer.id, memberPresence))
    }
    // eslint-disable-next-line
  }, [firebaseToken, consumer])

  useEffect(() => {
    if (notificationAllowed && consumer) {
      dispatch(getAllNotificationFromIndexedDb())
      document.addEventListener('visibilitychange', onVisibilityChange)
      return () => document.removeEventListener('visibilitychange', onVisibilityChange)
    }
    // eslint-disable-next-line
  }, [notificationAllowed, consumer, dispatch])

  const handleMessage = (payload: MessagePayload) => {
    console.info('Message received in foreground: ' + JSON.stringify(payload))
    const messagePayLoad = payload as MessagePayload
    if (messagePayLoad) {
      const data = messagePayLoad.data
      console.log(JSON.stringify(data))
      if (data) {
        console.log('consumer.id - ' + data['consumer.id'])
        console.log('title - ' + data['title'])
        console.log('body - ' + data['body'])
        console.log('agreement.id - ' + data['agreement.id'])
        console.log('agreement.authority-type - ' + data['agreement.authority-type'])
        const dbItem: LocalStorageAppNotificationItem = {
          consumerId: data['consumer.id'],
          appNotification: {
            messageId: messagePayLoad.messageId,
            notification: {
              title: data['title'],
              body: data['body'],
              agreementId: data['agreement.id'],
              orderId: data['agreement.parentId'],
              agreementReference: data['agreement.reference'],
              agreementAuthorityType: data['agreement.authority-type'],
            },
          },
        }
        console.log(JSON.stringify(dbItem))
        putNotificationToIndexDB(dbItem).then((_) => {
          dispatch(getAllNotificationFromIndexedDb())
        })
      }
    }
  }

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      console.log('Tab reopened, refetch the data!')
      console.log('get notification')
      dispatch(getAllNotificationFromIndexedDb())
    }
  }
}

export default useConsumerNotifications
