import { LocalStorageAppNotification, LocalStorageAppNotificationItem } from '../../store/app-notification/types'

export const indexedDb = 'nuevopark.push.notification.messages.db'
export const indexedStore = 'nuevopark.push.notification.messages.store'

export function createIndexedDb(): Promise<IDBDatabase | null> {
  return new Promise((resolve, reject) => {
    const indexedDbInstance =
      (window as any).indexedDB ||
      (window as any).mozIndexedDB ||
      (window as any).webkitIndexedDB ||
      (window as any).msIndexedDB

    const dbOpenRequest = indexedDbInstance.open(indexedDb, 4)
    dbOpenRequest.onerror = (event: any) => {
      console.error('Open data base failed')
      return reject(null)
    }
    dbOpenRequest.onsuccess = (event: any) => {
      return resolve(dbOpenRequest.result)
    }
    dbOpenRequest.onupgradeneeded = (event: any) => {
      const db = event.target?.result
      if (db) {
        db.createObjectStore(indexedStore)
      }
    }
  })
}

export function putNotificationToIndexDB(localStorageAppNotification: LocalStorageAppNotificationItem): Promise<void> {
  return createIndexedDb().then((db: IDBDatabase | null) => {
    return new Promise((resolve, reject) => {
      if (db) {
        const transaction = db.transaction([indexedStore], 'readwrite')

        const objectStore = transaction.objectStore(indexedStore)

        const consumerInfo: IDBRequest<LocalStorageAppNotification> = objectStore.get(
          localStorageAppNotification.consumerId,
        )

        consumerInfo.onsuccess = function () {
          if (consumerInfo.result) {
            const storageItem: LocalStorageAppNotification = {
              consumerId: localStorageAppNotification.consumerId,
              appNotifications: consumerInfo.result.appNotifications.concat(
                localStorageAppNotification.appNotification,
              ),
            }
            const result = objectStore.put(storageItem, localStorageAppNotification.consumerId)
            result.onsuccess = () => {
              console.info('put notification info success')
            }
            result.onerror = () => {
              console.error('put notification info failed')
            }
          } else {
            const storageItem: LocalStorageAppNotification = {
              consumerId: localStorageAppNotification.consumerId,
              appNotifications: Array.of(localStorageAppNotification.appNotification),
            }
            const result = objectStore.add(storageItem, localStorageAppNotification.consumerId)
            result.onsuccess = () => {
              console.info('add notification info success')
            }
            result.onerror = () => {
              console.error('add notification info failed')
            }
          }
        }
        consumerInfo.onerror = function () {
          console.error('Error when consumer info loading')
        }

        transaction.oncomplete = () => {
          return resolve()
        }
        // Handler for any unexpected error
        transaction.onerror = () => {
          console.error('error when processing db')
          return reject()
        }
      } else {
        console.error('creating db is returning null')
        return reject()
      }
    })
  })
}
