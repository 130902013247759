import {
  GET_PARKING_QUOTES_AND_EXIT_TIMES_REQUEST,
  GET_PARKING_QUOTES_AND_EXIT_TIMES_SUCCESS,
  ParkingQuotesAndExitTimesActionTypes,
  ParkingQuotesAndExitTimesRes,
  RESET_PARKING_QUOTES_AND_EXIT_TIMES,
} from './types'

export function getParkingQuotesAndExitTimesRequest(): ParkingQuotesAndExitTimesActionTypes {
  return {
    type: GET_PARKING_QUOTES_AND_EXIT_TIMES_REQUEST,
  }
}

export function getParkingQuotesAndExitTimesSuccess(
  parkingQuotesAndExitTimes: ParkingQuotesAndExitTimesRes,
): ParkingQuotesAndExitTimesActionTypes {
  return {
    type: GET_PARKING_QUOTES_AND_EXIT_TIMES_SUCCESS,
    payload: {
      parkingQuotesAndExitTimes: parkingQuotesAndExitTimes,
    },
  }
}

export function resetParkingQuotesAndExitTimes(): ParkingQuotesAndExitTimesActionTypes {
  return {
    type: RESET_PARKING_QUOTES_AND_EXIT_TIMES,
  }
}
