import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { currentConsumer } from '../../store/auth/reducers'
import { resetEntitlements } from '../../store/entitlements/actions'
import { getEntitlements } from '../../store/entitlements/thunks'
import { resetConsumerAgreements } from '../../store/my-agreements/actions'
import { getConsumerAgreements } from '../../store/my-agreements/thunks'
import { ROUTE_PATH_SIGN_IN } from '../constants'

const useConsumerAgreementsEntitlements = () => {
  const dispatch = useDispatch()
  const location = window.location

  const {
    agreementsLoading,
    agreements,
    activeAgreementSuccess,
    entitlementsLoading,
    entitlements,
    entitlementsSuccess,
  } = useSelector(
    (state: RootState) => ({
      agreementsLoading: state.myAgreementsReducer.myAgreementsLoading,
      agreements: state.myAgreementsReducer.myAgreements,
      activeAgreementSuccess: state.myAgreementsReducer.myAgreementsSuccess,
      entitlementsLoading: state.entitlementsReducer.loading,
      entitlements: state.entitlementsReducer.entitlements,
      entitlementsSuccess: state.entitlementsReducer.success,
    }),
    shallowEqual,
  )
  const consumer = useSelector(currentConsumer)

  useEffect(() => {
    if (consumer) {
      if (
        location.pathname !== ROUTE_PATH_SIGN_IN &&
        agreements.length === 0 &&
        !agreementsLoading &&
        !activeAgreementSuccess
      ) {
        dispatch(getConsumerAgreements(consumer.id))
      }
    } else {
      dispatch(resetConsumerAgreements())
    }
    // eslint-disable-next-line
  }, [consumer, agreementsLoading, activeAgreementSuccess])

  useEffect(() => {
    if (consumer) {
      if (
        location.pathname !== ROUTE_PATH_SIGN_IN &&
        entitlements.length === 0 &&
        !entitlementsLoading &&
        !entitlementsSuccess
      ) {
        dispatch(getEntitlements(consumer.id))
      }
    } else {
      dispatch(resetEntitlements())
    }
    // eslint-disable-next-line
  }, [consumer, entitlementsLoading, entitlementsSuccess])
}

export default useConsumerAgreementsEntitlements
