import {
  CarParkActionTypes,
  CarParkSearchState,
  GET_CAR_PARK_REQUEST,
  GET_CAR_PARK_SUCCESS,
  RESET_CAR_PARK,
} from './types'

const initialState: CarParkSearchState = {
  carPark: null,
}

export function carParkSearchReducer(
  state: CarParkSearchState = initialState,
  action: CarParkActionTypes,
): CarParkSearchState {
  switch (action.type) {
    case GET_CAR_PARK_REQUEST:
      return {
        ...state,
        carPark: null,
      }
    case GET_CAR_PARK_SUCCESS:
      return {
        ...state,
        carPark: action.payload.carPark,
      }
    case RESET_CAR_PARK:
      return initialState
    default:
      return state
  }
}
