import {
  CarparkLongInfoActionTypes,
  CarparkLongInfoState,
  GET_CARPARK_LONG_INFO_FAILURE,
  GET_CARPARK_LONG_INFO_REQUEST,
  GET_CARPARK_LONG_INFO_SUCCESS,
  RESET_CARPARK_LONG_INFO,
} from './types'

const initialState: CarparkLongInfoState = {
  error: false,
  carparkLongInfo: null,
}

export function carparkLongInfoReducer(
  state: CarparkLongInfoState = initialState,
  action: CarparkLongInfoActionTypes,
): CarparkLongInfoState {
  switch (action.type) {
    case GET_CARPARK_LONG_INFO_REQUEST:
      return {
        ...state,
        error: false,
        carparkLongInfo: null,
      }
    case GET_CARPARK_LONG_INFO_SUCCESS:
      return {
        ...state,
        carparkLongInfo: action.payload.carparkLongInfo,
      }
    case GET_CARPARK_LONG_INFO_FAILURE:
      return {
        ...state,
        error: true,
      }
    case RESET_CARPARK_LONG_INFO:
      return initialState
    default:
      return state
  }
}
