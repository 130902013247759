import {
  EntitlementVehicleActionTypes,
  EntitlementVehicleState,
  GET_ENTITLEMENT_VEHICLE_REQUEST,
  GET_ENTITLEMENT_VEHICLE_SUCCESS,
} from './types'

const initialState: EntitlementVehicleState = {
  vehicles: [],
}

export function entitlementVehicleReducer(
  state: EntitlementVehicleState = initialState,
  action: EntitlementVehicleActionTypes,
): EntitlementVehicleState {
  switch (action.type) {
    case GET_ENTITLEMENT_VEHICLE_REQUEST:
      return {
        ...state,
        vehicles: [],
      }
    case GET_ENTITLEMENT_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: action.payload.vehicles,
      }
    default:
      return state
  }
}
