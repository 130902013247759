import { DayOfWeek } from '../../common/types'

export enum AccessibleReason {
  AccessRestriction = 'AccessRestriction',
  Clearway = 'Clearway',
  Closed = 'Closed',
  EventParking = 'EventParking',
  PermitParking = 'PermitParking',
  ResidentParking = 'ResidentParking',
  Restricted = 'Restricted',
  Unrestricted = 'Unrestricted',
  BusLane = 'BusLane',
  BusZone = 'BusZone',
  TaxiZone = 'TaxiZone',
  TimeLimited = 'TimeLimited',
  NoStopping = 'NoStopping',
  NoParking = 'NoParking',
}

export interface OperatingHour {
  id: string
  dayOfWeek: DayOfWeek
  dayOfWeekOrdinal: number
  version: number
  wheelMaxStayMinutes: number
  accessible: boolean
  fromMinutesFormatted: string
  toMinutesFormatted: string
  accessibleReason: AccessibleReason | null
}

export interface OperatingHoursState {
  operatingHours: OperatingHour[]
}

export const GET_FACILITY_OPERATING_HOURS_API_RESOURCE_PATH =
  '/public/operators/:operatorId/facilities/:facilityId/operating-hours'

export const GET_FACILITY_OPERATING_HOURS_REQUEST = 'GET_FACILITY_OPERATING_HOURS_REQUEST'
export const GET_FACILITY_OPERATING_HOURS_SUCCESS = 'GET_FACILITY_OPERATING_HOURS_SUCCESS'

export interface getFacilityOperatingHoursRequest {
  type: typeof GET_FACILITY_OPERATING_HOURS_REQUEST
}

export interface getFacilityOperatingHoursSuccess {
  type: typeof GET_FACILITY_OPERATING_HOURS_SUCCESS
  payload: {
    data: OperatingHour[]
  }
}

export type OperatingHoursActionTypes = getFacilityOperatingHoursRequest | getFacilityOperatingHoursSuccess
