import { ConsumerPresenceActionTypes, POST_CONSUMER_PRESENCE_SUCCESS } from './types'
import { IdOnly } from '../basket/types'

export function postConsumerPresenceSuccess(data: IdOnly): ConsumerPresenceActionTypes {
  return {
    type: POST_CONSUMER_PRESENCE_SUCCESS,
    payload: {
      idOnly: data,
    },
  }
}
