import {
  GET_GUEST_AGREEMENTS_REQUEST,
  GET_GUEST_AGREEMENTS_SUCCESS,
  GUEST_ORDER_LOOKUP_REQUEST,
  GUEST_ORDER_LOOKUP_SUCCESS,
  GuestAgreementActionTypes,
  GuestAgreementState,
  RESET_GUEST_AGREEMENTS,
  RESET_GUEST_ORDER_LOOKUP,
} from './types'

const initialState: GuestAgreementState = {
  loading: false,
  success: false,
  error: null,
  orderId: null,
  orderAgreements: null,
}

export function guestAgreementsReducer(
  state: GuestAgreementState = initialState,
  action: GuestAgreementActionTypes,
): GuestAgreementState {
  switch (action.type) {
    case GUEST_ORDER_LOOKUP_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        orderId: null,
      }
    case GUEST_ORDER_LOOKUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        orderId: action.payload.orderId,
      }
    case GET_GUEST_AGREEMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
        orderAgreements: null,
      }
    case GET_GUEST_AGREEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        orderAgreements: action.payload.orderAgreements,
      }
    case RESET_GUEST_AGREEMENTS:
      return {
        ...state,
        loading: false,
        success: false,
        error: null,
        orderAgreements: null,
      }
    case RESET_GUEST_ORDER_LOOKUP:
      return {
        ...state,
        loading: false,
        success: false,
        error: null,
        orderId: null,
      }
    default:
      return state
  }
}
