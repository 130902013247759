import {
  GEO_FEATURE_PROXIMITY_REQUEST,
  GEO_FEATURE_PROXIMITY_SUCCESS,
  GeoFeatureProximityActionTypes,
  GeoFeatureProximityState,
  RESET_GEO_FEATURE_PROXIMITY,
} from './types'

const initialState: GeoFeatureProximityState = {
  geoFeatures: null,
}

export function geoFeatureProximityReducer(
  state: GeoFeatureProximityState = initialState,
  action: GeoFeatureProximityActionTypes,
): GeoFeatureProximityState {
  switch (action.type) {
    case GEO_FEATURE_PROXIMITY_REQUEST:
      return {
        ...state,
      }
    case GEO_FEATURE_PROXIMITY_SUCCESS:
      return {
        ...state,
        geoFeatures: action.payload.data,
      }
    case RESET_GEO_FEATURE_PROXIMITY:
      return initialState
    default:
      return state
  }
}
