import { MyActivityListDto } from '../my-agreements/types'

export interface GuestAgreementsListState {
  guestAgreementsList: MyActivityListDto[]
  isRequestedCompleted: boolean
}

export const GET_GUEST_AGREEMENTS_LIST_API_RESOURCE_PATH = 'public/operators/:operatorId/my-active-order-agreements'

export const GET_GUEST_AGREEMENTS_LIST_REQUEST = 'GET_GUEST_AGREEMENTS_LIST_REQUEST'
export const GET_GUEST_AGREEMENTS_LIST_SUCCESS = 'GET_GUEST_AGREEMENTS_LIST_SUCCESS'
export const RESET_GUEST_AGREEMENTS_LIST = 'RESET_GUEST_AGREEMENTS_LIST'

export interface getGuestAgreementsListRequest {
  type: typeof GET_GUEST_AGREEMENTS_LIST_REQUEST
}

export interface getGuestAgreementsListSuccess {
  type: typeof GET_GUEST_AGREEMENTS_LIST_SUCCESS
  payload: {
    data: MyActivityListDto[]
  }
}

export interface resetGuestAgreementsList {
  type: typeof RESET_GUEST_AGREEMENTS_LIST
}

export type GuestAgreementsListActionTypes =
  | getGuestAgreementsListRequest
  | getGuestAgreementsListSuccess
  | resetGuestAgreementsList
