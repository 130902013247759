import { User as FireBaseUser } from 'firebase/auth'
import { ConsumerAccessCredential } from '../access-credentials/types'
import { ChargeAccDetails } from '../charge-acc/types'
import { ConsumerCards } from '../consumer-cards/types'
import { ConsumerVehicle } from '../consumer-vehicle/types'
import { ConsumerFavouriteCarPark } from '../my-favourite-carparks/types'
import {
  AuthActionTypes,
  Consumer,
  ConsumerFullProfile,
  ConsumerSaveResponse,
  GET_CHARGE_ACCOUNT_DETAILS_REQUEST,
  GET_CHARGE_ACCOUNT_DETAILS_SUCCESS,
  GET_CONSUMER_CARDS_SUCCESS,
  GET_CONSUMER_FAVOURITE_CARPARKS_SUCCESS,
  GET_CONSUMER_FULL_PROFILE_REQUEST,
  GET_CONSUMER_FULL_PROFILE_SUCCESS,
  GET_CONSUMER_VEHICLE_SUCCESS,
  SAVE_CONSUMER_REQUEST,
  SAVE_CONSUMER_SUCCESS,
  SET_FIREBASE_INITIALIZED,
  SET_LOGGED_OFF,
  SET_LOGGED_ON,
  Settings,
  UPDATE_CONSUMER_ACCESS_CREDENTIALS,
  UPDATE_CONSUMER_NOTIFICATIONS_ENABLED,
  UPDATE_CONSUMER_SUCCESS,
  User,
  WHOAMI_REQUEST,
  WHOAMI_SUCCESS,
} from './types'

export function setLoggedOn(firebaseUser: FireBaseUser): AuthActionTypes {
  return {
    type: SET_LOGGED_ON,
    payload: {
      data: firebaseUser,
    },
  }
}

export function setLoggedOff(): AuthActionTypes {
  return {
    type: SET_LOGGED_OFF,
  }
}

export function whoAmiRequest(): AuthActionTypes {
  return {
    type: WHOAMI_REQUEST,
  }
}

export function whoAmiSuccess(user: User | null): AuthActionTypes {
  return {
    type: WHOAMI_SUCCESS,
    payload: {
      data: user,
    },
  }
}

export function saveConsumerRequest(): AuthActionTypes {
  return {
    type: SAVE_CONSUMER_REQUEST,
  }
}

export function saveConsumerSuccess(consumerSaveResponse: ConsumerSaveResponse): AuthActionTypes {
  return {
    type: SAVE_CONSUMER_SUCCESS,
    payload: {
      data: consumerSaveResponse,
    },
  }
}

export function getConsumerFullProfileRequest(): AuthActionTypes {
  return {
    type: GET_CONSUMER_FULL_PROFILE_REQUEST,
  }
}

export function getConsumerFullProfileSuccess(ConsumerFullProfile: ConsumerFullProfile | null): AuthActionTypes {
  return {
    type: GET_CONSUMER_FULL_PROFILE_SUCCESS,
    payload: {
      data: ConsumerFullProfile,
    },
  }
}

export function getConsumerCardsSuccess(data: ConsumerCards[]): AuthActionTypes {
  return {
    type: GET_CONSUMER_CARDS_SUCCESS,
    payload: {
      cards: data,
    },
  }
}

export function getConsumerFavouriteCarParksSuccess(data: ConsumerFavouriteCarPark[]): AuthActionTypes {
  return {
    type: GET_CONSUMER_FAVOURITE_CARPARKS_SUCCESS,
    payload: {
      favouriteCarParks: data,
    },
  }
}

export function getConsumerVehicleSuccess(data: ConsumerVehicle[]): AuthActionTypes {
  return {
    type: GET_CONSUMER_VEHICLE_SUCCESS,
    payload: {
      vehicles: data,
    },
  }
}

export function getChargeAccDetailsRequest(): AuthActionTypes {
  return {
    type: GET_CHARGE_ACCOUNT_DETAILS_REQUEST,
  }
}

export function getChargeAccDetailsSuccess(chargeAccDetails: ChargeAccDetails): AuthActionTypes {
  return {
    type: GET_CHARGE_ACCOUNT_DETAILS_SUCCESS,
    payload: {
      data: chargeAccDetails,
    },
  }
}

export function updateConsumerSuccess(updatedConsumer: Consumer): AuthActionTypes {
  return {
    type: UPDATE_CONSUMER_SUCCESS,
    payload: {
      data: updatedConsumer,
    },
  }
}

export function updateConsumerNotificationsEnabled(settings: Settings): AuthActionTypes {
  return {
    type: UPDATE_CONSUMER_NOTIFICATIONS_ENABLED,
    payload: {
      settings: settings,
    },
  }
}

export function updateConsumerAccessCredentialsSuccess(accessCredentials: ConsumerAccessCredential[]): AuthActionTypes {
  return {
    type: UPDATE_CONSUMER_ACCESS_CREDENTIALS,
    payload: {
      data: accessCredentials,
    },
  }
}

export function setFirebaseInitialized(): AuthActionTypes {
  return {
    type: SET_FIREBASE_INITIALIZED,
  }
}
