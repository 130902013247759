import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tenantConfig } from '../../store/app/reducers'
import { getPermitConfig } from '../../store/permit-config/thunks'

const usePermitConfig = () => {
  const dispatch = useDispatch()

  const tenantConfigState = useSelector(tenantConfig)

  useEffect(() => {
    if (tenantConfigState && tenantConfigState.tenant && tenantConfigState.tenant.code === 'parkone') {
      dispatch(getPermitConfig())
    }
    // eslint-disable-next-line
  }, [tenantConfigState])
}

export default usePermitConfig
