import {
  CHECK_CONSUMER_VEHICLE_EXISTS_REQUEST,
  CHECK_CONSUMER_VEHICLE_EXISTS_SUCCESS,
  ConsumerVehicleActionTypes,
  ConsumerVehicleState,
  RESET_CHECK_CONSUMER_VEHICLE_EXISTS,
  RESET_SAVE_CONSUMER_VEHICLE,
  SAVE_CONSUMER_VEHICLE_REQUEST,
  SAVE_CONSUMER_VEHICLE_SUCCESS,
} from './types'

const initialState: ConsumerVehicleState = {
  saveVehicleSuccess: false,
  savedVehicleId: null,
  checkConsumerVehicleExists: null,
}

export function consumerVehicleReducer(
  state: ConsumerVehicleState = initialState,
  action: ConsumerVehicleActionTypes,
): ConsumerVehicleState {
  switch (action.type) {
    case SAVE_CONSUMER_VEHICLE_REQUEST:
      return {
        ...state,
        saveVehicleSuccess: false,
        savedVehicleId: null,
      }
    case SAVE_CONSUMER_VEHICLE_SUCCESS:
      return {
        ...state,
        saveVehicleSuccess: true,
        savedVehicleId: action.payload.data,
      }
    case CHECK_CONSUMER_VEHICLE_EXISTS_REQUEST:
      return {
        ...state,
        checkConsumerVehicleExists: null,
      }
    case CHECK_CONSUMER_VEHICLE_EXISTS_SUCCESS:
      return {
        ...state,
        checkConsumerVehicleExists: action.payload.data,
      }
    case RESET_SAVE_CONSUMER_VEHICLE:
      return {
        ...state,
        saveVehicleSuccess: false,
        savedVehicleId: null,
      }
    case RESET_CHECK_CONSUMER_VEHICLE_EXISTS:
      return {
        ...state,
        checkConsumerVehicleExists: null,
      }
    default:
      return state
  }
}
