export enum NotificationType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARN = 'WARN',
}

export interface NotificationState {
  type: NotificationType | null
  message: string
}

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

interface SetNotification {
  type: typeof SET_NOTIFICATION
  payload: {
    type: NotificationType
    message: string
  }
}

interface ClearNotification {
  type: typeof CLEAR_NOTIFICATION
}

export type NotificationActionTypes = SetNotification | ClearNotification
