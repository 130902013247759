import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)

export const TIME_FORMAT = 'h:mma'
export const DATE_FORMAT = 'DD/MM/YY'
export const DATE_DAY_FORMAT = 'dddd'
export const TIME_24H_FORMAT = 'HH:mm'
export const TIME_12H_FORMAT = 'hh:mm A'
export const ISO_TIME_FORMAT = 'HH:mm:ss'
export const MDY_DATE_FORMAT = 'MM/DD/YYYY'
export const YMD_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'DD/MM/YY h:mma'
export const DATE_FORMAT_MONTH_AND_TIME = 'DD MMM YY h:mma'
export const DATE_FORMAT_WITH_SPACE = 'DD MMM YYYY'
export const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const DATE_FORMAT_FOUR_DIGIT_YEAR = 'DD/MM/YYYY'
export const DATE_FORMAT_DAY_OF_THE_WEEK = 'ddd DD/MM/YYYY'
export const MIDNIGHT_TIME_SLOT = '11:59 PM'

export const getCurrentInstant = (): number => {
  return dayjs().utc().unix()
}

export const getCurrentInstantWithTimeZone = (timeZone: string): number => {
  return dayjs().tz(timeZone).unix()
}

export const getCurrentMidnightInstant = (): number => {
  return dayjs().set('hour', 0).set('minute', 0).set('second', 0).utc().unix()
}

export const getCurrentTimeInMinutes = (): number => {
  return dayjs().get('hour') * 60 + dayjs().get('minute')
}

export const checkIsActiveInstant = (startInstant: number, finishInstant: number): boolean => {
  const currentInstant = getCurrentInstant()
  return startInstant <= currentInstant && currentInstant <= finishInstant
}

export const isActiveEntitlementDate = (startInstant: number, finishInstant?: number): boolean => {
  const currentInstant = getCurrentMidnightInstant()
  if (startInstant && finishInstant) {
    return startInstant <= currentInstant && currentInstant <= finishInstant
  } else {
    return startInstant <= currentInstant
  }
}

export const isActiveEntitlementSlot = (fromMinutes: number, toMinutes: number): boolean => {
  const currentMinutes = getCurrentTimeInMinutes()
  return fromMinutes <= currentMinutes && currentMinutes <= toMinutes
}

export const mobilePickerOptionGroups: Record<string, string[]> = {
  level: [
    '00:00 AM',
    '00:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '04:30 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
    'Midnight',
  ],
}

export const isMidnightTime = (time: Date): boolean => {
  let formattedTime = dayjs(time).format(TIME_12H_FORMAT)
  return formattedTime === MIDNIGHT_TIME_SLOT
}

type DateTimeComponents = {
  year: number
  month: number
  day: number
  hour: number
  minute: number
  second: number
}

export const parseISODateTime = (dateStr: string): DateTimeComponents => {
  const [date, time] = dateStr.split('T')
  const [year, month, day] = date.split('-').map(Number)
  const [hour, minute, second] = time.split(':').map(Number)
  return { year, month, day, hour, minute, second }
}

export const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}

export const calculateTotalMinutes = (dateTime: DateTimeComponents): number => {
  const monthsDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  // Adjust February for leap years
  if (isLeapYear(dateTime.year)) {
    monthsDays[1] = 29
  }

  // Calculate total days from a fixed point (e.g., year 0)
  let totalDays = 0
  for (let y = 0; y < dateTime.year; y++) {
    totalDays += isLeapYear(y) ? 366 : 365
  }
  for (let m = 0; m < dateTime.month - 1; m++) {
    totalDays += monthsDays[m]
  }
  totalDays += dateTime.day - 1

  // Convert total days to total minutes
  const totalMinutes = totalDays * 24 * 60 + dateTime.hour * 60 + dateTime.minute + dateTime.second / 60
  return totalMinutes
}
