import { RootState } from '../index'
import {
  GET_PERMIT_CONFIG_REQUEST,
  GET_PERMIT_CONFIG_SUCCESS,
  PermitConfigActionTypes,
  PermitConfigState,
} from './types'

const initialState: PermitConfigState = {
  permitConfig: null,
}

export function permitConfigReducer(
  state: PermitConfigState = initialState,
  action: PermitConfigActionTypes,
): PermitConfigState {
  switch (action.type) {
    case GET_PERMIT_CONFIG_REQUEST:
      return {
        ...state,
        permitConfig: null,
      }
    case GET_PERMIT_CONFIG_SUCCESS:
      return {
        ...state,
        permitConfig: action.payload.data,
      }
    default:
      return state
  }
}

export const permitConfigState = (state: RootState) => state.permitConfigReducer.permitConfig
