import {
  ConsumerAgreementActionTypes,
  GET_CONSUMER_AGREEMENTS_REQUEST,
  GET_CONSUMER_AGREEMENTS_SUCCESS,
  GET_CONSUMER_AGREEMENT_REQUEST,
  GET_CONSUMER_AGREEMENT_SUCCESS,
  GET_CONSUMER_INACTIVE_AGREEMENTS_REQUEST,
  GET_CONSUMER_INACTIVE_AGREEMENTS_SUCCESS,
  GET_ORDER_AGREEMENT_FAILURE,
  GET_ORDER_AGREEMENT_REQUEST,
  GET_ORDER_AGREEMENT_SUCCESS,
  InactiveOrderAgreement,
  MyActivityDto,
  MyActivityListDto,
  OrderAgreement,
  RESET_CONSUMER_AGREEMENT,
  RESET_CONSUMER_AGREEMENTS,
  RESET_CONSUMER_INACTIVE_AGREEMENTS,
  RESET_ORDER_AGREEMENT,
} from './types'

export function getConsumerAgreementsRequest(): ConsumerAgreementActionTypes {
  return {
    type: GET_CONSUMER_AGREEMENTS_REQUEST,
  }
}

export function getConsumerAgreementsSuccess(data: MyActivityListDto[]): ConsumerAgreementActionTypes {
  return {
    type: GET_CONSUMER_AGREEMENTS_SUCCESS,
    payload: {
      myAgreements: data,
    },
  }
}

export function getConsumerInactiveAgreementsRequest(): ConsumerAgreementActionTypes {
  return {
    type: GET_CONSUMER_INACTIVE_AGREEMENTS_REQUEST,
  }
}

export function getConsumerInactiveAgreementsSuccess(
  inactiveOrderAgreements: InactiveOrderAgreement[],
): ConsumerAgreementActionTypes {
  return {
    type: GET_CONSUMER_INACTIVE_AGREEMENTS_SUCCESS,
    payload: {
      data: inactiveOrderAgreements,
    },
  }
}

export function getConsumerAgreementRequest(): ConsumerAgreementActionTypes {
  return {
    type: GET_CONSUMER_AGREEMENT_REQUEST,
  }
}

export function getConsumerAgreementSuccess(data: MyActivityDto | null): ConsumerAgreementActionTypes {
  return {
    type: GET_CONSUMER_AGREEMENT_SUCCESS,
    payload: {
      myAgreement: data,
    },
  }
}

export function resetConsumerAgreements(): ConsumerAgreementActionTypes {
  return {
    type: RESET_CONSUMER_AGREEMENTS,
  }
}

export function resetConsumerAgreement(): ConsumerAgreementActionTypes {
  return {
    type: RESET_CONSUMER_AGREEMENT,
  }
}

export function resetConsumerInactiveAgreements(): ConsumerAgreementActionTypes {
  return {
    type: RESET_CONSUMER_INACTIVE_AGREEMENTS,
  }
}

// Order Agreement Actions

export function getOrderAgreementRequest(): ConsumerAgreementActionTypes {
  return {
    type: GET_ORDER_AGREEMENT_REQUEST,
  }
}

export function getOrderAgreementSuccess(data: OrderAgreement): ConsumerAgreementActionTypes {
  return {
    type: GET_ORDER_AGREEMENT_SUCCESS,
    payload: {
      orderAgreement: data,
    },
  }
}

export function getOrderAgreementFailure(error: string): ConsumerAgreementActionTypes {
  return {
    type: GET_ORDER_AGREEMENT_FAILURE,
    payload: {
      error: error,
    },
  }
}

export function resetOrderAgreement(): ConsumerAgreementActionTypes {
  return {
    type: RESET_ORDER_AGREEMENT,
  }
}
