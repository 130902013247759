import { datadogRum } from '@datadog/browser-rum'
import { User as FireBaseUser, getAuth, onAuthStateChanged } from 'firebase/auth'
import { deleteToken, getMessaging, isSupported } from 'firebase/messaging'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setFirebaseInitialized, setLoggedOff, setLoggedOn, whoAmiSuccess } from '../../store/auth/actions'
import { fetchWhoAmI } from '../../store/auth/thunks'
import {
  ROUTE_PATH_SIGN_IN,
  ROUTE_PATH_SIGN_UP,
  ROUTE_PATH_VERIFY_CONSUMER_MOBILE,
  ROUTE_PATH_VERIFY_MOBILE,
} from '../constants'

const useAuthStateChange = () => {
  const dispatch = useDispatch()
  const location = window.location

  const { tenantConfig } = useSelector(
    (state: RootState) => ({
      tenantConfig: state.appConfigReducer.tenantConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (tenantConfig) {
      try {
        const auth = getAuth()
        onAuthStateChanged(auth, handleStateChange)
      } catch (error) {
        console.error('getAuth/onAuthStateChanged: ' + JSON.stringify(error))
        dispatch(setFirebaseInitialized())
      }
    }
    // eslint-disable-next-line
  }, [tenantConfig])

  async function handleStateChange(user: FireBaseUser | null) {
    console.info('auth state change ' + user)
    if (user) {
      dispatch(setLoggedOn(user))
      if (
        location.pathname !== ROUTE_PATH_SIGN_IN &&
        location.pathname !== ROUTE_PATH_SIGN_UP &&
        location.pathname !== ROUTE_PATH_VERIFY_MOBILE &&
        location.pathname !== ROUTE_PATH_VERIFY_CONSUMER_MOBILE
      ) {
        dispatch(fetchWhoAmI()) //call who am I
      } else {
        dispatch(whoAmiSuccess(null))
      }
    } else {
      console.info('handleStateChange: Firebase user not found. Hence, signing out and clearing the user from store')
      datadogRum.removeUserProperty('id')
      dispatch(setLoggedOff())
      if (await isSupported()) {
        const messaging = getMessaging()
        try {
          if (messaging) {
            deleteToken(messaging)
              .then((result: boolean) => {
                console.info('FCM: Delete message token ==>' + result)
              })
              .catch((error) => {
                console.error('FCM: handleStateChange - While deleting token ==>' + JSON.stringify(error))
              })
          }
        } catch (error) {
          console.error('FCM: handleStateChange - Firebase messaging is not supported ==>' + JSON.stringify(error))
        }
      } else {
        console.error('FCM: handleStateChange - Firebase messaging is not supported.')
      }
    }
    dispatch(setFirebaseInitialized())
  }
}

export default useAuthStateChange
