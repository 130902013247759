import { useEffect } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import setUpAxios from '../../config/axios'
import { setupFirebase } from '../../services/firebase-api/firebase'
import { RootState } from '../../store'
import { getTenantConfigThunk } from '../../store/app/thunks'
import { getBrandingDetails } from '../../store/branding/thunks'
import { getTerritories } from '../../store/geo-feature/territories/thunks'
import { getUrlConfig } from '../../store/url-config/thunks'
import { setupDatadog } from '../datadog'

const useAppConfig = () => {
  const dispatch = useDispatch()
  const hostName = window.location.hostname

  const { tenantConfig } = useSelector(
    (state: RootState) => ({
      tenantConfig: state.appConfigReducer.tenantConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    console.info('calling getTenantConfig', hostName)
    dispatch(getTenantConfigThunk(hostName))
  }, [hostName, dispatch])

  useEffect(() => {
    if (tenantConfig) {
      console.info('Info: isMobile', isMobile)
      console.info('Info: isTablet', isTablet)
      if (hostName === 'localhost' || (isMobile && !isTablet)) {
        dispatch(getUrlConfig())
        dispatch(getTerritories())
        dispatch(getBrandingDetails())
        console.info('Applying tenant config', tenantConfig)
        console.debug('axios setup complete')
        try {
          setupFirebase(tenantConfig.firebase, tenantConfig.firebaseAuth)
        } catch (error) {
          console.error(JSON.stringify(error) + 'setupFirebase')
        }
        console.debug('firebase setup complete')
        setUpAxios(dispatch)
        console.debug('axios complete')
        if (tenantConfig.datadog) {
          setupDatadog({
            tenancyCode: tenantConfig.tenant.tenancyCode,
            tenantCode: tenantConfig.tenant.code,
            environment: tenantConfig.config.environment,
            datadogConfig: tenantConfig.datadog,
            gitSha: process.env.REACT_APP_GIT_SHA,
          })
          console.debug('datadog init complete')
        }
      } else {
        let currentUrl = window.location.href
        const { desktopHostName, mobileHostName } = tenantConfig.config
        if (currentUrl.includes(mobileHostName)) {
          currentUrl = currentUrl.replace(mobileHostName, desktopHostName)
        }
        window.location.href = currentUrl
      }
    }
    // eslint-disable-next-line
  }, [tenantConfig, dispatch])
}

export default useAppConfig
