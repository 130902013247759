import { GET_URL_CONFIG_REQUEST, GET_URL_CONFIG_SUCCESS, UrlConfig, UrlConfigActionTypes } from './types'

export function getUrlConfigRequest(): UrlConfigActionTypes {
  return {
    type: GET_URL_CONFIG_REQUEST,
  }
}

export function getUrlConfigSuccess(urlConfig: UrlConfig): UrlConfigActionTypes {
  return {
    type: GET_URL_CONFIG_SUCCESS,
    payload: {
      data: urlConfig,
    },
  }
}
