import { RootState } from '../index'
import { PwaInstallActionTypes, PwaInstallState, SET_DEFERRED_PROMPT } from './types'

const initialState: PwaInstallState = {
  deferredPrompt: null,
}

export function pwaInstallReducer(
  state: PwaInstallState = initialState,
  action: PwaInstallActionTypes,
): PwaInstallState {
  switch (action.type) {
    case SET_DEFERRED_PROMPT:
      return {
        ...initialState,
        deferredPrompt: action.payload.data,
      }
    default:
      return state
  }
}

export const pwaInstallDeferredPrompt = (state: RootState) => {
  return state.pwaInstallReducer.deferredPrompt
}
