import {
  GET_MAP_BOX_SUGGESTIONS_REQUEST,
  GET_MAP_BOX_SUGGESTIONS_SUCCESS,
  MapBoxSuggestionsActionTypes,
  MapBoxSuggestionsState,
  RESET_MAP_BOX_SUGGESTIONS,
} from './types'

const initialState: MapBoxSuggestionsState = {
  loading: false,
  mapBoxSuggestions: [],
}

export function mapBoxGeoFeaturesReducer(
  state: MapBoxSuggestionsState = initialState,
  action: MapBoxSuggestionsActionTypes,
): MapBoxSuggestionsState {
  switch (action.type) {
    case GET_MAP_BOX_SUGGESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        mapBoxSuggestions: [],
      }
    case GET_MAP_BOX_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        mapBoxSuggestions: action.payload.data,
      }
    case RESET_MAP_BOX_SUGGESTIONS:
      return initialState
    default:
      return state
  }
}
