import {
  GET_GEO_FEATURE_FACILITIES_REQUEST,
  GET_GEO_FEATURE_FACILITIES_SUCCESS,
  GeoFeatureFacilitiesActionTypes,
  GeoFeatureFacilitiesState,
  RESET_GEO_FEATURE_FACILITIES,
} from './types'

const initialState: GeoFeatureFacilitiesState = {
  geoFeatureFacilities: [],
}

export function geoFeatureFacilitiesReducer(
  state: GeoFeatureFacilitiesState = initialState,
  action: GeoFeatureFacilitiesActionTypes,
): GeoFeatureFacilitiesState {
  switch (action.type) {
    case GET_GEO_FEATURE_FACILITIES_REQUEST:
      return {
        ...state,
      }
    case GET_GEO_FEATURE_FACILITIES_SUCCESS:
      return {
        ...state,
        geoFeatureFacilities: action.payload.data,
      }
    case RESET_GEO_FEATURE_FACILITIES:
      return initialState
    default:
      return state
  }
}
