import axios from 'axios'
import { ROUTE_PATH_QUICK_PARK_PARKING_LOCATION } from '../../common/constants'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { resetParkingBasket } from '../basket/actions'
import { deleteParkingBasket } from '../basket/thunks'
import { isExpired } from '../basket/types'
import { AppThunk } from '../index'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { SalesChannelType } from '../park-and-pay/types'
import { getParkingQuotesAndExitTimesSuccess } from '../parking-quotes-exit-times/actions'
import { GET_PARKING_QUOTES_AND_EXIT_TIMES_API_PATH } from '../parking-quotes-exit-times/types'
import { getQuoteFailure, getQuoteRequest, getQuoteSuccess, setQuoteExpired } from './actions'
import { ParkingQuoteRequestDto, QuotePromiseResponse } from './types'

export const GET_QUOTE_API_RESOURCE_PATH = '/public/operators/:operatorId/parking-quotes'

export const getQuote =
  (quote: ParkingQuoteRequestDto, doNotShowNoOffersToast?: boolean): AppThunk<Promise<QuotePromiseResponse>> =>
  async (dispatch, getState) => {
    let quotePromiseResponse: QuotePromiseResponse = {
      id: '',
      itemList: [],
      success: false,
    }
    dispatch(getQuoteRequest())
    dispatch(resetParkingBasket())
    await dispatch(deleteParkingBasket())
    const { operatorId } = await findConfiguration(getState())
    const config = await buildAxiosConfig(getState())
    await axios
      .post(GET_QUOTE_API_RESOURCE_PATH.replace(':operatorId', operatorId), quote, config)
      .then((response) => {
        if (
          response.data &&
          response.data.itemList &&
          response.data.itemList.length === 0 &&
          response.data.quote.salesChannelType !== SalesChannelType.Access &&
          !doNotShowNoOffersToast
        ) {
          dispatch(
            setNotification(
              NotificationType.ERROR,
              'No offers available. Please update the parking period and try again.',
            ),
          )
        } else {
          dispatch(getQuoteSuccess(response.data))
          quotePromiseResponse = {
            id: response.data.id,
            itemList: response.data.itemList,
            success: true,
            warnings: response.data.warnings,
          }
        }
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getQuoteFailure())
        if (typeof error === 'object') {
          if (isExpired(error.code)) {
            dispatch(setQuoteExpired())
          } else if (error.code === 'carPark.entry.closed') {
            quotePromiseResponse.isCarParkEntryClosed = true
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
    return quotePromiseResponse
  }

export const getParkingQuoteById =
  (quoteId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(getQuoteRequest())
    const { operatorId } = await findConfiguration(getState())
    const config = await buildAxiosConfig(getState())
    const windowLocationPathname: string = window.location.pathname
    const resourcePath: string =
      windowLocationPathname.includes('Park') || windowLocationPathname.includes(ROUTE_PATH_QUICK_PARK_PARKING_LOCATION)
        ? GET_PARKING_QUOTES_AND_EXIT_TIMES_API_PATH.replace(':operatorId', operatorId) + '/' + quoteId
        : GET_QUOTE_API_RESOURCE_PATH.replace(':operatorId', operatorId) + '/' + quoteId
    await axios
      .get(resourcePath, config)
      .then((response) => {
        dispatch(getQuoteSuccess(response.data))
        if (
          windowLocationPathname.includes('Park') ||
          windowLocationPathname.includes(ROUTE_PATH_QUICK_PARK_PARKING_LOCATION)
        ) {
          dispatch(getParkingQuotesAndExitTimesSuccess(response.data))
        }
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getQuoteFailure())
        if (typeof error === 'object') {
          if (isExpired(error.code)) {
            dispatch(setQuoteExpired())
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
  }
