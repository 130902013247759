import {
  RESET_GEO_FEATURES,
  SEARCH_GEO_FEATURES_REQUEST,
  SEARCH_GEO_FEATURES_SUCCESS,
  SearchGeoFeaturesActionTypes,
  SearchGeoFeaturesState,
} from './types'

const initialState: SearchGeoFeaturesState = {
  loading: false,
  geoFeatures: [],
}

export function geoFeaturesReducer(
  state: SearchGeoFeaturesState = initialState,
  action: SearchGeoFeaturesActionTypes,
): SearchGeoFeaturesState {
  switch (action.type) {
    case SEARCH_GEO_FEATURES_REQUEST:
      return {
        ...state,
        loading: true,
        geoFeatures: [],
      }
    case SEARCH_GEO_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        geoFeatures: action.payload.data,
      }
    case RESET_GEO_FEATURES:
      return initialState
    default:
      return state
  }
}
