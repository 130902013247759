import { OOPS_ERROR_MESSAGE } from '../../common/messages'
import { findConfiguration } from '../../common/utility'
import { AppThunk } from '../index'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { getPermitConfigRequest, getPermitConfigSuccess } from './actions'

export const getPermitConfig = (): AppThunk<any> => async (dispatch, getState) => {
  dispatch(getPermitConfigRequest())
  const { operatorId } = findConfiguration(getState())
  const resourcePath = '/_tenant/park/operators/' + operatorId + '/permit.json'
  fetch(resourcePath)
    .then((response) => response.json())
    .then((json) => dispatch(getPermitConfigSuccess(json)))
    .catch(() => {
      dispatch(setNotification(NotificationType.ERROR, OOPS_ERROR_MESSAGE))
    })
}
