import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { Theme } from '@mui/material/styles'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import './assets/css/alignment.css'
import './assets/css/common.css'
import './assets/css/main.css'
import './assets/css/variables.css'
import './assets/css/wrappers.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import configureStore from './store'
import { theme } from './theme'
import './version-static.css'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}
const store = configureStore(undefined)

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.querySelector('#root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
