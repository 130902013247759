import {
  EntitlementState,
  EntitlementsActionTypes,
  GET_ENTITLEMENTS_REQUEST,
  GET_ENTITLEMENTS_SUCCESS,
  RESET_ENTITLEMENTS,
} from './types'

const initialState: EntitlementState = {
  loading: false,
  success: false,
  entitlements: [],
  isRequestFinished: false,
}

export function entitlementsReducer(
  state: EntitlementState = initialState,
  action: EntitlementsActionTypes,
): EntitlementState {
  switch (action.type) {
    case GET_ENTITLEMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        entitlements: [],
        isRequestFinished: false,
      }
    case GET_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        entitlements: action.payload.data,
        isRequestFinished: true,
      }
    case RESET_ENTITLEMENTS:
      return initialState
    default:
      return state
  }
}
