import { BasePriceUnit } from '../../common/utility'
import { GeoMapLineString, LocationType, Point, SalesChannelType, ZoneShortInfo } from '../park-and-pay/types'

export enum AccessControlType {
  Attended = 'Attended',
  Enforced = 'Enforced',
  Gated = 'Gated',
}

export enum LocationClass {
  OnStreet = 'OnStreet',
  OffStreet = 'OffStreet',
}

export interface CarparkLongInfo {
  id: string
  code: string
  clientRef: string
  publicName: string
  publicId: string
  name: string
  operatorId: string
  marketing: CarparkMarketing
  version: number
  clearance: number
  mainImageUrl: string | null
  mapDirectionsUrl: string
  timeZoneId: string
  address: CarparkAddress
  mapPoint: CarparkMapPoint
  rateBoard: CarparkRateBoard | null
  timeBoard: CarparkTimeBoard | null
  amenities: CarparkAmenity[]
  accessControlType: AccessControlType
  locationClass: LocationClass
  zones: ZoneShortInfo[]
  distanceFromPointMetres: number | null
  geoMapType: LocationType
  geoMapPoint: Point | null
  geoMapLineString: GeoMapLineString
  availableSalesChannels: SalesChannelType[]
  vacantSpace: number | null
}

export interface CarparkMarketing {
  customTheme: string | null
  headline: string | null
  details: string | null
  termsHeadline: string | null
  termsDetails: string | null
  basePriceLabel: string
  basePriceFee: number
  basePriceUnit: BasePriceUnit | null
}

export interface CarparkAddress {
  line1: string
  line2: string
  suburb: string
  state: string
  postcode: string
  countryIso: string
}

export interface CarparkMapPoint {
  latitude: number
  longitude: number
  label: string
}

export interface CarparkRateBoard {
  id: string
  name: string
  rateBoardPeriods: CarparkRateBoardPeriod[]
}

export interface CarparkRateBoardPeriod {
  id: string
  name: string
  sortOrder: number
  slots: CarparkRateBoardPeriodSlot[]
}

export interface CarparkRateBoardPeriodSlot {
  id: string
  name: string
  price: string
  sortOrder: number
}

export interface CarparkTimeBoard {
  id: string
  name: string
  tradingHours: CarparkTimeBoardTradingHour[]
}

export interface CarparkTimeBoardTradingHour {
  id: string
  period: string
  tradingTimes: string
  sortOrder: number
}

export interface CarparkAmenity {
  id: string
  kind: string
  name: string
  iconPath: string
}

export interface CarparkLongInfoState {
  error: boolean
  carparkLongInfo: CarparkLongInfo | null
}

export const GET_CARPARK_LONG_INFO_REQUEST = 'GET_CARPARK_LONG_INFO_REQUEST'
export const GET_CARPARK_LONG_INFO_SUCCESS = 'GET_CARPARK_LONG_INFO_SUCCESS'
export const GET_CARPARK_LONG_INFO_FAILURE = 'GET_CARPARK_LONG_INFO_FAILURE'
export const RESET_CARPARK_LONG_INFO = 'RESET_CARPARK_LONG_INFO'

interface getCarparkLongInfoRequestAction {
  type: typeof GET_CARPARK_LONG_INFO_REQUEST
}

interface getCarparkLongInfoSuccessAction {
  type: typeof GET_CARPARK_LONG_INFO_SUCCESS
  payload: {
    carparkLongInfo: CarparkLongInfo
  }
}

interface getCarparkLongInfoFailureAction {
  type: typeof GET_CARPARK_LONG_INFO_FAILURE
}

interface resetCarparkLongInfoAction {
  type: typeof RESET_CARPARK_LONG_INFO
}

export type CarparkLongInfoActionTypes =
  | getCarparkLongInfoRequestAction
  | getCarparkLongInfoSuccessAction
  | getCarparkLongInfoFailureAction
  | resetCarparkLongInfoAction
