import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ISO_DATE_FORMAT, MDY_DATE_FORMAT } from '../../common/date-time-utils'
import { SESSION_STORAGE_KEY_PARKING_QUOTE_ID } from '../../common/types'
import Loading from '../../component/loading/Loading'
import { RootState } from '../../store'
import { currentConsumer } from '../../store/auth/reducers'
import { getParkingBasket } from '../../store/basket/thunks'
import { CarParkFacility } from '../../store/carpark-facilities/types'
import { getShortInfoFromPublicId, getShortInfoZonesFromPublicId } from '../../store/carpark-search/thunks'
import { selectCarParkFacility, selectDuration } from '../../store/park-and-pay/action'
import { FacilityType, SalesChannelType, SelectedDuration } from '../../store/park-and-pay/types'
import { getParkingQuoteById } from '../../store/parking-quote/thunks'

interface BasketReloadProps {
  basketId: string
  onLoaded: () => void
}

const BasketReload: React.FC<BasketReloadProps> = (props) => {
  const { basketId, onLoaded } = props

  const dispatch = useDispatch()

  const [pendingBasket, setPendingBasket] = useState<boolean>(true)
  const [pendingParkingQuote, setPendingParkingQuote] = useState<boolean>(true)
  const [pendingCarPark, setPendingCarPark] = useState<boolean>(true)
  const [reloadError, setReloadError] = useState<boolean>(false)

  const { basket, parkingBasketExpired, parkingQuote, parkingQuoteExpired, carPark } = useSelector(
    (state: RootState) => ({
      basket: state.basketReducer.parkingBasket,
      parkingBasketExpired: state.basketReducer.parkingBasketExpired,
      parkingQuote: state.quoteReducer.quote,
      parkingQuoteExpired: state.quoteReducer.quoteExpired,
      carPark: state.carParkSearchReducer.carPark,
    }),
    shallowEqual,
  )
  const consumer = useSelector(currentConsumer)

  useEffect(() => {
    dispatch(getParkingBasket(basketId))
    // eslint-disable-next-line
  }, [basketId])

  useEffect(() => {
    /**
     * Return false if the basket is owned by a consumer id and the current consumer is null
     * or does not match
     */
    function verifyBasketOwner(): boolean {
      if (basket?.contact?.consumerId) {
        const ownerId = basket.contact.consumerId
        if (ownerId === consumer?.id) {
          return true
        } else {
          console.warn('owner: ' + ownerId + ' does not match: ' + consumer?.id)
          return false
        }
      } else {
        // nobody owns - ok
        return true
      }
    }
    if (parkingBasketExpired) {
      setPendingBasket(false)
      setPendingParkingQuote(false)
      setPendingCarPark(false)
    }
    if (basket) {
      setPendingBasket(false)
      if (verifyBasketOwner()) {
        if (basket.parkingItem && basket.parkingItem.parkingQuoteId) {
          const sessionParkingQuoteId = sessionStorage.getItem(SESSION_STORAGE_KEY_PARKING_QUOTE_ID)
          // use last generated quote id
          if (sessionParkingQuoteId) {
            dispatch(getParkingQuoteById(sessionParkingQuoteId))
          } else {
            dispatch(getParkingQuoteById(basket.parkingItem.parkingQuoteId))
          }
        } else {
          setPendingParkingQuote(false)
        }
        if (basket.salesChannelType === SalesChannelType.Park) {
          const publicId = basket.parkingItem.stayAgreements[0].virtualMeterInfo
            ? basket.parkingItem.stayAgreements[0].virtualMeterInfo.publicId
            : basket.parkingItem.stayAgreements[0].allocationInfo
            ? basket.parkingItem.stayAgreements[0].allocationInfo.zonePublicId
            : basket.parkingItem.stayAgreements[0].facilityRevision.publicId
          dispatch(getShortInfoZonesFromPublicId(publicId))
        } else if (basket.parkingItem.stayAgreements[0].facilityRevision.publicId) {
          dispatch(getShortInfoFromPublicId(basket.parkingItem.stayAgreements[0].facilityRevision.publicId))
        } else {
          setPendingCarPark(false)
        }
      }
    }
  }, [dispatch, basket, consumer, parkingBasketExpired])

  useEffect(() => {
    if (pendingParkingQuote) {
      if (parkingQuote) {
        console.info('loaded parking quote ' + parkingQuote.id)
        setPendingParkingQuote(false)
      }
      if (parkingQuoteExpired) {
        console.error('ParkingQuoteExpired: ' + parkingQuoteExpired)
        setReloadError(true)
        setPendingParkingQuote(false)
      }
    }

    if (pendingCarPark) {
      if (carPark) {
        console.info('loaded car park ' + carPark.publicId)
        setPendingCarPark(false)
      }
    }
    // eslint-disable-next-line
  }, [parkingQuote, parkingQuoteExpired, pendingParkingQuote, carPark, pendingCarPark])

  useEffect(() => {
    if (!pendingBasket && !pendingParkingQuote && !pendingCarPark) {
      console.info('all finished, reload error: ' + reloadError)

      if (!reloadError && basket) {
        if (carPark) {
          const carParkFacility: CarParkFacility = {
            address: carPark.address,
            clearance: carPark.clearance,
            id: carPark.id,
            publicName: carPark.publicName,
            publicId: carPark.publicId,
            operatorId: carPark.operatorId,
            location: carPark.location,
            facilityType: FacilityType.PrimaryCarPark,
            locationClass: carPark.locationClass,
            clientRef: carPark.clientRef,
          }
          dispatch(selectCarParkFacility(carParkFacility))
        }

        // get stay data from quote instead of basket
        if (parkingQuote) {
          const stayRequest = parkingQuote.stayRequest
          if (stayRequest) {
            let durationMinutes = parkingQuote.itemList[0].stayAgreements[0].singleStay.durationMinutes
            const startDateTime = dayjs(stayRequest.requestedStartDateTime)
            const finishDateTime = dayjs(stayRequest.requestedFinishDateTime)
            const selectedDuration: SelectedDuration = {
              durationMinutes: durationMinutes,
              entryDateTimeIso8601: startDateTime.format(ISO_DATE_FORMAT),
              endDateTimeIso8601: finishDateTime.format(ISO_DATE_FORMAT),
              entryDate: startDateTime.format(MDY_DATE_FORMAT),
              endDate: finishDateTime.format(MDY_DATE_FORMAT),
              entryTime: startDateTime.toDate(),
              endTime: finishDateTime.toDate(),
              selectorIndex: 0,
            }
            dispatch(selectDuration(selectedDuration))
          } else {
            const selectedDuration: SelectedDuration = {
              endDate: '',
              entryDate: '',
              endTime: new Date(),
              entryTime: new Date(),
              endDateTimeIso8601: '',
              entryDateTimeIso8601: '',
              selectorIndex: parkingQuote.itemList.findIndex(
                (offer) => offer.id === basket.parkingItem.parkingQuoteItemId,
              ),
              durationMinutes: basket.parkingItem.stayAgreements[0].singleStay.durationMinutes,
            }
            dispatch(selectDuration(selectedDuration))
          }
        }
      }
      onLoaded()
    }
    // eslint-disable-next-line
  }, [dispatch, basket, parkingQuote, carPark, pendingBasket, pendingParkingQuote, pendingCarPark, reloadError])

  return <Loading />
}

export default BasketReload
