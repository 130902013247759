import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getBrandingState } from '../../store/branding/reducers'
import { BrandingElement, ElementType } from '../../store/branding/types'
import { preLoadImages } from '../utility'

const useBrandingImages = () => {
  const branding = useSelector(getBrandingState)

  useEffect(() => {
    if (branding) {
      const brandingImageElements: BrandingElement[] = branding.elements.filter(
        (brandingElement) => brandingElement.elementType === ElementType.Image,
      )
      const imageUrls: (string | undefined)[] = brandingImageElements.map(
        (brandingImageElement) => brandingImageElement.imageValue?.url,
      )
      const filteredImageUrls: string[] = imageUrls.filter((url): url is string => url !== undefined)
      if (filteredImageUrls.length > 0) {
        preLoadImages(filteredImageUrls)
      }
    }
  }, [branding])
}

export default useBrandingImages
