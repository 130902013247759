const MuiSlider = {
  styleOverrides: {
    root: {
      height: 4,
      '& .MuiSlider-track': {
        height: 4,
        border: 'none',
      },
      '& .MuiSlider-rail': {
        height: 4,
      },
      '& .MuiSlider-thumb': {
        backgroundColor: '#EFF2F4',
        boxShadow: '0px 1px 2px #0000006E',
        width: 24,
        height: 24,
      },
    },
  },
}

export default MuiSlider
