import { VehicleMakesActionTypes, GET_VEHICLE_MAKES_REQUEST, GET_VEHICLE_MAKES_SUCCESS, VehicleMakes } from './types'

export function getVehicleMakesRequest(): VehicleMakesActionTypes {
  return {
    type: GET_VEHICLE_MAKES_REQUEST,
  }
}

export function getVehicleMakesSuccess(data: VehicleMakes[]): VehicleMakesActionTypes {
  return {
    type: GET_VEHICLE_MAKES_SUCCESS,
    payload: {
      vehicleMakes: data,
    },
  }
}
