import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const useTabTitle = (tabTitle: string) => {
  const { operatorName } = useSelector((state: RootState) => ({
    operatorName: state.appConfigReducer.tenantConfig?.config.operatorName,
  }))

  useEffect(() => {
    if (operatorName) {
      let title: string = ''
      if (tabTitle) {
        title = operatorName + ' - ' + tabTitle
      } else {
        title = operatorName
      }
      let element = document?.querySelector('title')
      if (element) {
        element.textContent = title
      }
    }
  }, [operatorName, tabTitle])
}

export default useTabTitle
