export interface FeedbackReq {
  rating: number
  contact: boolean
  comment?: string
  locationId?: string
  name?: string
  phone?: string
  email?: string
}

export interface FeedbackState {
  storedFeedbackData: FeedbackReq | null
}

export const SUBMIT_FEEDBACK_API_RESOURCE_PATH = '/public/operators/:operatorId/feedbacks'

export const SET_FEEDBACK_DATA = 'SET_FEEDBACK_DATA'
export const RESET_FEEDBACK_DATA = 'RESET_FEEDBACK_DATA'

export interface setFeedbackData {
  type: typeof SET_FEEDBACK_DATA
  payload: {
    data: FeedbackReq
  }
}

export interface resetFeedbackData {
  type: typeof RESET_FEEDBACK_DATA
}

export type FeedbackActionTypes = setFeedbackData | resetFeedbackData
