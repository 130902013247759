import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ISO_DATE_FORMAT, MDY_DATE_FORMAT } from '../../common/date-time-utils'
import { SESSION_STORAGE_KEY_PARKING_QUOTE_ID } from '../../common/types'
import Loading from '../../component/loading/Loading'
import { RootState } from '../../store'
import { currentConsumer } from '../../store/auth/reducers'
import { CarParkFacility } from '../../store/carpark-facilities/types'
import { getShortInfoFromPublicId } from '../../store/carpark-search/thunks'
import { selectCarParkFacility, selectDuration } from '../../store/park-and-pay/action'
import { CarPark, FacilityType, SelectedDuration } from '../../store/park-and-pay/types'
import { resetQuote } from '../../store/parking-quote/actions'
import { getParkingQuoteById } from '../../store/parking-quote/thunks'
import { ParkingQuoteDto } from '../../store/parking-quote/types'

interface ParkingQuoteReloadProps {
  parkingQuoteId: string
  onLoaded: () => void
}

const ParkingQuoteReload: React.FC<ParkingQuoteReloadProps> = (props) => {
  const [pendingParkingQuote, setPendingParkingQuote] = useState<boolean>(true)
  const [pendingCarPark, setPendingCarPark] = useState<boolean>(true)
  const consumer = useSelector(currentConsumer)

  const dispatch = useDispatch()
  const { parkingQuote, parkingQuoteExpired, carPark } = useSelector(
    (state: RootState) => ({
      parkingQuote: state.quoteReducer.quote,
      parkingQuoteExpired: state.quoteReducer.quoteExpired,
      carPark: state.carParkSearchReducer.carPark,
    }),
    shallowEqual,
  )

  useEffect(() => {
    dispatch(getParkingQuoteById(props.parkingQuoteId))
  }, [dispatch, props.parkingQuoteId])

  // check for parking quote and trigger car park
  useEffect(() => {
    if (parkingQuoteExpired) {
      console.error('ParkingQuoteExpired: ' + parkingQuoteExpired)
      setPendingParkingQuote(false)
      setPendingCarPark(false)
    }
    if (parkingQuote) {
      console.info('loaded parking quote ' + parkingQuote.id)
      if (
        parkingQuote.itemList &&
        parkingQuote.itemList[0] &&
        parkingQuote.itemList[0].stayAgreements[0] &&
        parkingQuote.itemList[0].stayAgreements[0].facilityRevision &&
        parkingQuote.itemList[0].stayAgreements[0].facilityRevision.publicId
      ) {
        dispatch(getShortInfoFromPublicId(parkingQuote.itemList[0].stayAgreements[0].facilityRevision.publicId))
      } else {
        setPendingCarPark(false)
      }
      setPendingParkingQuote(false)
    }
  }, [dispatch, parkingQuote, parkingQuoteExpired])

  // check for car park
  useEffect(() => {
    if (pendingCarPark) {
      if (carPark) {
        console.info('loaded car park ' + carPark.publicId)
        setPendingCarPark(false)
      }
    }
    // eslint-disable-next-line
  }, [pendingCarPark, carPark])

  // when both complete - update and callback
  useEffect(() => {
    function setParkingQuote(parkingQuote: ParkingQuoteDto, carPark: CarPark | null) {
      if (carPark) {
        const carParkFacility: CarParkFacility = {
          address: carPark.address,
          clearance: carPark.clearance,
          id: carPark.id,
          publicName: carPark.publicName,
          publicId: carPark.publicId,
          operatorId: carPark.operatorId,
          location: carPark.location,
          facilityType: FacilityType.PrimaryCarPark,
          locationClass: carPark.locationClass,
          clientRef: carPark.clientRef,
        }
        dispatch(selectCarParkFacility(carParkFacility))
      }
      let durationMinutes = 0
      if (parkingQuote && parkingQuote.itemList[0] && parkingQuote.itemList[0].stayAgreements[0]) {
        durationMinutes = parkingQuote.itemList[0].stayAgreements[0].singleStay.durationMinutes
      }
      const stayRequest = parkingQuote.stayRequest
      if (stayRequest) {
        const startDateTime = dayjs(stayRequest.requestedStartDateTime)
        const finishDateTime = dayjs(stayRequest.requestedFinishDateTime)
        const selectedDuration: SelectedDuration = {
          durationMinutes: durationMinutes,
          entryDateTimeIso8601: startDateTime.format(ISO_DATE_FORMAT),
          endDateTimeIso8601: finishDateTime.format(ISO_DATE_FORMAT),
          entryDate: startDateTime.format(MDY_DATE_FORMAT),
          endDate: finishDateTime.format(MDY_DATE_FORMAT),
          entryTime: startDateTime.toDate(),
          endTime: finishDateTime.toDate(),
          selectorIndex: 0,
        }
        dispatch(selectDuration(selectedDuration))
      }
    }

    if (!pendingParkingQuote && !pendingCarPark) {
      console.info('all finished')
      if (parkingQuote) {
        // check if existing quote has a consumer id
        if (parkingQuote.quote.consumerId) {
          // if matches - all ok
          if (parkingQuote.quote.consumerId === consumer?.id) {
            // matches - we're ok
            setParkingQuote(parkingQuote, carPark)
          } else {
            console.info('discarded existing quote')
            sessionStorage.removeItem(SESSION_STORAGE_KEY_PARKING_QUOTE_ID)
            dispatch(resetQuote())
          }
        } else {
          // no consumer id on the quote - use it
          setParkingQuote(parkingQuote, carPark)
        }
      }
      props.onLoaded()
    }
  }, [dispatch, parkingQuote, pendingParkingQuote, pendingCarPark, carPark, props, consumer?.id])

  return <Loading />
}

export default ParkingQuoteReload
