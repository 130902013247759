import { CarParkFacility } from '../carpark-facilities/types'
import { MapBoxFeature, MapBoxSuggestion } from '../mapbox-geo-feature/types'
import { MyActivityDto } from '../my-agreements/types'
import {
  INIT_FLOW,
  POSITION_SEARCH,
  RESET_ORDER_FORM,
  SELECT_CARPARK_FACILITY,
  SELECT_CONTACT_DETAILS,
  SELECT_DURATION,
  SELECT_LANDMARK,
  SELECT_MAP_BOX_SUGGESTION,
  SET_CONSUMER_ORDER,
  SET_CONSUMER_ORDER_TO_EXTEND,
  SET_MAP_VIEW,
  SET_SEARCHED_FACILITY_FILTERS,
} from './action'
import { OrderFormActionTypes, Point, SearchFacilityFilters, SelectedContactDetails, SelectedDuration } from './types'

interface AgreementFlowState {
  selectedCarParkFacility: CarParkFacility | null
  selectedLandmark: MapBoxFeature | null
  selectedPoint: Point | null
  selectedMapBoxSuggestion: MapBoxSuggestion | null
  selectedDuration: SelectedDuration | null
  selectedContactDetails: SelectedContactDetails | null
  consumerOrderToAmend: MyActivityDto | null
  consumerOrderToExtend: MyActivityDto | null
  searchedFacilityFilters: SearchFacilityFilters | null
  mapView: boolean
}

const initialState: AgreementFlowState = {
  selectedCarParkFacility: null,
  selectedLandmark: null,
  selectedPoint: null,
  selectedMapBoxSuggestion: null,
  selectedDuration: null,
  selectedContactDetails: null,
  consumerOrderToAmend: null,
  consumerOrderToExtend: null,
  searchedFacilityFilters: null,
  mapView: true,
}

export function orderFormReducer(
  state: AgreementFlowState = initialState,
  action: OrderFormActionTypes,
): AgreementFlowState {
  switch (action.type) {
    case INIT_FLOW: {
      return {
        ...initialState,
        selectedMapBoxSuggestion: state.selectedMapBoxSuggestion ? state.selectedMapBoxSuggestion : null,
      }
    }
    case SELECT_CARPARK_FACILITY: {
      return {
        ...state,
        selectedCarParkFacility: action.payload.data,
      }
    }
    case SELECT_LANDMARK: {
      return {
        ...state,
        selectedLandmark: action.payload.data,
      }
    }
    case POSITION_SEARCH: {
      return {
        ...state,
        selectedPoint: action.payload.data,
      }
    }
    case SELECT_MAP_BOX_SUGGESTION: {
      return {
        ...state,
        selectedMapBoxSuggestion: action.payload.data,
      }
    }
    case SELECT_DURATION: {
      return {
        ...state,
        selectedDuration: action.payload.data,
      }
    }
    case SELECT_CONTACT_DETAILS: {
      return {
        ...state,
        selectedContactDetails: action.payload.data,
      }
    }
    case SET_CONSUMER_ORDER: {
      return {
        ...state,
        consumerOrderToAmend: action.payload.data,
      }
    }
    case SET_CONSUMER_ORDER_TO_EXTEND: {
      return {
        ...state,
        consumerOrderToExtend: action.payload.data,
      }
    }
    case SET_SEARCHED_FACILITY_FILTERS: {
      return {
        ...state,
        searchedFacilityFilters: action.payload.data,
      }
    }
    case SET_MAP_VIEW: {
      return {
        ...state,
        mapView: action.payload.data,
      }
    }
    case RESET_ORDER_FORM: {
      return initialState
    }
    default:
      return state
  }
}
