import {
  GET_VEHICLE_MAKES_REQUEST,
  GET_VEHICLE_MAKES_SUCCESS,
  VehicleMakesActionTypes,
  VehicleMakesState,
} from './types'

const initialState: VehicleMakesState = {
  vehicleMakes: [],
}

export function vehicleMakesReducer(
  state: VehicleMakesState = initialState,
  action: VehicleMakesActionTypes,
): VehicleMakesState {
  switch (action.type) {
    case GET_VEHICLE_MAKES_REQUEST:
      return {
        ...state,
        vehicleMakes: [],
      }
    case GET_VEHICLE_MAKES_SUCCESS:
      return {
        ...state,
        vehicleMakes: action.payload.vehicleMakes,
      }
    default:
      return state
  }
}
