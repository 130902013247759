import axios from 'axios'
import { AppThunk } from '..'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { getBrandingRequest, getBrandingSuccess } from './actions'
import { GET_BRANDING_API_RESOURCE_PATH } from './types'

export const getBrandingDetails = (): AppThunk<any> => async (dispatch, getState) => {
  dispatch(getBrandingRequest())
  const config = await buildAxiosConfig(getState())
  const { operatorId } = findConfiguration(getState())
  await axios
    .get(GET_BRANDING_API_RESOURCE_PATH.replace(':operatorId', operatorId), config)
    .then((response) => {
      dispatch(getBrandingSuccess(response.data))
    })
    .catch((error: ErrorResponse | string) => {
      dispatch(getBrandingSuccess(null))
      if (typeof error === 'object') {
        dispatch(setNotification(NotificationType.ERROR, error.message))
      }
    })
}
