import {
  FacilityAccessInfoActionTypes,
  FacilityAccessInfoState,
  GET_FACILITY_ACCESS_INFO_FAILURE,
  GET_FACILITY_ACCESS_INFO_REQUEST,
  GET_FACILITY_ACCESS_INFO_SUCCESS,
  RESET_FACILITY_ACCESS_INFO,
} from './types'

const initialState: FacilityAccessInfoState = {
  error: false,
  facilityAccessInfo: null,
}

export function facilityAccessInfoReducer(
  state: FacilityAccessInfoState = initialState,
  action: FacilityAccessInfoActionTypes,
): FacilityAccessInfoState {
  switch (action.type) {
    case GET_FACILITY_ACCESS_INFO_REQUEST:
      return {
        ...state,
        error: false,
        facilityAccessInfo: null,
      }
    case GET_FACILITY_ACCESS_INFO_SUCCESS:
      return {
        ...state,
        facilityAccessInfo: action.payload.facilityAccessInfo,
      }
    case GET_FACILITY_ACCESS_INFO_FAILURE:
      return {
        ...state,
        error: true,
      }
    case RESET_FACILITY_ACCESS_INFO:
      return initialState
    default:
      return state
  }
}
