import {
  GET_CONSUMER_RECENT_PARKS_REQUEST,
  GET_CONSUMER_RECENT_PARKS_SUCCESS,
  RecentParksActionTypes,
  RecentParksState,
} from './types'

const initialState: RecentParksState = {
  recentParks: [],
}

export function recentParksReducer(
  state: RecentParksState = initialState,
  action: RecentParksActionTypes,
): RecentParksState {
  switch (action.type) {
    case GET_CONSUMER_RECENT_PARKS_REQUEST:
      return {
        ...state,
        recentParks: [],
      }
    case GET_CONSUMER_RECENT_PARKS_SUCCESS:
      return {
        ...state,
        recentParks: action.payload.data,
      }
    default:
      return state
  }
}
