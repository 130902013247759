import axios, { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import { YMD_DATE_FORMAT } from '../../common/date-time-utils'
import { OrderStatus } from '../../common/types'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { HistoryFilters } from '../../pages/parking/my-profile/my-agreements/activity-utils'
import { AppThunk } from '../index'
import { setLoading } from '../loading/reducers'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { SalesChannelType, TransactionType } from '../park-and-pay/types'
import {
  getConsumerAgreementRequest,
  getConsumerAgreementSuccess,
  getConsumerAgreementsRequest,
  getConsumerAgreementsSuccess,
  getConsumerInactiveAgreementsRequest,
  getConsumerInactiveAgreementsSuccess,
  getOrderAgreementFailure,
  getOrderAgreementRequest,
  getOrderAgreementSuccess,
} from './actions'
import {
  GET_AGREEMENT_DETAILS_API_RESOURCE_PATH,
  GET_CONSUMER_ACTIVE_AGREEMENTS_API_RESOURCE_PATH,
  GET_CONSUMER_INACTIVE_AGREEMENTS_API_RESOURCE_PATH,
  GET_CONSUMER_ORDER_DETAILS_API_RESOURCE_PATH,
  GET_PUBLIC_ORDER_DETAILS_API_RESOURCE_PATH,
  MyActivityListDto,
  OrderDetailsPromiseResponse,
} from './types'
import { HTTP_RESPONSE_CODE_400 } from '../../common/constants'

// Consumer Agreements
export const getConsumerAgreements =
  (consumerId: string): AppThunk<Promise<MyActivityListDto[]>> =>
  async (dispatch, getState) => {
    let consumerAgreements: MyActivityListDto[] = []
    dispatch(getConsumerAgreementsRequest())
    const { operatorId } = findConfiguration(getState())
    const config = await buildAxiosConfig(getState())
    config.params = {
      page: 0,
      size: 100,
      sortBy: 'StartDate',
      sortDirection: 'asc',
    }
    await axios
      .get(
        GET_CONSUMER_ACTIVE_AGREEMENTS_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ),
        config,
      )
      .then((response) => {
        consumerAgreements = response.data
        dispatch(getConsumerAgreementsSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getConsumerAgreementsSuccess([]))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return consumerAgreements
  }

export const getConsumerInactiveAgreements =
  (consumerId: string, filtersData?: HistoryFilters): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(getConsumerInactiveAgreementsRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    config.params = {
      agreementAuthorityType: filtersData && filtersData.channel ? filtersData.channel : null,
      agreementReference: filtersData && filtersData.orderNo ? filtersData.orderNo.toUpperCase() : null,
      startDate: filtersData && filtersData.startDate ? dayjs(filtersData.startDate).format(YMD_DATE_FORMAT) : null,
      endDate: filtersData && filtersData.endDate ? dayjs(filtersData.endDate).format(YMD_DATE_FORMAT) : null,
      facilityId: filtersData && filtersData.facilityId ? filtersData.facilityId : null,
      agreementStatus: filtersData && filtersData.status ? filtersData.status : null,
      page: 0,
      size: 100,
      sortBy: 'EndDate',
      sortDirection: 'desc',
    }
    await axios
      .get(
        GET_CONSUMER_INACTIVE_AGREEMENTS_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ),
        config,
      )
      .then((response) => {
        dispatch(getConsumerInactiveAgreementsSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getConsumerInactiveAgreementsSuccess([]))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }
// Consumer Agreements

// Order
export const getOrderDetailsById =
  (orderId: string, consumerId?: string): AppThunk<Promise<OrderDetailsPromiseResponse>> =>
  async (dispatch, getState) => {
    let orderDetailsPromiseResponse: OrderDetailsPromiseResponse = {
      id: '',
      financialTransactions: [],
      orderCreatedDateTime: '',
      paymentDeclined: false,
      parkingLineItem: null,
      relatedOrders: null,
      salesChannelType: SalesChannelType.Res,
      success: false,
    }
    dispatch(getConsumerAgreementRequest())
    const { operatorId, coreUrl } = findConfiguration(getState())
    const config = consumerId ? await buildAxiosConfig(getState()) : { baseURL: coreUrl }
    const resourcePath: string = consumerId
      ? GET_CONSUMER_ORDER_DETAILS_API_RESOURCE_PATH.replace(':operatorId', operatorId)
          .replace(':consumerId', consumerId)
          .replace(':orderId', orderId)
      : GET_PUBLIC_ORDER_DETAILS_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(':orderId', orderId)
    await axios
      .get(resourcePath, config)
      .then((response) => {
        dispatch(getConsumerAgreementSuccess(response.data))
        orderDetailsPromiseResponse = {
          id: response.data.id,
          facility: response.data.facility,
          financialTransactions: response.data.financialTransactions,
          orderCreatedDateTime: response.data.orderCreatedDateTime,
          paymentDeclined: response.data.orderStatus === OrderStatus.Declined ? true : false,
          parkingLineItem: response.data.parkingLineItem,
          relatedOrders: response.data.relatedOrders,
          salesChannelType: response.data.salesChannelType,
          success: true,
        }
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getConsumerAgreementSuccess(null))
        if (typeof error === 'object') {
          if (error.code === HTTP_RESPONSE_CODE_400) {
            dispatch(
              setNotification(
                NotificationType.ERROR,
                'This park is not associated with this account. Please sign-out and try again.',
              ),
            )
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
    return orderDetailsPromiseResponse
  }

export const viewOrderInvoice =
  (orderId: string, transactionType: TransactionType, consumerId?: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    const { operatorId } = findConfiguration(getState())
    const config: AxiosRequestConfig = await buildAxiosConfig(getState())
    config.params = {
      transactionType: transactionType,
    }
    config.responseType = 'blob'
    const resourcePath: string = consumerId
      ? GET_CONSUMER_ORDER_DETAILS_API_RESOURCE_PATH.replace(':operatorId', operatorId)
          .replace(':consumerId', consumerId)
          .replace(':orderId', orderId) + '/invoice'
      : GET_PUBLIC_ORDER_DETAILS_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(':orderId', orderId) +
        '/invoice'
    await axios
      .get(resourcePath, config)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1])
        link.click()
        link.remove()
        dispatch(setLoading(false))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }

export const postSuspiciousDeclinedPayments =
  (orderId: string, basketId: string, consumerId?: string): AppThunk<any> =>
  async (dispatch, getState) => {
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState())
    const resourcePath: string = consumerId
      ? GET_CONSUMER_ORDER_DETAILS_API_RESOURCE_PATH.replace(':operatorId', operatorId)
          .replace(':consumerId', consumerId)
          .replace(':orderId', orderId)
      : GET_PUBLIC_ORDER_DETAILS_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(':orderId', orderId)
    await axios
      .post(resourcePath + ':suspiciousDeclinedPayments', { basketId: basketId }, authConfig)
      .then(() => {})
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }
// Order

// Order Agreement
export const getPublicOrderAgreementById =
  (agreementId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(getOrderAgreementRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    await axios
      .get(
        GET_AGREEMENT_DETAILS_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(':agreementId', agreementId),
        config,
      )
      .then((response) => {
        dispatch(getOrderAgreementSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(getOrderAgreementFailure(error.code))
        } else {
          dispatch(getOrderAgreementFailure(error))
        }
      })
  }
// Order Agreement
