import axios from 'axios'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { AppThunk } from '../index'
import { AgreementStatus } from '../my-agreements/types'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { getEntitlementsRequest, getEntitlementsSuccess } from './actions'
import { Entitlement, GET_CONSUMER_ENTITLEMENTS_API_RESOURCE_PATH } from './types'

export const getEntitlements =
  (consumerId: string): AppThunk<Promise<Entitlement[]>> =>
  async (dispatch, getState) => {
    let consumerEntitlements: Entitlement[] = []
    dispatch(getEntitlementsRequest())
    const { operatorId } = findConfiguration(getState())
    const config = await buildAxiosConfig(getState())
    config.params = {
      agreementStatus: AgreementStatus.Valid,
      sortBy: 'StartDate',
      sortDirection: 'asc',
    }
    await axios
      .get(
        GET_CONSUMER_ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ),
        config,
      )
      .then((response) => {
        consumerEntitlements = response.data
        dispatch(getEntitlementsSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getEntitlementsSuccess([]))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return consumerEntitlements
  }
