import { MyActivityListDto } from '../my-agreements/types'
import {
  GET_GUEST_AGREEMENTS_LIST_REQUEST,
  GET_GUEST_AGREEMENTS_LIST_SUCCESS,
  GuestAgreementsListActionTypes,
  RESET_GUEST_AGREEMENTS_LIST,
} from './types'

export function getGuestAgreementsListRequest(): GuestAgreementsListActionTypes {
  return {
    type: GET_GUEST_AGREEMENTS_LIST_REQUEST,
  }
}

export function getGuestAgreementsListSuccess(guestAgreements: MyActivityListDto[]): GuestAgreementsListActionTypes {
  return {
    type: GET_GUEST_AGREEMENTS_LIST_SUCCESS,
    payload: {
      data: guestAgreements,
    },
  }
}

export function resetGuestAgreementsList(): GuestAgreementsListActionTypes {
  return {
    type: RESET_GUEST_AGREEMENTS_LIST,
  }
}
