import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../index'
import { clearNotification } from './action'
import { NotificationType } from './types'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const NotificationBar: React.FC = () => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const { notificationType, notificationMessage } = useSelector(
    (state: RootState) => ({
      notificationType: state.notificationsReducer.type,
      notificationMessage: state.notificationsReducer.message,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (notificationType && notificationMessage) {
      setMessage(notificationMessage)
      setOpen(true)
    }
  }, [notificationType, notificationMessage])

  const handleClose = () => {
    setOpen(false)
    dispatch(clearNotification())
  }

  const getNotificationType = (type: NotificationType | null) => {
    switch (type) {
      case NotificationType.ERROR:
        return 'error'
      case NotificationType.INFO:
        return 'success'
      case NotificationType.WARN:
        return 'warning'
      default:
        return 'error'
    }
  }

  return (
    <Snackbar
      open={open}
      style={{ top: 60 }}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={notificationType === NotificationType.ERROR ? null : 6000}
    >
      <Alert onClose={handleClose} severity={getNotificationType(notificationType)}>
        <span dangerouslySetInnerHTML={{ __html: message }}></span>
      </Alert>
    </Snackbar>
  )
}
export default NotificationBar
