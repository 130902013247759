import {
  ConsumerFavouriteCarParkActionTypes,
  ConsumerFavouriteCarParkState,
  GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_REQUEST,
  GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_SUCCESS,
  RESET_CONSUMER_FAVOURITE_CARPARK,
} from './types'

const initialState: ConsumerFavouriteCarParkState = {
  favouriteCarParkDetails: [],
  isRequestFinished: false,
}

export function consumerFavouriteCarParksReducer(
  state: ConsumerFavouriteCarParkState = initialState,
  action: ConsumerFavouriteCarParkActionTypes,
): ConsumerFavouriteCarParkState {
  switch (action.type) {
    case GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_REQUEST:
      return {
        ...state,
        favouriteCarParkDetails: null,
        isRequestFinished: false,
      }
    case GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_SUCCESS:
      return {
        ...state,
        favouriteCarParkDetails: action.payload.favouriteCarParkDetails,
        isRequestFinished: true,
      }
    case RESET_CONSUMER_FAVOURITE_CARPARK:
      return initialState
    default:
      return state
  }
}
