import { Button, Dialog, DialogContent, SelectChangeEvent, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as IconWarning } from '../../../assets/svg/common/Icon_Warning.svg'
import { Consumer } from '../../../store/auth/types'
import {
  checkConsumerVehicleExistsSuccess,
  resetCheckConsumerVehicleExists,
} from '../../../store/consumer-vehicle/actions'
import { checkAndSaveConsumerVehicle, saveConsumerVehicle } from '../../../store/consumer-vehicle/thunks'
import { CheckConsumerVehicleExists, ConsumerVehicle } from '../../../store/consumer-vehicle/types'
import PlateIssuerSelect from '../auto-complete/vehicle/plate-issuer-select'
import { Transition } from './slide-up-transition'

interface VehicleExistsDialogProps {
  currentConsumer: Consumer
  checkConsumerVehicleExistsResponse: CheckConsumerVehicleExists
}

const VehicleExistsDialog: React.FC<VehicleExistsDialogProps> = (props: VehicleExistsDialogProps) => {
  const { currentConsumer, checkConsumerVehicleExistsResponse } = props

  const dispatch = useDispatch()
  const plateInputRef = useRef<HTMLDivElement | null>(null)

  const [licensePlate, setLicensePlate] = useState<string>('')
  const [selectedPlateIssuer, setSelectedPlateIssuer] = useState<string>('')
  const [openPlateIssuerSelect, setOpenPlateIssuerSelect] = useState<boolean>(false)

  const onClickCancel = () => {
    dispatch(resetCheckConsumerVehicleExists())
  }

  const onClickConfirmOrContinue = () => {
    if (currentConsumer) {
      let saveVehicleReq: ConsumerVehicle = checkConsumerVehicleExistsResponse.consumerVehicle
      if (checkConsumerVehicleExistsResponse.vehicleExistsOnDifferentConsumer) {
        if (saveVehicleReq.plate === licensePlate && saveVehicleReq.plateIssuer === selectedPlateIssuer) {
          dispatch(resetCheckConsumerVehicleExists())
          setTimeout(() => {
            const checkConsumerVehicleExistsResponse: CheckConsumerVehicleExists = {
              consumerVehicle: saveVehicleReq,
              openVehiclePlateDialog: true,
              vehicleExistsOnConsumer: false,
              vehicleExistsOnEntitlement: false,
              vehicleExistsOnDifferentConsumer: false,
            }
            dispatch(checkConsumerVehicleExistsSuccess(checkConsumerVehicleExistsResponse))
          })
        } else {
          saveVehicleReq.plate = licensePlate
          saveVehicleReq.plateIssuer = selectedPlateIssuer
          dispatch(resetCheckConsumerVehicleExists())
          dispatch(checkAndSaveConsumerVehicle(currentConsumer.id, saveVehicleReq))
        }
      } else {
        saveVehicleReq.confirmedOwner = true
        dispatch(resetCheckConsumerVehicleExists())
        dispatch(saveConsumerVehicle(currentConsumer.id, saveVehicleReq))
      }
    }
  }

  return (
    <Dialog
      scroll="paper"
      fullWidth={true}
      TransitionComponent={Transition}
      className="vehicle-exists-dialog"
      open={checkConsumerVehicleExistsResponse.openVehiclePlateDialog}
    >
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container className="form-field-wrapper">
          <Grid item xs={12} md={12} className="error-icon-box">
            <IconWarning className="error-icon" />
          </Grid>
          <Grid item xs={12} md={12} className="mt-12">
            <div className="error-title-text mb-4">
              {checkConsumerVehicleExistsResponse.vehicleExistsOnConsumer
                ? 'Invalid Vehicle Plate!'
                : checkConsumerVehicleExistsResponse.vehicleExistsOnDifferentConsumer
                ? 'Duplicate Vehicle Plate!'
                : 'Warning!'}
            </div>
            {checkConsumerVehicleExistsResponse.vehicleExistsOnConsumer ? (
              <>
                <div className="error-desc-text">The Vehicle Plate you have entered</div>
                <div className="error-desc-text">
                  is already stored against this&nbsp;
                  {checkConsumerVehicleExistsResponse.vehicleExistsOnEntitlement ? 'Entitlement' : 'profile'}.
                </div>
                <div className="error-desc-text">
                  To add this vehicle to your&nbsp;
                  {checkConsumerVehicleExistsResponse.vehicleExistsOnEntitlement ? 'Entitlement' : 'profile'}, you
                </div>
                <div className="error-desc-text">must first delete any other vehicle with</div>
                <div className="error-desc-text">the same Vehicle Plate and Plate Issuer.</div>
              </>
            ) : checkConsumerVehicleExistsResponse.vehicleExistsOnDifferentConsumer ? (
              <>
                <div className="error-desc-text">The Vehicle Plate you have entered</div>
                <div className="error-desc-text">is linked to another active account.</div>
                <div className="error-desc-text">Enter the Vehicle Plate again below</div>
                <div className="error-desc-text">to verify it is correct then select</div>
                <div className="error-desc-text">'Confirm' to continue or 'Cancel' to</div>
                <div className="error-desc-text">enter a different Vehicle Plate.</div>
              </>
            ) : (
              <>
                <div className="error-desc-text">Under Australian Law, it is an offense</div>
                <div className="error-desc-text">to claim ownership of a vehicle you</div>
                <div className="error-desc-text">do not own or have been authorised</div>
                <div className="error-desc-text">to use by the rightful owner.</div>
              </>
            )}
            {checkConsumerVehicleExistsResponse.vehicleExistsOnDifferentConsumer && (
              <>
                <Grid item xs={12} md={12} className="field-box text-center border-top mt-20">
                  <span className="field-label">VEHICLE PLATE</span>
                  <TextField
                    fullWidth
                    placeholder="-"
                    variant="outlined"
                    autoComplete="off"
                    value={licensePlate}
                    inputRef={plateInputRef}
                    onChange={(e) => setLicensePlate(e.target.value.toUpperCase())}
                    inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 10 }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setOpenPlateIssuerSelect(true)
                        if (plateInputRef && plateInputRef.current) {
                          plateInputRef.current.blur()
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className="field-box text-center">
                  <span className="field-label">PLATE ISSUER</span>
                  <PlateIssuerSelect
                    selectedPlateIssuer={selectedPlateIssuer}
                    onChangePlateIssuer={(event: SelectChangeEvent<string | null>) => {
                      setOpenPlateIssuerSelect(false)
                      setSelectedPlateIssuer(event.target.value ? event.target.value : '')
                    }}
                    onClickClear={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                      event.stopPropagation()
                      setSelectedPlateIssuer('')
                    }}
                    openPlateIssuerSelect={openPlateIssuerSelect}
                    setOpenPlateIssuerSelect={setOpenPlateIssuerSelect}
                    forExistingVehicleDialog={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 'auto' }}>
          {checkConsumerVehicleExistsResponse.vehicleExistsOnConsumer ? (
            <Grid item xs={12} md={12}>
              <Button variant="contained" size="large" onClick={onClickCancel}>
                OK
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item xs={6} md={6}>
                <Button variant="contained" size="large" onClick={onClickCancel}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} md={6}>
                <Button
                  size="large"
                  variant="contained"
                  onClick={onClickConfirmOrContinue}
                  disabled={
                    checkConsumerVehicleExistsResponse.vehicleExistsOnDifferentConsumer
                      ? !licensePlate || !selectedPlateIssuer
                      : false
                  }
                >
                  {checkConsumerVehicleExistsResponse.vehicleExistsOnDifferentConsumer ? 'Confirm' : 'Continue'}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default VehicleExistsDialog
