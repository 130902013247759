import {
  ConsumerTransactionsActionTypes,
  ConsumerTransactionsState,
  GET_CONSUMER_TRANSACTIONS_REQUEST,
  GET_CONSUMER_TRANSACTIONS_SUCCESS,
} from './types'

const initialState: ConsumerTransactionsState = {
  loading: false,
  consumerTransactions: [],
}

export function consumerTransactionsReducer(
  state: ConsumerTransactionsState = initialState,
  action: ConsumerTransactionsActionTypes,
): ConsumerTransactionsState {
  switch (action.type) {
    case GET_CONSUMER_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        consumerTransactions: [],
      }
    case GET_CONSUMER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        consumerTransactions: action.payload.data,
      }
    default:
      return state
  }
}
