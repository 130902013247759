import { GeoFeature } from '../../park-and-pay/types'

export interface SearchGeoFeaturesState {
  loading: boolean
  geoFeatures: GeoFeature[]
}

export const SEARCH_GEO_FEATURES_API_PATH = '/geo-feature-api/jurisdictions/:jurisdictionCode/search'

export const SEARCH_GEO_FEATURES_REQUEST = 'SEARCH_GEO_FEATURES_REQUEST'
export const SEARCH_GEO_FEATURES_SUCCESS = 'SEARCH_GEO_FEATURES_SUCCESS'
export const RESET_GEO_FEATURES = 'RESET_GEO_FEATURES'

interface SearchGeoFeaturesRequestAction {
  type: typeof SEARCH_GEO_FEATURES_REQUEST
}

interface SearchGeoFeaturesSuccessAction {
  type: typeof SEARCH_GEO_FEATURES_SUCCESS
  payload: {
    data: GeoFeature[]
  }
}

interface resetGeoFeaturesAction {
  type: typeof RESET_GEO_FEATURES
}

export type SearchGeoFeaturesActionTypes =
  | SearchGeoFeaturesRequestAction
  | SearchGeoFeaturesSuccessAction
  | resetGeoFeaturesAction
