export interface FacilityIdNames {
  id: string
  name: string
  publicId: string
  publicName: string
}

export interface OrderedFacilitiesState {
  facilityIdNames: FacilityIdNames[]
}

export const GET_CONSUMER_ORDERED_FACILITIES_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/my-ordered-facilities/list'

export const GET_CONSUMER_ORDERED_FACILITIES_REQUEST = 'GET_CONSUMER_ORDERED_FACILITIES_REQUEST'
export const GET_CONSUMER_ORDERED_FACILITIES_SUCCESS = 'GET_CONSUMER_ORDERED_FACILITIES_SUCCESS'

export interface getConsumerOrderedFacilitiesRequest {
  type: typeof GET_CONSUMER_ORDERED_FACILITIES_REQUEST
}

export interface getConsumerOrderedFacilitiesSuccess {
  type: typeof GET_CONSUMER_ORDERED_FACILITIES_SUCCESS
  payload: {
    data: FacilityIdNames[]
  }
}

export type OrderedFacilitiesActionTypes = getConsumerOrderedFacilitiesRequest | getConsumerOrderedFacilitiesSuccess
