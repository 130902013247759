export const ERROR_ALLOW_LOCATION_PERMISSION = 'Allow access to the location permission in order to use the app.'
export const ERROR_ALLOW_CAMERA_PERMISSION = 'Allow access to the camera permission in order to use the app.'
export const ERROR_LOCATION_LOADING = "You cannot access the carpark if we can't confirm your location."
export const OOPS_ERROR_MESSAGE = 'Oops! Something went wrong. Please try again.'
export const ERROR_INVALID_QR_CODE = 'Invalid QR Code.'
export const INCORRECT_PASSWORD = 'The password entered is not correct. Please try again.'
export const YOUR_ACCOUNT_DELETED = 'Your account has been Deleted.'

export const SAVE_CARD_TO_PROFILE_MESSAGE = 'Save this card to my Profile.'
export const DELETE_EXISTING_CARD_MESSAGE =
  'To save this card to your profile, you will need to delete one of your existing cards.'
export const MAXIMUM_SAVED_CARD_MESSAGE = 'A maximum of 3 cards can be saved to your profile.'
export const MAKE_DEFAULT_CARD_MESSAGE = 'Make this my default card.'

export const SAVE_VEHICLE_TO_PROFILE_MESSAGE = 'Save this vehicle to my Profile.'
export const DELETE_EXISTING_VEHICLE_MESSAGE =
  'To save this vehicle to your profile, you will need to delete one of your existing vehicles.'
export const MAXIMUM_SAVED_VEHICLE_MESSAGE = 'A maximum of 4 vehicles can be saved to your profile.'
export const MAKE_DEFAULT_VEHICLE_MESSAGE = 'Make this my default vehicle.'
export const PARKING_NOT_AVAILABLE = 'Parking is not available at this time.'
export const SIGN_IN_TO_VIEW_INVOICES = 'Please sign-in to view invoices.'
export const SESSION_DATA_NOT_FOUND = 'Session data was not found in the response.'
