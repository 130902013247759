import { OOPS_ERROR_MESSAGE } from '../../common/messages'
import { AppThunk } from '../index'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { getVehicleMakesRequest, getVehicleMakesSuccess } from './actions'
import { GET_VEHICLE_MAKES_RESOURCE_API_PATH } from './types'

export const getVehicleMakes = (): AppThunk<any> => async (dispatch) => {
  dispatch(getVehicleMakesRequest())
  fetch(GET_VEHICLE_MAKES_RESOURCE_API_PATH)
    .then((response) => response.json())
    .then((json) => {
      dispatch(getVehicleMakesSuccess(json))
    })
    .catch(() => {
      dispatch(setNotification(NotificationType.ERROR, OOPS_ERROR_MESSAGE))
    })
}
