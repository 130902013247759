import { AccessType } from '../access-points/types'

export enum PhysicalFacilityType {
  PrimaryCarPark = 'PrimaryCarPark',
  NestedCarPark = 'NestedCarPark',
}

export interface AccessPointInfo {
  id: string
  accessType: AccessType
  name: string
  publicId: string
}

export interface PublicFacilityDto {
  id: string
  clientRef: string
  publicName: string
  publicId: string
  qrScannerImageUrl?: string
  rqrScannerImageUrl?: string
}

export interface FacilityAccessInfo {
  nestedAccessPoints?: AccessPointInfo[]
  nestedFacilityInfo?: PublicFacilityDto
  physicalFacilityType: PhysicalFacilityType
  primaryAccessPoints: AccessPointInfo[]
  primaryFacilityInfo: PublicFacilityDto
}

export interface FacilityAccessInfoState {
  error: boolean
  facilityAccessInfo: FacilityAccessInfo | null
}

export const GET_FACILITY_ACCESS_INFO_REQUEST = 'GET_FACILITY_ACCESS_INFO_REQUEST'
export const GET_FACILITY_ACCESS_INFO_SUCCESS = 'GET_FACILITY_ACCESS_INFO_SUCCESS'
export const GET_FACILITY_ACCESS_INFO_FAILURE = 'GET_FACILITY_ACCESS_INFO_FAILURE'
export const RESET_FACILITY_ACCESS_INFO = 'RESET_FACILITY_ACCESS_INFO'

interface getFacilityAccessInfoRequestAction {
  type: typeof GET_FACILITY_ACCESS_INFO_REQUEST
}

interface getFacilityAccessInfoSuccessAction {
  type: typeof GET_FACILITY_ACCESS_INFO_SUCCESS
  payload: {
    facilityAccessInfo: FacilityAccessInfo
  }
}

interface getFacilityAccessInfoFailureAction {
  type: typeof GET_FACILITY_ACCESS_INFO_FAILURE
}

interface resetFacilityAccessInfoAction {
  type: typeof RESET_FACILITY_ACCESS_INFO
}

export type FacilityAccessInfoActionTypes =
  | getFacilityAccessInfoRequestAction
  | getFacilityAccessInfoSuccessAction
  | getFacilityAccessInfoFailureAction
  | resetFacilityAccessInfoAction
