import { LoadingActionTypes, LoadingState, SET_LOADING } from './types'

const initialState: LoadingState = {
  loading: false,
}

export function setLoading(loading: boolean): LoadingActionTypes {
  return {
    type: SET_LOADING,
    payload: {
      loading: loading,
    },
  }
}

export function loadingReducer(state = initialState, action: LoadingActionTypes): LoadingState {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }
    default:
      return state
  }
}
