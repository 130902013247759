export const GET_MAP_BOX_SUGGESTIONS_REQUEST = 'GET_MAP_BOX_SUGGESTIONS_REQUEST'
export const GET_MAP_BOX_SUGGESTIONS_SUCCESS = 'GET_MAP_BOX_SUGGESTIONS_SUCCESS'
export const RESET_MAP_BOX_SUGGESTIONS = 'RESET_MAP_BOX_GEO_FEATURES'

export const MAP_BOX_TILE_URL =
  '/map-api/styles/v1/architect-piniada/clewbatgy000w01qaxrpnt7ss/tiles/256/{z}/{x}/{y}?access_token='

export interface MapBoxSuggestion {
  name: string
  name_preferred?: string
  mapbox_id: string
  feature_type: string
  address?: string
  full_address?: string
  place_formatted: string
  context: {
    country?: {
      name: string
      country_code: string
      country_code_alpha_3: string
    }
    region?: {
      id: string
      name: string
      region_code: string
      region_code_full: string
    }
    postcode?: {
      id: string
      name: string
    }
    district?: {
      id: string
      name: string
    }
    place?: {
      id: string
      name: string
    }
    locality?: {
      id: string
      name: string
    }
    neighborhood?: {
      id: string
      name: string
    }
    address?: {
      name: string
      address_number: string
      street_name: string
    }
    street?: {
      name: string
    }
  }
  language?: string
  maki?: string
  poi_category?: string[]
  poi_category_ids?: string[]
  brand?: string
  brand_id?: string
  external_ids?: MapBoxExternalIds
  metadata?: MapBoxExternalMetaData
  geometry?: MapBoxGeometry
  isCarparkFacility?: boolean
}

export interface MapBoxFeature {
  geometry: MapBoxGeometry
  properties: MapBoxProperty
  type: string
  isCarparkFacility?: boolean
}

export interface MapBoxGeometry {
  coordinates: number[]
  type: string
}

export interface MapBoxProperty {
  name: string
  name_preferred?: string
  mapbox_id: string
  feature_type: string
  address?: string
  full_address?: string
  place_formatted?: string
  context: {
    country?: {
      name: string
      country_code: string
      country_code_alpha_3: string
    }
    region?: {
      id: string
      name: string
      region_code: string
      region_code_full: string
    }
    postcode?: {
      id: string
      name: string
    }
    district?: {
      id: string
      name: string
    }
    place?: {
      id: string
      name: string
    }
    locality?: {
      id: string
      name: string
    }
    neighborhood?: {
      id: string
      name: string
    }
    address?: {
      name: string
      address_number: string
      street_name: string
    }
    street?: {
      name: string
    }
  }
  coordinates: MapBoxCoordinates
  bbox?: number[]
  language?: string
  maki?: string
  poi_category?: string[]
  poi_category_ids?: string[]
  brand?: string
  brand_id?: string
  external_ids?: MapBoxExternalIds
  metadata?: MapBoxExternalMetaData
}

export interface MapBoxCoordinates {
  latitude: number
  longitude: number
  accuracy?: string
  routable_points?: MapBoxRoutablePoints[]
}

export interface MapBoxRoutablePoints {
  name: string
  latitude: number
  longitude: number
}

export interface MapBoxExternalIds {
  [key: string]: string
}

export interface MapBoxExternalMetaData {
  [key: string]: string
}

export interface MapBoxSuggestionsState {
  loading: boolean
  mapBoxSuggestions: MapBoxSuggestion[]
}

interface getMapBoxSuggestionsRequestAction {
  type: typeof GET_MAP_BOX_SUGGESTIONS_REQUEST
}

interface getMapBoxSuggestionsSuccessAction {
  type: typeof GET_MAP_BOX_SUGGESTIONS_SUCCESS
  payload: {
    data: MapBoxSuggestion[]
  }
}

interface resetMapBoxSuggestions {
  type: typeof RESET_MAP_BOX_SUGGESTIONS
}

export type MapBoxSuggestionsActionTypes =
  | getMapBoxSuggestionsRequestAction
  | getMapBoxSuggestionsSuccessAction
  | resetMapBoxSuggestions
