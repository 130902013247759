import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { currentConsumer } from '../../store/auth/reducers'
import { getVehicleMakes } from '../../store/vehicle-makes/thunks'
import { preLoadImages } from '../utility'

const useVehicleMakes = () => {
  const dispatch = useDispatch()
  const { consumerVehicles, vehicleMakes } = useSelector(
    (state: RootState) => ({
      consumerVehicles: state.authReducer.vehicles,
      vehicleMakes: state.vehicleMakesReducer.vehicleMakes,
    }),
    shallowEqual,
  )
  const consumer = useSelector(currentConsumer)

  useEffect(() => {
    dispatch(getVehicleMakes())
  }, [dispatch])

  useEffect(() => {
    if (vehicleMakes && consumer && consumerVehicles) {
      const vehicleMakeIconPaths: string[] = consumerVehicles.flatMap((consumerVehicle) =>
        vehicleMakes
          .filter((vehicleMake) => vehicleMake.name === consumerVehicle.makeCode)
          .map((vehicleMake) => '/_cdn/' + vehicleMake.iconPath),
      )
      if (vehicleMakeIconPaths.length > 0) {
        preLoadImages(vehicleMakeIconPaths)
      }
    }
    // eslint-disable-next-line
  }, [vehicleMakes, consumer, consumerVehicles])
}

export default useVehicleMakes
