import React, { createContext, useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { shallowEqual, useSelector } from 'react-redux'
import AppRoutes from './common/app-routes'
import { ROUTE_PATH_PARK_CONFIRM_PAYMENT, ROUTE_PATH_SIGN_IN, ROUTE_PATH_SIGN_UP } from './common/constants'
import useAppConfig from './common/hooks/useAppConfig'
import useAuthStateChange from './common/hooks/useAuthStateChange'
import useBeforeInstallPrompt from './common/hooks/useBeforeInstallPrompt'
import useBrandingImages from './common/hooks/useBrandingImages'
import useConsumerAgreementsEntitlements from './common/hooks/useConsumerAgreementsEntitlements'
import useConsumerNotifications from './common/hooks/useConsumerNotifications'
import useConsumerProfile from './common/hooks/useConsumerProfile'
import useGoogleAnalytics from './common/hooks/useGoogleAnalytics'
import useGuestAgreements from './common/hooks/useGuestAgreements'
import useInitialLoading from './common/hooks/useInitialLoading'
import usePermitConfig from './common/hooks/usePermitConfig'
import useTabTitle from './common/hooks/useTabTitle'
import useVehicleMakes from './common/hooks/useVehicleMakes'
import { SESSION_STORAGE_KEY_BASKET_ID, SESSION_STORAGE_KEY_PARKING_QUOTE_ID } from './common/types'
import Loading from './component/loading/Loading'
import BasketReload from './pages/parking/basket-reload'
import ParkingQuoteReload from './pages/parking/parking-quote-reload'
import { RootState } from './store'
import { getBrandingColour } from './store/branding/reducers'
import { BrandingElementName } from './store/branding/types'
import { ROUTE_PATH_CASUAL_ACCESS_PARKING_SUMMARY, ROUTE_PATH_MANAGE_PARK } from './store/park-and-pay/types'

export const GitShaContext = createContext<string | undefined>('')

export const App: React.FC = () => {
  const location = window.location
  const hostName = window.location.hostname
  const isInitialLoading = useInitialLoading()
  const [reloadingBasketId, setReloadingBasketId] = useState<string | null>(null)
  const [reloadingParkingQuoteId, setReloadingParkingQuoteId] = useState<string | null>(null)

  const { firebaseInitialized, existingBasketId } = useSelector(
    (state: RootState) => ({
      firebaseInitialized: state.authReducer.firebaseInitialized,
      existingBasketId: state.basketReducer.parkingBasket?.id,
    }),
    shallowEqual,
  )

  const homeBGColour = useSelector((state: RootState) =>
    getBrandingColour(state, BrandingElementName.AppHomeBackgroundColour),
  )
  const headerColour = useSelector((state: RootState) => getBrandingColour(state, BrandingElementName.HeaderColour))
  const headerTextColour = useSelector((state: RootState) =>
    getBrandingColour(state, BrandingElementName.HeaderTextColour),
  )
  const buttonColour = useSelector((state: RootState) => getBrandingColour(state, BrandingElementName.ButtonColour))
  const buttonTextColour = useSelector((state: RootState) =>
    getBrandingColour(state, BrandingElementName.ButtonTextColour),
  )
  const formIconColour = useSelector((state: RootState) => getBrandingColour(state, BrandingElementName.FormIconColour))
  const accentColour = useSelector((state: RootState) => getBrandingColour(state, BrandingElementName.AccentColour))
  const linkColour = useSelector((state: RootState) => getBrandingColour(state, BrandingElementName.LinkColour))

  // Reload during flow
  useEffect(() => {
    if (
      !existingBasketId &&
      !location.pathname.includes(ROUTE_PATH_CASUAL_ACCESS_PARKING_SUMMARY) &&
      !location.pathname.includes(ROUTE_PATH_MANAGE_PARK) &&
      !location.pathname.includes(ROUTE_PATH_PARK_CONFIRM_PAYMENT)
    ) {
      const basketId = sessionStorage.getItem(SESSION_STORAGE_KEY_BASKET_ID)
      if (basketId) {
        setReloadingBasketId(basketId)
        console.info('Found basket id in session storage: ' + basketId)
      } else {
        console.debug('no basket id found')
        const parkingQuoteId = sessionStorage.getItem(SESSION_STORAGE_KEY_PARKING_QUOTE_ID)
        if (parkingQuoteId) {
          setReloadingParkingQuoteId(parkingQuoteId)
          console.info('Found parking quote id: ' + parkingQuoteId)
        } else {
          console.debug('no parking quote id found')
        }
      }
    } else {
      console.debug('Basket exists - skip reload')
    }
    // eslint-disable-next-line
  }, [firebaseInitialized])

  function onBasketLoaded() {
    setReloadingBasketId(null)
  }

  function onParkingQuoteLoaded() {
    setReloadingParkingQuoteId(null)
  }

  useAppConfig()
  useTabTitle('')
  usePermitConfig()
  useVehicleMakes()
  useBrandingImages()
  useGoogleAnalytics()
  useGuestAgreements()
  useAuthStateChange()
  useConsumerProfile()
  useConsumerNotifications()
  useConsumerAgreementsEntitlements()
  useBeforeInstallPrompt()

  if (hostName === 'localhost' || (isMobile && !isTablet)) {
    if (isInitialLoading && location.pathname !== ROUTE_PATH_SIGN_IN && location.pathname !== ROUTE_PATH_SIGN_UP) {
      return <Loading />
    } else if (reloadingBasketId) {
      return <BasketReload onLoaded={onBasketLoaded} basketId={reloadingBasketId} />
    } else if (reloadingParkingQuoteId) {
      return <ParkingQuoteReload onLoaded={onParkingQuoteLoaded} parkingQuoteId={reloadingParkingQuoteId} />
    } else {
      return (
        <GitShaContext.Provider value={process.env.REACT_APP_GIT_SHA}>
          <AppRoutes />
          <style>{`
            #root:has(#page-wrapper):has(.home-main-wrapper) {
              background-color: ${homeBGColour} !important; 
            }
            #header-wrapper {
              background : ${headerColour};
            }
            #core-header-text-colour {
              color: ${headerTextColour} !important;
            }
            .core-icon-header-class {
              fill: ${headerTextColour} !important;
            }
            .core-icon-button-class {
              fill: ${buttonTextColour} !important;
            }
            .core-icon-button-color-class {
              fill: ${buttonColour} !important;
            }
            .core-icon-class {
              fill: ${formIconColour} !important;
            }
            .core-icon-stroke-class {
              stroke: ${formIconColour} !important;
            }
            .core-label-color-class {
              color: ${formIconColour} !important;
            }
            .core-accent-class {
              background-color: ${accentColour} !important;
            }
            .core-icon-link-class {
              fill: ${linkColour} !important;
            }
            .core-text-link-class {
              color: ${linkColour} !important;
            }
            .core-border-link-color-class {
              border-color: ${linkColour} !important;
            }
            .MuiButton-root,
            .MuiButton-root.Mui-disabled,
            .MuiButton-root:hover,
            .custom-btn {
              background-color: ${buttonColour} !important;
              color: ${buttonTextColour} !important;
            }
            .MuiSlider-track {
              background-color: ${formIconColour} !important;
            }
            .park-main-wrapper .MuiSlider-track {
              background-color: ${buttonColour} !important;
            }
            .btnRed {
              background-color: #FD5A5F !important;
              color: white !important;
            }
            .btnGreen {
              background-color: #6eb760 !important;
              color: white !important;
            }
          `}</style>
        </GitShaContext.Provider>
      )
    }
  } else {
    return <></>
  }
}

export default App
