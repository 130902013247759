import { SESSION_STORAGE_KEY_BASKET_ID } from '../../common/types'
import {
  BasketActionTypes,
  BasketState,
  GET_CONFIGURED_CHARGES_REQUEST,
  GET_CONFIGURED_CHARGES_SUCCESS,
  RESET_BASKET,
  RESET_BASKET_CONTACT,
  SET_PARKING_BASKET_EXPIRED,
  SET_PARKING_BASKET_REQUEST,
  SET_PARKING_BASKET_SUCCESS,
} from './types'

const initialState: BasketState = {
  parkingBasket: null,
  parkingBasketExpired: false,
  basketParkingItemIdOnly: null,
  costRecoveryCharges: null,
}

export function basketReducer(state: BasketState = initialState, action: BasketActionTypes): BasketState {
  switch (action.type) {
    case SET_PARKING_BASKET_REQUEST:
      return {
        ...state,
        parkingBasketExpired: false,
      }
    case SET_PARKING_BASKET_SUCCESS:
      sessionStorage.setItem(SESSION_STORAGE_KEY_BASKET_ID, action.payload.basket.id)
      return {
        ...state,
        parkingBasket: action.payload.basket,
        basketParkingItemIdOnly: {
          basketId: action.payload.basket.id,
          basketItemId: action.payload.basket.parkingItem.basketItemId,
        },
      }
    case GET_CONFIGURED_CHARGES_REQUEST:
      return {
        ...state,
        parkingBasketExpired: false,
      }
    case GET_CONFIGURED_CHARGES_SUCCESS:
      return {
        ...state,
        costRecoveryCharges: action.payload.charges,
      }
    case SET_PARKING_BASKET_EXPIRED:
      return {
        ...state,
        parkingBasketExpired: true,
      }
    case RESET_BASKET_CONTACT:
      let updatedBasket: any = { ...state.parkingBasket }
      updatedBasket.contact = undefined
      return {
        ...state,
        parkingBasket: updatedBasket,
      }
    case RESET_BASKET:
      return initialState
    default:
      return state
  }
}
