import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { FirebaseAuthConfig, FirebaseConfig } from '../../common/types'

export async function setupFirebase(firebaseConfig: FirebaseConfig, firebaseAuthConfig: FirebaseAuthConfig) {
  const firebaseApp = initializeApp(firebaseConfig)
  const auth = getAuth(firebaseApp)
  auth.tenantId = firebaseAuthConfig.tenantId
  if (await isSupported()) {
    try {
      getMessaging(firebaseApp)
    } catch (error) {
      console.error('FCM: setupFirebase - Firebase messaging is not supported ==>' + JSON.stringify(error))
    }
  } else {
    console.error('FCM: setupFirebase - Firebase messaging is not supported.')
  }
  console.info('Firebase setupFirebase configured')
}

export const requestForToken = async (fcmVapidKey: string, messaging: any) => {
  return getToken(messaging, {
    vapidKey: fcmVapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('FCM: currentToken ==>', currentToken)
        return currentToken
      } else {
        // Show permission request UI
        console.error('FCM: No registration token available, request permission to generate one.')
        messaging.requestPermission()
        return null
      }
    })
    .catch((err) => {
      console.error('FCM: An error occurred while retrieving token ==>', JSON.stringify(err))
      return null
    })
}
