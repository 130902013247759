import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { ROUTE_PATH_HOME } from '../common/constants'
import { currentConsumer } from '../store/auth/reducers'
import { SalesChannelType } from '../store/park-and-pay/types'
import { urlConfigState } from '../store/url-config/reducer'
import ParkingLayout from './parking-layout'

interface IProps {
  path: string
  component: any
  exact?: boolean
  isPrivateRoute?: boolean
  salesChannelType?: SalesChannelType
}

const RouteWithLayout: React.FC<IProps> = (props) => {
  const { component: Component, isPrivateRoute, salesChannelType, ...rest } = props

  const [animationFadeClass, setAnimationFadeClass] = React.useState<string>('fadeInRight')

  const urlConfig = useSelector(urlConfigState)
  const consumer = useSelector(currentConsumer)

  const changeAnimationOnBack = () => {
    setAnimationFadeClass('fadeInLeft')
  }

  const changeAnimationOnNext = () => {
    setAnimationFadeClass('fadeInRight')
  }

  const refreshAnimation = () => {
    setAnimationFadeClass('')
    setTimeout(() => {
      setAnimationFadeClass('fadeInRight')
    })
  }

  window.addEventListener('popstate', () => onPopState())

  const onPopState = () => {
    changeAnimationOnBack()
  }

  const checkIsSalesChannelSupported = (salesChannelType: SalesChannelType): boolean => {
    return urlConfig && urlConfig.supportedSalesChannels?.includes(salesChannelType) ? true : false
  }

  const getRouteComponent = (matchProps: any) => {
    return isPrivateRoute ? (
      consumer ? (
        <Component
          {...matchProps}
          fadeClass={animationFadeClass}
          changeAnimationOnBack={changeAnimationOnBack}
          changeAnimationOnNext={changeAnimationOnNext}
          refreshAnimation={refreshAnimation}
        />
      ) : (
        <Redirect to={ROUTE_PATH_HOME} />
      )
    ) : (
      <Component
        {...matchProps}
        fadeClass={animationFadeClass}
        changeAnimationOnBack={changeAnimationOnBack}
        changeAnimationOnNext={changeAnimationOnNext}
        refreshAnimation={refreshAnimation}
      />
    )
  }

  if (salesChannelType) {
    return (
      <Route
        {...rest}
        children={(matchProps) => (
          <ParkingLayout>
            {checkIsSalesChannelSupported(salesChannelType) ? (
              getRouteComponent(matchProps)
            ) : (
              <Redirect to={ROUTE_PATH_HOME} />
            )}
          </ParkingLayout>
        )}
      />
    )
  } else {
    return <Route {...rest} children={(matchProps) => <ParkingLayout>{getRouteComponent(matchProps)}</ParkingLayout>} />
  }
}

export default RouteWithLayout
