import { v4 as uuidv4 } from 'uuid'

const storageKeyName = 'nuevopark.device.finger.print.id'
const deviceFingerPrintGenerator = () => {
  let signature = localStorage.getItem(storageKeyName) || ''
  if (signature) {
    return signature
  }
  // Generate Random String
  signature = uuidv4()
  // Persist Signature
  localStorage.setItem(storageKeyName, signature)
  return signature
}

export const clearDeviceFingerPrint = () => {
  localStorage.removeItem(storageKeyName)
}

export default deviceFingerPrintGenerator
