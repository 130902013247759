import {
  AccessCredential,
  AccessMode,
  AgreementAuthorityType,
  AgreementType,
  EntryStatus,
  IdName,
  LatestMovementAccessType,
  LatestMovementResult,
  OrderStatus,
  ParkingLineItemStayAgreementType,
} from '../../common/types'
import {
  CalculatedInclusiveTaxItem,
  CalculatedLevy,
  CalculatedServiceCharge,
  PaymentProcessingCharge,
} from '../basket/types'
import { AccessControlType, LocationClass } from '../carpark-long-info/types'
import { Slot } from '../entitlements/types'
import {
  OfferNotification,
  OrderCharge,
  OrderFinancialTransaction,
  OrderMaskedContactDto,
  ParkingLineItem,
  RetailProduct,
  RevisionStatus,
  SalesChannelType,
  SubscriptionLineItemDto,
} from '../park-and-pay/types'
import { TariffTimeUnit, UserClass } from '../parking-quote/types'

export const GET_CONSUMER_AGREEMENTS_REQUEST = 'GET_CONSUMER_AGREEMENTS_REQUEST'
export const GET_CONSUMER_AGREEMENTS_SUCCESS = 'GET_CONSUMER_AGREEMENTS_SUCCESS'

export const GET_CONSUMER_INACTIVE_AGREEMENTS_REQUEST = 'GET_CONSUMER_INACTIVE_AGREEMENTS_REQUEST'
export const GET_CONSUMER_INACTIVE_AGREEMENTS_SUCCESS = 'GET_CONSUMER_INACTIVE_AGREEMENTS_SUCCESS'

export const GET_CONSUMER_AGREEMENT_REQUEST = 'GET_CONSUMER_AGREEMENT_REQUEST'
export const GET_CONSUMER_AGREEMENT_SUCCESS = 'GET_CONSUMER_AGREEMENT_SUCCESS'

export const GET_ORDER_AGREEMENT_REQUEST = 'GET_ORDER_AGREEMENT_REQUEST'
export const GET_ORDER_AGREEMENT_SUCCESS = 'GET_ORDER_AGREEMENT_SUCCESS'
export const GET_ORDER_AGREEMENT_FAILURE = 'GET_ORDER_AGREEMENT_FAILURE'

export const RESET_CONSUMER_AGREEMENTS = 'RESET_CONSUMER_AGREEMENTS'
export const RESET_CONSUMER_AGREEMENT = 'RESET_CONSUMER_AGREEMENT'
export const RESET_CONSUMER_INACTIVE_AGREEMENTS = 'RESET_CONSUMER_INACTIVE_AGREEMENTS'
export const RESET_ORDER_AGREEMENT = 'RESET_ORDER_AGREEMENT'

export const GET_CONSUMER_ACTIVE_AGREEMENTS_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/my-active-order-agreements'
export const GET_CONSUMER_INACTIVE_AGREEMENTS_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/my-agreements'
export const GET_CONSUMER_ORDER_DETAILS_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/my-orders/:orderId'
export const GET_PUBLIC_ORDER_DETAILS_API_RESOURCE_PATH = '/public/operators/:operatorId/orders/:orderId'
export const GET_AGREEMENT_DETAILS_API_RESOURCE_PATH = '/public/operators/:operatorId/my-agreements/:agreementId'

export enum OrderRelationType {
  ExtendedBy = 'ExtendedBy',
  OverstayedBy = 'OverstayedBy',
  AmendedBy = 'AmendedBy',
}

export interface RelatedOrder {
  relationType: OrderRelationType
  orderId: string
}

interface SingleStay {
  active: {
    durationMinutes: number
    finishDateTime: string
    finishInstant: number
    finishDateTimeFormatted: string
    startDateTime: string
    startInstant: number
    startDateTimeFormatted: string
  }
  booked: {
    durationMinutes: number
    finishDateTime: string
    finishInstant: number
    finishDateTimeFormatted: string
    startDateTime: string
    startInstant: number
    startDateTimeFormatted: string
  }
}

interface WeeklyStay {
  active: {
    startInstant: number
    finishInstant: number | null
    startDateTime: string
    finishDateTime: string | null
    slots: Slot[]
  }
  booked: {
    startInstant: number
    finishInstant: number | null
    startDateTime: string
    finishDateTime: string | null
    slots: Slot[]
  }
}

export interface BayInfo {
  bayNumber: string
  bayId: string
  facilityZoneName: string
  facilityZonePublicId: string
}

export interface MyActivityListDto {
  id: string
  orderId: string
  agreement: StayAgreement | null
  vehiclePlate: string | null
  vehiclePlateIssuer?: string
  orderStatus: OrderStatus
  totalCharge: number
  allowCancel: boolean
  allowAmend: boolean
  facility: FacilityInfo
  maskedCardNumber: string
  agreementReference: string
  agreementStatus: AgreementStatus
  agreementType: string
  entryStatus: EntryStatus
  timeZoneId: string
  singleStay: SingleStay
  salesChannelType: SalesChannelType
  latestMovementAccessType: LatestMovementAccessType | null
  latestMovementResult: LatestMovementResult | null
  orderReference: string
  orderItemAgreementType: string
  orderCreatedInstant: number
  orderCreatedDateTime: string
  bayInfo: BayInfo | null
  offer: IdName
  multiEntry: boolean
}

export interface SearchCriteria {
  salesChannelType: string | null
  startDate: string | null
  endDate: string | null
  facilityId: string | null
  agreementReference: string | null
}

export enum AgreementStatus {
  /**
   * Valid agreement. Valid agreement. For long term multiple stay
   * agreements, the status remains Valid until the entire agreement
   * expires.
   */
  Valid = 'Valid',

  /**
   * Single use agreement has been consumed
   * TODO: rename to consumed?
   */
  Completed = 'Completed',

  /**
   * Agreement end date has been exceeded. The end date of the
   * agreement should be checked in realtime if the expiry is time
   * critical.
   */
  Expired = 'Expired',

  /**
   * Agreement has expired and was not consumed.
   */
  NoShow = 'NoShow',

  /**
   * Agreement has been cancelled.
   */
  Cancelled = 'Cancelled',

  /**
   * Agreement has been amended
   */
  Amended = 'Amended',

  Released = 'Released',
}

export enum LinkSource {
  Guest = 'Guest',
}

export interface FacilityInfo {
  id: string
  name: string
  publicId: string
  clientRef: string
  publicName: string
  accessControlType: AccessControlType
  locationClass: LocationClass
}

export interface StayAgreement {
  startDateTime: string
  finishDateTime: string
  recurringCharge: number
  recurringInterval: number
  recurringTimeUnit: TariffTimeUnit
}

export interface ConsumerAgreementState {
  myAgreementsLoading: boolean
  myAgreementsSuccess: boolean
  myAgreement: MyActivityDto | null
  myAgreements: MyActivityListDto[]
  myInactiveAgreements: InactiveOrderAgreement[]
  myAgreementRequestFinished: boolean
  myAgreementsRequestFinished: boolean
  myInactiveAgreementsRequestFinished: boolean
}

export interface OrderAgreementState {
  error: string | null
  orderAgreement: OrderAgreement | null
}

export interface OrderAgreement {
  id: string
  orderId: string
  agreementAuthorityId: string
  agreementType: AgreementType
  timeZoneId: string
  singleStay: SingleStay
  weeklyStay: WeeklyStay | null
  agreementReference: string
  agreementStatus: AgreementStatus
  vehiclePlate: string
  entryStatus: EntryStatus
  multiEntry: boolean
  offerName: string
  latestMovementAccessType: LatestMovementAccessType | null
  latestMovementResult: LatestMovementResult | null
  facility: FacilityInfo
  facilityZone: FacilityInfo | null
  accessCredentials: AccessCredential[]
  accessModes: AccessMode[]
  contact: OrderMaskedContactDto
  agreementCreatedDateTime: string
  agreementCreatedInstant: number
  agreementAuthorityType: AgreementAuthorityType
  orderItemAgreementType: ParkingLineItemStayAgreementType
  salesChannelType: SalesChannelType
  consumerId: string
  orderStatus: OrderStatus
  bayInfo: BayInfo | null
}

export interface InactiveOrderAgreement {
  id: string
  agreementSource: string
  operatorId: string
  startDateTime: string
  startDateTimeFormatted: string
  finishDateTime: string
  finishDateTimeFormatted: string
  timeZoneId: string
  agreementReference: string
  consumerId: string
  agreementStatus: AgreementStatus
  entryStatus: EntryStatus
  facility: FacilityInfo
  facilityZone: FacilityInfo | null
  agreementAuthorityType: AgreementAuthorityType
  agreementAuthorityId: string
  createdDateTime: string
}

export interface MyActivityDto {
  id: string
  agreement: StayAgreement | null
  facility: FacilityInfo
  salesChannelType: SalesChannelType
  orderReferenceId: string
  orderReference: string
  consumerId: string
  createdByUserId: string
  createdByUserClass: UserClass
  operatorId: string
  basketId: string
  orderCreatedInstant: number
  orderCreatedDateTime: string
  vehiclePlate: string
  vehicleMake: string
  subTotalIncTaxes: number
  orderStatus: OrderStatus
  confirmedDetailsCorrect: boolean
  acceptedTermsAndPrivacy: boolean
  sendPromoAndOffers: boolean
  totalCharge: number
  paymentAmount: number
  paymentReceiptNo: string | null
  refundReceiptNo: string | null
  contact: OrderMaskedContactDto
  parkingLineItem: ParkingLineItem
  retailProducts: RetailProduct[]
  charges: OrderCharge[]
  financialTransactions: OrderFinancialTransaction[]
  subscriptionLineItem: SubscriptionLineItemDto
  mobileNumber: string
  mobileCountry: string
  revisionStatus: RevisionStatus
  allowCancel: boolean
  allowAmend: boolean
  allowUpdate: boolean
  allowTimeExtend: boolean
  allowCancelDeadlineInstant: number
  allowAmendDeadlineInstant: number
  allowUpdateDeadlineInstant: number
  allowTimeExtendMinutes: number
  agreementStatus: AgreementStatus
  creditAmount?: number
  utilised: boolean
  finalAmendment: boolean
  relatedOrders: RelatedOrder[] | null
  linkSource: LinkSource | null
  promoCode?: string
}

export interface ShowOrderDetails {
  orderReference: string
  facilityName: string
  facilityId: string
  startDateTime: string
  finishDateTime: string
  startDateTimeFormatted?: string
  finishDateTimeFormatted?: string
  vehiclePlate?: string
  offerName?: string
  offerPrice: number
  discountAmount: number
  discountPercentage?: number
  preDiscountCharge: number
  orderNotifications: OfferNotification[]
  CalculatedInclusiveTaxItem?: CalculatedInclusiveTaxItem[]
  CalculatedLevy?: CalculatedLevy[]
  CalculatedServiceCharges?: CalculatedServiceCharge[]
  PaymentProcessingCharges?: PaymentProcessingCharge[]
  orderCharges?: OrderCharge[]
  subTotalIncTaxes: number
  totalCharge: number
  creditCardType?: string | null
  creditCardNumber?: string | null
  creditCardExpiryMonth?: string | null
  creditCardExpiryYear?: string | null
  carParkId: string
  creditAmount?: number
  entryStatus?: EntryStatus
  agreementStatus?: AgreementStatus
  salesChannelType: SalesChannelType
  orderStatus?: OrderStatus
  paymentReceiptNo: string | null
}

export interface OrderDetailsPromiseResponse {
  id: string
  facility?: FacilityInfo
  financialTransactions: OrderFinancialTransaction[]
  orderCreatedDateTime: string
  paymentDeclined: boolean
  parkingLineItem: ParkingLineItem | null
  relatedOrders: RelatedOrder[] | null
  salesChannelType: SalesChannelType
  success: boolean
}

interface getConsumerAgreementsRequestAction {
  type: typeof GET_CONSUMER_AGREEMENTS_REQUEST
}

interface getConsumerAgreementsSuccessAction {
  type: typeof GET_CONSUMER_AGREEMENTS_SUCCESS
  payload: {
    myAgreements: MyActivityListDto[]
  }
}

interface getConsumerInactiveAgreementsRequestAction {
  type: typeof GET_CONSUMER_INACTIVE_AGREEMENTS_REQUEST
}

interface getConsumerInactiveAgreementsSuccessAction {
  type: typeof GET_CONSUMER_INACTIVE_AGREEMENTS_SUCCESS
  payload: {
    data: InactiveOrderAgreement[]
  }
}

interface getConsumerAgreementRequestAction {
  type: typeof GET_CONSUMER_AGREEMENT_REQUEST
}

interface getConsumerAgreementSuccessAction {
  type: typeof GET_CONSUMER_AGREEMENT_SUCCESS
  payload: {
    myAgreement: MyActivityDto | null
  }
}

interface resetConsumerAgreementsAction {
  type: typeof RESET_CONSUMER_AGREEMENTS
}

interface resetConsumerAgreementAction {
  type: typeof RESET_CONSUMER_AGREEMENT
}

interface resetConsumerInactiveAgreementsAction {
  type: typeof RESET_CONSUMER_INACTIVE_AGREEMENTS
}

// Order Agreement types
interface getOrderAgreementRequestAction {
  type: typeof GET_ORDER_AGREEMENT_REQUEST
}

interface getOrderAgreementSuccessAction {
  type: typeof GET_ORDER_AGREEMENT_SUCCESS
  payload: {
    orderAgreement: OrderAgreement
  }
}

interface getOrderAgreementFailureAction {
  type: typeof GET_ORDER_AGREEMENT_FAILURE
  payload: {
    error: string
  }
}

interface resetOrderAgreementAction {
  type: typeof RESET_ORDER_AGREEMENT
}

export type ConsumerAgreementActionTypes =
  | getConsumerAgreementsRequestAction
  | getConsumerAgreementsSuccessAction
  | getConsumerInactiveAgreementsRequestAction
  | getConsumerInactiveAgreementsSuccessAction
  | getConsumerAgreementRequestAction
  | getConsumerAgreementSuccessAction
  | getOrderAgreementRequestAction
  | getOrderAgreementSuccessAction
  | getOrderAgreementFailureAction
  | resetConsumerAgreementAction
  | resetConsumerAgreementsAction
  | resetConsumerInactiveAgreementsAction
  | resetOrderAgreementAction
