import { SessionState } from '../types'
import { SessionStatus } from '../types'

export interface ResetPasswordLinkReq {
  email: string
}

export interface UpdatePasswordResetResponse {
  id: string
  sessionId: string
  sessionType: string
  sessionState: SessionState
  sessionStatus: SessionStatus
}

export interface ForgotPasswordState {
  updatePasswordResetResponse: UpdatePasswordResetResponse | null
}

export const GET_RESET_PASSWORD_LINK_API_RESOURCE_PATH = '/public/operators/:operatorId/consumers:sendResetPasswordLink'
export const UPDATE_PASSWORD_RESET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:updatePasswordReset'

export const UPDATE_PASSWORD_RESET_SUCCESS = 'UPDATE_PASSWORD_RESET_SUCCESS'
export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD'

interface updateResetPasswordSuccess {
  type: typeof UPDATE_PASSWORD_RESET_SUCCESS
  payload: {
    data: UpdatePasswordResetResponse
  }
}

interface resetForgotPassword {
  type: typeof RESET_FORGOT_PASSWORD
}

export type ForgotPasswordActionTypes = updateResetPasswordSuccess | resetForgotPassword
