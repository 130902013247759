import {
  GET_PARKING_QUOTES_AND_EXIT_TIMES_REQUEST,
  GET_PARKING_QUOTES_AND_EXIT_TIMES_SUCCESS,
  ParkingQuotesAndExitTimesActionTypes,
  ParkingQuotesAndExitTimesState,
  RESET_PARKING_QUOTES_AND_EXIT_TIMES,
} from './types'

const initialState: ParkingQuotesAndExitTimesState = {
  parkingQuotesAndExitTimes: null,
}

export function parkingQuotesAndExitTimesReducer(
  state: ParkingQuotesAndExitTimesState = initialState,
  action: ParkingQuotesAndExitTimesActionTypes,
): ParkingQuotesAndExitTimesState {
  switch (action.type) {
    case GET_PARKING_QUOTES_AND_EXIT_TIMES_REQUEST:
      return {
        ...state,
        parkingQuotesAndExitTimes: null,
      }
    case GET_PARKING_QUOTES_AND_EXIT_TIMES_SUCCESS:
      return {
        ...state,
        parkingQuotesAndExitTimes: action.payload.parkingQuotesAndExitTimes,
      }
    case RESET_PARKING_QUOTES_AND_EXIT_TIMES:
      return initialState
    default:
      return state
  }
}
