import { createFilterOptions } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import React from 'react'
import { useLocation } from 'react-router'
import { AUTHORIZATION_HEADER, BEARER_PREFIX, PARK_API_KEY_HEADER } from '../config/axios/types'
import { RootState } from '../store'
import { FacilityAccessInfo, PublicFacilityDto } from '../store/access-info/types'
import { AccessType } from '../store/access-points/types'
import { Consumer, ConsumerFullProfile } from '../store/auth/types'
import { Basket, Vehicle } from '../store/basket/types'
import { AccessControlType, CarparkLongInfo, LocationClass } from '../store/carpark-long-info/types'
import { ConsumerVehicle } from '../store/consumer-vehicle/types'
import { Territory } from '../store/geo-feature/territories/types'
import { MapBoxSuggestion } from '../store/mapbox-geo-feature/types'
import { AgreementStatus, MyActivityListDto } from '../store/my-agreements/types'
import { AccessibleReason } from '../store/operating-hours/types'
import { CarPark, SalesChannelType } from '../store/park-and-pay/types'
import { ParkingQuoteDto, QuoteWarning } from '../store/parking-quote/types'
import { VehicleMakes } from '../store/vehicle-makes/types'
import { DayOfWeek, DayOfWeekKeyValuePair, EntryStatus, UserAgentType } from './types'

export const findConfiguration = (state: RootState) => {
  const appConfig = state.appConfigReducer
  const parkApiConfig = appConfig.parkApiConfig
  const tenantConfig = appConfig.tenantConfig
  const operatorId = tenantConfig?.config.operatorId
  const coreUrl = parkApiConfig?.coreUrl
  const tenantId = tenantConfig?.tenant.id
  const apiKey = tenantConfig?.tenant.apiKey
  const firebaseUser = state.authReducer.firebaseUser
  const currentUser = state.authReducer.user
  // Check for mandatory config
  if (coreUrl == null) throw Error('url missing from parkApiConfig')
  if (tenantId == null) throw Error('tenantId missing from tenantConfig')
  if (apiKey == null) throw Error('apiKey missing from tenantConfig')
  if (operatorId == null) throw Error('operatorId missing from tenantConfig')
  return { coreUrl, tenantId, apiKey, operatorId, firebaseUser, currentUser }
}

export const buildAxiosConfig = async (state: RootState, isForConsumer?: boolean): Promise<AxiosRequestConfig> => {
  const { appConfigReducer, authReducer } = state
  const { parkApiConfig, tenantConfig } = appConfigReducer
  const coreUrl = parkApiConfig?.coreUrl
  const apiKey = tenantConfig?.tenant.apiKey
  const firebaseUser = authReducer.firebaseUser
  const currentConsumer = authReducer.consumer

  if (coreUrl == null) throw new Error('url missing from parkApiConfig')
  if (apiKey == null) throw new Error('apiKey missing from tenantConfig')

  const headers: Record<string, string> = {
    [PARK_API_KEY_HEADER]: apiKey,
  }
  if (firebaseUser) {
    const idToken = await firebaseUser.getIdToken()
    if (isForConsumer || currentConsumer) {
      headers[AUTHORIZATION_HEADER] = BEARER_PREFIX + idToken
    }
  }
  return {
    baseURL: coreUrl,
    headers,
  }
}

export const buildPaymentAxiosConfig = async (
  state: RootState,
  isForConsumer?: boolean,
): Promise<AxiosRequestConfig> => {
  const { appConfigReducer, authReducer } = state
  const { paymentApiConfig, tenantConfig } = appConfigReducer
  const paymentApiUrl = paymentApiConfig?.paymentApiUrl
  const apiKey = tenantConfig?.tenant.apiKey
  const firebaseUser = authReducer.firebaseUser
  const currentConsumer = authReducer.consumer

  if (paymentApiUrl == null) throw new Error('url missing from paymentApiConfig')
  if (apiKey == null) throw new Error('apiKey missing from tenantConfig')

  const headers: Record<string, string> = {
    [PARK_API_KEY_HEADER]: apiKey,
  }
  if (firebaseUser) {
    const idToken = await firebaseUser.getIdToken()
    if (isForConsumer || currentConsumer) {
      headers[AUTHORIZATION_HEADER] = BEARER_PREFIX + idToken
    }
  }
  return {
    baseURL: paymentApiUrl,
    headers,
  }
}

export const getConvertedStingTime = (milliseconds: number, isForPark?: boolean, isForSearch?: boolean) => {
  const { days, hours, minutes } = getTimeParamsFromMillisecond(milliseconds)
  let convertedTime: string = ''
  const defaultFormatting = () => {
    if (days > 0) {
      convertedTime = isForSearch ? days * 24 + 'hr' : days + (isForPark ? 'd ' : days > 1 ? ' days ' : ' day ')
    }
    if (hours > 0) {
      convertedTime += hours + (isForPark ? 'hr ' : hours > 1 ? ' hrs ' : ' hr ')
    }
    if (minutes > 0) {
      convertedTime += minutes + (isForPark ? 'min' : minutes > 1 ? ' mins' : ' min')
    }
  }
  if (isForPark) {
    if (days > 0 && hours === 0 && minutes === 0) {
      convertedTime = days + (days > 1 ? ' days' : ' day')
    } else if (hours > 0 && days === 0 && minutes === 0) {
      convertedTime = hours + (hours > 1 ? ' hrs' : ' hr')
    } else if (minutes > 0 && days === 0 && hours === 0) {
      convertedTime = minutes + (minutes > 1 ? ' mins' : ' min')
    } else {
      defaultFormatting()
    }
  } else {
    defaultFormatting()
  }
  return convertedTime
}

export const getTimeParamsFromMillisecond = (milliseconds: number) => {
  const seconds = Math.floor((milliseconds / 1000) % 60)
  const minutes = Math.floor((milliseconds / 1000 / 60) % 60)
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24)
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24))
  return {
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  }
}

export const convertMinutesToTime = (minutes: number): string => {
  if (minutes === 1440) {
    return '12:00am'
  } else {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    const formattedHours = hours < 10 ? `0${hours}` : hours > 12 ? `${hours - 12}` : `${hours}`
    const formattedMinutes = remainingMinutes < 10 ? `0${remainingMinutes}` : `${remainingMinutes}`
    return `${formattedHours}:${formattedMinutes}${hours < 12 ? 'am' : 'pm'}`
  }
}

export const isIOS = () => {
  const browserInfo = navigator.userAgent.toLowerCase()

  if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
    return true
  }
  if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
    return true
  }
  return false
}

export const EMAIL_REGULAR_EXPRESSION =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const getCurrentInstant = (): number => {
  return dayjs().utc().unix()
}

export const checkIsActiveInstant = (startInstant: number, finishInstant: number): boolean => {
  const currentInstant = getCurrentInstant()
  return startInstant <= currentInstant && currentInstant <= finishInstant
}

export const PHONE_NUMBER_REGULAR_EXPRESSION = /^04\d{8}$/

export const CHARACTERS_REGULAR_EXPRESSION = /^[a-zA-Z\s'-]+$/

export const CAPITALIZE_STRING_REGULAR_EXPRESSION = /(^\w{1})|(\s{1}\w{1})|(-\w{1})/g

export const MASK_CARD_NUMBER_REGULAR_EXPRESSION = /^(\d{4})\d+(\d{4})$/

export const MASK_NON_DIGIT_REGEX = /\D/g
export const MASK_NON_ALPHANUMERIC_REGEX = /[^a-zA-Z0-9]/g
export const MASK_NON_ORDER_REFERENCE_REGEX = /[^a-zA-Z0-9-]/g

export const VISA_REGEX = /^4[0-9]{12}(?:[0-9]{3})?$/
export const MASTERCARD_REGEX = /^5[1-5][0-9]{14}$/
export const AMEX_REGEX = /^3[47][0-9]{13}$/

export const STATIC_VEHICLE_ID = 'static-vehicle-id'
export const STATIC_CARD_ID = 'static-card-id'

export const validateCreditCardNumber = (cardNumber: string): boolean => {
  if (VISA_REGEX.test(cardNumber)) {
    return true
  } else if (MASTERCARD_REGEX.test(cardNumber)) {
    return true
  } else if (AMEX_REGEX.test(cardNumber)) {
    return true
  } else {
    return false
  }
}

export const convertLongToShortInfo = (carparkLongInfo: CarparkLongInfo): CarPark => {
  let carparkShortInfo: CarPark = {
    id: carparkLongInfo.id,
    publicName: carparkLongInfo.publicName,
    publicId: carparkLongInfo.publicId,
    clearance: carparkLongInfo.clearance,
    location: {
      latitude: carparkLongInfo.mapPoint.latitude,
      longitude: carparkLongInfo.mapPoint.longitude,
    },
    address: carparkLongInfo.address,
    operatorId: carparkLongInfo.operatorId,
    amenities: [],
    marketing: null,
    code: carparkLongInfo.code,
    clientRef: carparkLongInfo.clientRef,
    name: carparkLongInfo.name,
    basePriceLabel: carparkLongInfo.marketing.basePriceLabel,
    basePriceFee: carparkLongInfo.marketing.basePriceFee,
    basePriceUnit: carparkLongInfo.marketing.basePriceUnit,
    version: carparkLongInfo.version,
    mainImageUrl: carparkLongInfo.mainImageUrl,
    timeZoneId: carparkLongInfo.timeZoneId,
    endTimeMinutes: 0,
    zoneShortInfo: null,
    accessControlType: carparkLongInfo.accessControlType,
    locationClass: carparkLongInfo.locationClass,
    distanceFromPointMetres: carparkLongInfo.distanceFromPointMetres,
  }
  return carparkShortInfo
}

export const getConsumerFromFullProfile = (consumerFullProfile: ConsumerFullProfile): Consumer => {
  const consumer: Consumer = {
    id: consumerFullProfile.id,
    email: consumerFullProfile.email,
    mobile: {
      number: consumerFullProfile.mobile.number,
      country: consumerFullProfile.mobile.country,
    },
    operatorId: consumerFullProfile.operatorId,
    firstName: consumerFullProfile.firstName,
    lastName: consumerFullProfile.lastName,
    consumerType: consumerFullProfile.consumerType,
    timeZoneId: consumerFullProfile.timeZoneId,
    sendPromoAndOffers: consumerFullProfile.sendPromoAndOffers,
  }
  return consumer
}

export const getAgreementStatusFormattedName = (agreementStatus: AgreementStatus): string => {
  switch (agreementStatus) {
    case AgreementStatus.Valid:
      return 'Valid'
    case AgreementStatus.Completed:
      return 'Completed'
    case AgreementStatus.Expired:
      return 'Expired'
    case AgreementStatus.Amended:
      return 'Amended'
    case AgreementStatus.Cancelled:
      return 'Cancelled'
    case AgreementStatus.NoShow:
      return 'No Show'
    default:
      return ''
  }
}

export const getEntryStatusFormattedName = (entryStatus: EntryStatus): string => {
  switch (entryStatus) {
    case EntryStatus.OffSite:
      return 'Off-Site'
    case EntryStatus.OnSite:
      return 'On-Site'
    case EntryStatus.Suspect:
      return 'Suspect'
    case EntryStatus.TransitIn:
      return 'Transit-In'
    case EntryStatus.TransitOut:
      return 'Transit-Out'
    case EntryStatus.Fallback:
      return 'Fallback'
    default:
      return '-'
  }
}

export const getAccessTypeFromEntryStatus = (entryStatus: EntryStatus): AccessType => {
  if (entryStatus === EntryStatus.OffSite) {
    return AccessType.Entry
  } else {
    return AccessType.Exit
  }
}

export const getFormattedAccessType = (accessType: AccessType): string => {
  if (accessType === AccessType.Entry) {
    return 'Enter'
  } else if (accessType === AccessType.Exit) {
    return 'Exit'
  } else {
    return ''
  }
}

export const getFormattedAccessibleReason = (accessibleReason: AccessibleReason | null): string => {
  switch (accessibleReason) {
    case AccessibleReason.AccessRestriction:
      return 'Access Restriction'
    case AccessibleReason.Clearway:
      return 'Clearway'
    case AccessibleReason.Closed:
      return 'Closed'
    case AccessibleReason.EventParking:
      return 'Event Parking'
    case AccessibleReason.PermitParking:
      return 'Permit Parking'
    case AccessibleReason.ResidentParking:
      return 'Resident Parking'
    case AccessibleReason.Restricted:
      return 'Restricted Parking'
    case AccessibleReason.Unrestricted:
      return 'Unrestricted Parking'
    case AccessibleReason.BusLane:
      return 'Bus Lane'
    case AccessibleReason.BusZone:
      return 'Bus Zone'
    case AccessibleReason.TaxiZone:
      return 'Taxi Zone'
    case AccessibleReason.NoStopping:
      return 'No Stopping'
    case AccessibleReason.NoParking:
      return 'No Parking'
    case AccessibleReason.TimeLimited:
      return 'Time Limited Parking'
    default:
      return '-'
  }
}

export const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const getFormattedTimeRangeFromMinutes = (fromMinutes: number, toMinutes: number) => {
  if ((fromMinutes === 0 && toMinutes === 1440) || (fromMinutes === 0 && toMinutes === 0)) {
    return 'All Day'
  } else {
    let fromTime = getMinutes(fromMinutes)
    let toTime = getMinutes(toMinutes)
    return fromTime + ' - ' + toTime
  }
}

export function getMinutes(totalMinutes: number) {
  let hours = totalMinutes / 60
  let rHours = Math.floor(hours)
  let minutes = (hours - rHours) * 60
  let rMinutes = Math.round(minutes)
  if (rHours < 12) {
    return rHours + ':' + getTwoDigitNumber(rMinutes) + 'am'
  } else if (rHours === 12) {
    return rHours + ':' + getTwoDigitNumber(rMinutes) + 'pm'
  } else {
    return rHours - 12 + ':' + getTwoDigitNumber(rMinutes) + 'pm'
  }
}

function getTwoDigitNumber(number: number) {
  if (number < 10) {
    return '0' + number
  } else {
    return number
  }
}

export const weekList: DayOfWeekKeyValuePair[] = [
  {
    key: DayOfWeek.MONDAY,
    value: 'Monday',
  },
  {
    key: DayOfWeek.TUESDAY,
    value: 'Tuesday',
  },
  {
    key: DayOfWeek.WEDNESDAY,
    value: 'Wednesday',
  },
  {
    key: DayOfWeek.THURSDAY,
    value: 'Thursday',
  },
  {
    key: DayOfWeek.FRIDAY,
    value: 'Friday',
  },
  {
    key: DayOfWeek.SATURDAY,
    value: 'Saturday',
  },
  {
    key: DayOfWeek.SUNDAY,
    value: 'Sunday',
  },
]

export enum BasePriceUnit {
  Minutes_15 = 'Minutes_15',
  Minutes_30 = 'Minutes_30',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Fortnight = 'Fortnight',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year',
}

export const getFormattedBasePriceUnit = (basePriceUnit: BasePriceUnit): string => {
  switch (basePriceUnit) {
    case BasePriceUnit.Minutes_15:
      return ' per 15 minutes'
    case BasePriceUnit.Minutes_30:
      return ' per 30 minutes'
    default:
      return ' per ' + basePriceUnit.toLowerCase()
  }
}

export const isKerbsideFacility = (accessControlType: AccessControlType, locationClass: LocationClass): boolean => {
  return accessControlType === AccessControlType.Enforced && locationClass === LocationClass.OnStreet
}

export const preLoadImages = async (srcArray: string[]) => {
  const promises = await srcArray.map((src) => {
    return new Promise(function (resolve, reject) {
      const img = new Image()
      img.src = src
      img.onload = resolve
      img.onerror = reject
    })
  })
  await Promise.all(promises)
}

export const getIsDefaultForVehicleAndCard = (length: number, isDefault: boolean): boolean | null => {
  return length > 0 ? isDefault : null
}

export const validateQrCodeHostname = (url: URL): boolean => {
  const hostname = url.hostname
  const currentHostname = window.location.hostname
  return currentHostname === hostname || currentHostname === 'localhost'
}

export const getFormattedSuggestion = (geoFeature: MapBoxSuggestion): string => {
  const AusStateMappings: Record<string, string> = {
    'Australian Capital Territory': 'ACT',
    'New South Wales': 'NSW',
    'Northern Territory': 'NT',
    Queensland: 'QLD',
    'South Australia': 'SA',
    Tasmania: 'TAS',
    Victoria: 'VIC',
    'Western Australia': 'WA',
  }
  let geoFeatureName = geoFeature.feature_type === 'address' ? '' : geoFeature.name
  if (geoFeature.full_address) {
    geoFeatureName = geoFeatureName ? geoFeatureName + ' ' + geoFeature.full_address : geoFeature.full_address
  } else if (geoFeature.address) {
    geoFeatureName = geoFeatureName ? geoFeatureName + ' ' + geoFeature.address : geoFeature.address
  } else {
    geoFeatureName = geoFeatureName ? geoFeatureName + ' ' + geoFeature.place_formatted : geoFeature.place_formatted
  }
  let formattedGeoFeatureName: string = geoFeatureName.endsWith(', Australia')
    ? geoFeatureName.substring(0, geoFeatureName.length - ', Australia'.length)
    : geoFeatureName
  for (const [state, abbreviation] of Object.entries(AusStateMappings)) {
    if (formattedGeoFeatureName.includes(state)) {
      formattedGeoFeatureName = formattedGeoFeatureName.replace(state, abbreviation)
      break
    }
  }
  return formattedGeoFeatureName.replaceAll(',', '')
}

export const getSortedConsumerVehicles = (consumerVehicles: ConsumerVehicle[]): ConsumerVehicle[] => {
  return consumerVehicles.sort((a: ConsumerVehicle, b: ConsumerVehicle) => {
    if (a.defaultSelected && !b.defaultSelected) {
      return -1
    }
    if (!a.defaultSelected && b.defaultSelected) {
      return 1
    }
    if (a.makeCode < b.makeCode) {
      return -1
    }
    if (a.makeCode > b.makeCode) {
      return 1
    }
    return 0
  })
}

export const getSortedVehicleMakes = (vehicleMakes: VehicleMakes[]): VehicleMakes[] => {
  return vehicleMakes.sort((a: VehicleMakes, b: VehicleMakes) => {
    if (a.name === 'Other' && b.name !== 'Other') {
      return 1
    }
    if (a.name !== 'Other' && b.name === 'Other') {
      return -1
    }
    return a.name.localeCompare(b.name)
  })
}

export const getSortedTerritories = (territories: Territory[]): Territory[] => {
  return territories.sort((a: Territory, b: Territory) => {
    if (a.code < b.code) {
      return -1
    }
    if (a.code > b.code) {
      return 1
    }
    return 0
  })
}

export const filterSuggestions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: MapBoxSuggestion) => getFormattedSuggestion(option),
})

export const filterStringOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: string) => option,
})

export const checkIsValidUrl = (strText: string) => {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
  return urlPattern.test(strText)
}

export const checkIsNotSVG = (imgUrl: string) => {
  const imgUrlPattern = /\.(?:png|jpe?g)/
  return imgUrlPattern.test(imgUrl)
}

export const checkDuplicateBookingExists = (
  parkingQuote: ParkingQuoteDto | null,
  parkingBasket: Basket | null,
  consumerAgreements: MyActivityListDto[],
  selectedVehicle: ConsumerVehicle | Vehicle,
): boolean => {
  if (parkingQuote && parkingQuote.warnings && parkingQuote.warnings.includes(QuoteWarning.OverlappingActiveOrder)) {
    if (consumerAgreements.length > 0) {
      const requestedStartInstant: number = parkingBasket
        ? parkingBasket.parkingItem.stayAgreements[0].singleStay.startInstant
        : 0
      const requestedFinishInstant: number = parkingBasket
        ? parkingBasket.parkingItem.stayAgreements[0].singleStay.finishInstant
        : 0
      const validResAgreements: MyActivityListDto[] = consumerAgreements.filter(
        (consumerAgreement) => consumerAgreement.salesChannelType === SalesChannelType.Res,
      )
      const agreementsAtSameFacilityAndTime: MyActivityListDto[] = validResAgreements.filter(
        (validResAgreement) =>
          validResAgreement.facility.id === parkingQuote.requestedFacilityIds[0] &&
          Math.max(requestedStartInstant, validResAgreement.singleStay.booked.startInstant) <
            Math.min(requestedFinishInstant, validResAgreement.singleStay.booked.finishInstant),
      )
      const duplicateBooking: MyActivityListDto | undefined = agreementsAtSameFacilityAndTime.find(
        (agreementAtSameFacilityAndTime) =>
          agreementAtSameFacilityAndTime.vehiclePlate === selectedVehicle.plate.toUpperCase() &&
          agreementAtSameFacilityAndTime.vehiclePlateIssuer === selectedVehicle.plateIssuer,
      )
      if (duplicateBooking) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

export const handleKeyDown = (
  event: React.KeyboardEvent<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>,
  validToTrigger: boolean,
  triggerButtonClick: () => void,
) => {
  if (event.key === 'Enter' && validToTrigger) {
    triggerButtonClick()
  }
}

export const getVehicleMakesIconPath = (vehicleMakes: VehicleMakes[], makeCode: string) => {
  return '/_cdn/' + vehicleMakes.find((vehicleMake) => vehicleMake.name === makeCode)?.iconPath
}

export const getDynamicScannerImgUrl = (
  facilityAccessInfo: FacilityAccessInfo | null,
  entryStatus?: EntryStatus,
  rqr?: boolean,
): string | undefined => {
  if (facilityAccessInfo && entryStatus) {
    const { nestedFacilityInfo, primaryFacilityInfo } = facilityAccessInfo
    const isTransitOrOnSite = entryStatus === EntryStatus.TransitIn || entryStatus === EntryStatus.OnSite

    const getScannerImageUrl = (facilityInfo: PublicFacilityDto) => {
      if (rqr && facilityInfo.rqrScannerImageUrl) {
        preLoadImages([facilityInfo.rqrScannerImageUrl])
      } else if (facilityInfo.qrScannerImageUrl) {
        preLoadImages([facilityInfo.qrScannerImageUrl])
      }
      return rqr ? facilityInfo.rqrScannerImageUrl : facilityInfo.qrScannerImageUrl
    }

    if (nestedFacilityInfo) {
      if (isTransitOrOnSite) {
        return getScannerImageUrl(nestedFacilityInfo) || getScannerImageUrl(primaryFacilityInfo)
      } else {
        return getScannerImageUrl(primaryFacilityInfo) || getScannerImageUrl(nestedFacilityInfo)
      }
    } else {
      return getScannerImageUrl(primaryFacilityInfo)
    }
  } else {
    return undefined
  }
}

export const convertStringToHashHex = async (strToConvert: string): Promise<string> => {
  // Convert the input string to a Uint8Array containing the UTF-8 encoded bytes
  const utf8 = new TextEncoder().encode(strToConvert)
  // Use the SubtleCrypto API to perform a SHA-256 hash on the encoded bytes
  return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
    // Convert the resulting hash buffer to a Uint8Array
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    // Map each byte to its hexadecimal representation, and join them to form the final hash string
    const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('')
    // Return the final hash string
    return hashHex
  })
}

export const openLink = (linkUrl: string, doNotOpenNewTab?: boolean) => {
  window.open(linkUrl, doNotOpenNewTab ? '_self' : undefined)
}

export const getDocumentReferrer = () => {
  return document?.referrer ? document.referrer : ''
}

export const getUserAgentType = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches
  return isStandalone ? UserAgentType.PWA : UserAgentType.MobileBrowser
}

export const checkIsPermit = (agreement: MyActivityListDto) => {
  if (
    agreement.facility.locationClass === LocationClass.OffStreet &&
    agreement.facility.accessControlType === AccessControlType.Enforced
  ) {
    return true
  } else {
    return false
  }
}
