import { GET_TERRITORIES_REQUEST, GET_TERRITORIES_SUCCESS, TerritoriesActionTypes, TerritoriesState } from './types'

const initialState: TerritoriesState = {
  territories: [],
}

export function territoriesReducer(
  state: TerritoriesState = initialState,
  action: TerritoriesActionTypes,
): TerritoriesState {
  switch (action.type) {
    case GET_TERRITORIES_REQUEST:
      return {
        ...state,
        territories: [],
      }
    case GET_TERRITORIES_SUCCESS:
      return {
        ...state,
        territories: action.payload.data,
      }
    default:
      return state
  }
}
