export const GET_TERRITORIES_API_RESOURCE_PATH = '/geo-feature-api/jurisdictions/aus/territories/list-all'

export const GET_TERRITORIES_REQUEST = 'GET_TERRITORIES_REQUEST'
export const GET_TERRITORIES_SUCCESS = 'GET_TERRITORIES_SUCCESS'

export interface Territory {
  id: string
  code: string
  name: string
}

interface getTerritoriesRequestAction {
  type: typeof GET_TERRITORIES_REQUEST
}

interface getTerritoriesSuccessAction {
  type: typeof GET_TERRITORIES_SUCCESS
  payload: {
    data: Territory[]
  }
}

export interface TerritoriesState {
  territories: Territory[]
}

export type TerritoriesActionTypes = getTerritoriesRequestAction | getTerritoriesSuccessAction
