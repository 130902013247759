import axios from 'axios'
import { AppThunk } from '..'
import { HTTP_RESPONSE_CODE_400, HTTP_RESPONSE_CODE_404 } from '../../common/constants'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { setLoading } from '../loading/reducers'
import { MobileVerificationUpdate } from '../mobile-verification/types'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import {
  getMobileVerificationCodeForConsumerRequest,
  getMobileVerificationCodeForConsumerSuccess,
  getSessionByIdRequest,
  getSessionByIdSuccess,
  resendEmailVerificationForConsumerRequest,
  resendEmailVerificationForConsumerSuccess,
  resendEmailVerificationRequest,
  resendEmailVerificationSuccess,
  resendMobileVerificationCodeRequest,
  resendMobileVerificationCodeSuccess,
  resendMobileVerificationLinkForConsumerRequest,
  resendMobileVerificationLinkForConsumerSuccess,
  updateEmailVerificationRequest,
  updateEmailVerificationSuccess,
  updateMobileVerificationForSignUpRequest,
  updateMobileVerificationForSignUpSuccess,
} from './actions'
import {
  EmailVerificationRequest,
  GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_API_RESOURCE_PATH,
  GET_SESSION_BY_ID_API_RESOURCE_PATH,
  RESEND_MOBILE_VERIFICATION_CODE_API_RESOURCE_PATH,
  RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_API_RESOURCE_PATH,
  RESEND_PUBLIC_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_API_RESOURCE_PATH,
  RESEND_PUBLIC_VERIFICATION_EMAIL_FOR_CONSUMER_API_RESOURCE_PATH,
  RESEND_VERIFICATION_EMAIL_API_RESOURCE_PATH,
  RESEND_VERIFICATION_EMAIL_FOR_CONSUMER_API_RESOURCE_PATH,
  UPDATE_EMAIL_VERIFICATION_API_RESOURCE_PATH,
  UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_API_RESOURCE_PATH,
} from './types'

export const resendVerificationEmail =
  (sessionId: string, doNotShowError?: boolean): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let resendVerificationEmailSuccess: boolean = false
    dispatch(setLoading(true))
    dispatch(resendEmailVerificationRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    await axios
      .post(
        RESEND_VERIFICATION_EMAIL_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(':sessionId', sessionId),
        {},
        authConfig,
      )
      .then((response) => {
        dispatch(setLoading(false))
        resendVerificationEmailSuccess = true
        dispatch(resendEmailVerificationSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (!doNotShowError) {
          if (typeof error === 'object') {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
    return resendVerificationEmailSuccess
  }

export const getSessionById =
  (sessionId: string): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let getSessionSuccess: boolean = false
    dispatch(getSessionByIdRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    await axios
      .get(
        GET_SESSION_BY_ID_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(':sessionId', sessionId),
        authConfig,
      )
      .then((response) => {
        getSessionSuccess = true
        dispatch(getSessionByIdSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return getSessionSuccess
  }

export const updateEmailVerification =
  (sessionId: string, emailVerificationRequest: EmailVerificationRequest): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let updateEmailVerified: boolean = false
    dispatch(updateEmailVerificationRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    await axios
      .post(
        UPDATE_EMAIL_VERIFICATION_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(':sessionId', sessionId),
        emailVerificationRequest,
        authConfig,
      )
      .then((response) => {
        updateEmailVerified = true
        dispatch(updateEmailVerificationSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return updateEmailVerified
  }

export const resendMobileVerificationCode =
  (sessionId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    dispatch(resendMobileVerificationCodeRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    await axios
      .post(
        RESEND_MOBILE_VERIFICATION_CODE_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':sessionId',
          sessionId,
        ),
        {},
        authConfig,
      )
      .then((response) => {
        dispatch(setLoading(false))
        dispatch(resendMobileVerificationCodeSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }

export const updateMobileVerificationForSignUp =
  (sessionId: string, mobileVerificationUpdate: MobileVerificationUpdate): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let updateMobileVerified: boolean = false
    dispatch(updateMobileVerificationForSignUpRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    await axios
      .post(
        UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':sessionId',
          sessionId,
        ),
        mobileVerificationUpdate,
        authConfig,
      )
      .then((response) => {
        updateMobileVerified = true
        dispatch(updateMobileVerificationForSignUpSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          if (error.code === HTTP_RESPONSE_CODE_404) {
            dispatch(setNotification(NotificationType.ERROR, 'Invalid verification code.'))
          } else if (error.code === HTTP_RESPONSE_CODE_400) {
            dispatch(
              setNotification(
                NotificationType.ERROR,
                'The verification code has expired. Please press ‘resend verification code’ and try again.',
              ),
            )
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
    return updateMobileVerified
  }

export const resendVerificationEmailForConsumer =
  (consumerEmail: string, consumerId?: string): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let resendEmailForConsumerSuccess: boolean = false
    dispatch(setLoading(true))
    dispatch(resendEmailVerificationForConsumerRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    const resourcePath: string = consumerId
      ? RESEND_VERIFICATION_EMAIL_FOR_CONSUMER_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        )
      : RESEND_PUBLIC_VERIFICATION_EMAIL_FOR_CONSUMER_API_RESOURCE_PATH.replace(':operatorId', operatorId)
    await axios
      .post(resourcePath, consumerId ? {} : { email: consumerEmail }, authConfig)
      .then((response) => {
        dispatch(setLoading(false))
        resendEmailForConsumerSuccess = true
        dispatch(resendEmailVerificationForConsumerSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return resendEmailForConsumerSuccess
  }

export const resendMobileVerificationLinkForConsumer =
  (consumerEmail: string, consumerId?: string): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let resendMobileLinkForConsumerSuccess: boolean = false
    dispatch(setLoading(true))
    dispatch(resendMobileVerificationLinkForConsumerRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    const resourcePath: string = consumerId
      ? RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        )
      : RESEND_PUBLIC_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_API_RESOURCE_PATH.replace(':operatorId', operatorId)
    await axios
      .post(resourcePath, consumerId ? {} : { email: consumerEmail }, authConfig)
      .then((response) => {
        dispatch(setLoading(false))
        resendMobileLinkForConsumerSuccess = true
        dispatch(resendMobileVerificationLinkForConsumerSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return resendMobileLinkForConsumerSuccess
  }

export const getMobileVerificationCodeForConsumer =
  (sessionId: string): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let getMobileVerificationCodeSuccess: boolean = false
    dispatch(getMobileVerificationCodeForConsumerRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    await axios
      .post(
        GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':sessionId',
          sessionId,
        ),
        {},
        authConfig,
      )
      .then((response) => {
        getMobileVerificationCodeSuccess = true
        dispatch(getMobileVerificationCodeForConsumerSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return getMobileVerificationCodeSuccess
  }
