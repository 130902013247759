import {
  ConsumerAgreementActionTypes,
  ConsumerAgreementState,
  GET_CONSUMER_AGREEMENTS_REQUEST,
  GET_CONSUMER_AGREEMENTS_SUCCESS,
  GET_CONSUMER_AGREEMENT_REQUEST,
  GET_CONSUMER_AGREEMENT_SUCCESS,
  GET_CONSUMER_INACTIVE_AGREEMENTS_REQUEST,
  GET_CONSUMER_INACTIVE_AGREEMENTS_SUCCESS,
  GET_ORDER_AGREEMENT_FAILURE,
  GET_ORDER_AGREEMENT_REQUEST,
  GET_ORDER_AGREEMENT_SUCCESS,
  OrderAgreementState,
  RESET_CONSUMER_AGREEMENT,
  RESET_CONSUMER_AGREEMENTS,
  RESET_CONSUMER_INACTIVE_AGREEMENTS,
  RESET_ORDER_AGREEMENT,
} from './types'

const initialState: ConsumerAgreementState = {
  myAgreementsLoading: false,
  myAgreementsSuccess: false,
  myAgreement: null,
  myAgreements: [],
  myInactiveAgreements: [],
  myAgreementRequestFinished: false,
  myAgreementsRequestFinished: false,
  myInactiveAgreementsRequestFinished: false,
}

export function myAgreementsReducer(
  state: ConsumerAgreementState = initialState,
  action: ConsumerAgreementActionTypes,
): ConsumerAgreementState {
  switch (action.type) {
    case GET_CONSUMER_AGREEMENTS_REQUEST:
      return {
        ...state,
        myAgreements: [],
        myAgreementsLoading: true,
        myAgreementsSuccess: false,
        myAgreementsRequestFinished: false,
      }
    case GET_CONSUMER_AGREEMENTS_SUCCESS:
      return {
        ...state,
        myAgreementsSuccess: true,
        myAgreementsLoading: false,
        myAgreementsRequestFinished: true,
        myAgreements: action.payload.myAgreements,
      }
    case GET_CONSUMER_INACTIVE_AGREEMENTS_REQUEST:
      return {
        ...state,
        myInactiveAgreements: [],
        myInactiveAgreementsRequestFinished: false,
      }
    case GET_CONSUMER_INACTIVE_AGREEMENTS_SUCCESS:
      return {
        ...state,
        myInactiveAgreements: action.payload.data,
        myInactiveAgreementsRequestFinished: true,
      }
    case GET_CONSUMER_AGREEMENT_REQUEST:
      return {
        ...state,
        myAgreement: null,
        myAgreementRequestFinished: false,
      }
    case GET_CONSUMER_AGREEMENT_SUCCESS:
      return {
        ...state,
        myAgreementRequestFinished: true,
        myAgreement: action.payload.myAgreement,
      }
    case RESET_CONSUMER_AGREEMENTS:
      return {
        ...state,
        myAgreementsSuccess: false,
        myAgreements: [],
        myAgreementsRequestFinished: false,
      }
    case RESET_CONSUMER_AGREEMENT:
      return {
        ...state,
        myAgreement: null,
        myAgreementRequestFinished: false,
      }
    case RESET_CONSUMER_INACTIVE_AGREEMENTS:
      return {
        ...state,
        myInactiveAgreements: [],
        myInactiveAgreementsRequestFinished: false,
      }
    default:
      return state
  }
}

// Order Agreement
const initialOrderAgreementState: OrderAgreementState = {
  error: null,
  orderAgreement: null,
}

export function orderAgreementReducer(
  state: OrderAgreementState = initialOrderAgreementState,
  action: ConsumerAgreementActionTypes,
): OrderAgreementState {
  switch (action.type) {
    case GET_ORDER_AGREEMENT_REQUEST:
      return {
        ...state,
        orderAgreement: null,
      }
    case GET_ORDER_AGREEMENT_SUCCESS:
      return {
        ...state,
        orderAgreement: action.payload.orderAgreement,
      }
    case GET_ORDER_AGREEMENT_FAILURE:
      return {
        ...state,
      }
    case RESET_ORDER_AGREEMENT:
      return initialOrderAgreementState
    default:
      return state
  }
}
