import { RootState } from '..'
import { GET_URL_CONFIG_REQUEST, GET_URL_CONFIG_SUCCESS, UrlConfigActionTypes, UrlConfigState } from './types'

const initialState: UrlConfigState = {
  urlConfig: null,
}

export function urlConfigReducer(state: UrlConfigState = initialState, action: UrlConfigActionTypes): UrlConfigState {
  switch (action.type) {
    case GET_URL_CONFIG_REQUEST:
      return {
        ...state,
        urlConfig: null,
      }
    case GET_URL_CONFIG_SUCCESS:
      return {
        ...state,
        urlConfig: action.payload.data,
      }
    default:
      return state
  }
}

export const urlConfigState = (state: RootState) => {
  return state.urlConfigReducer.urlConfig
}
