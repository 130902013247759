import { RootState } from '..'
import {
  BrandingActionTypes,
  BrandingElementName,
  BrandingIconType,
  BrandingState,
  GET_BRANDING_REQUEST,
  GET_BRANDING_SUCCESS,
} from './types'

const initialState: BrandingState = {
  branding: null,
  isRequestCompleted: false,
}

export function brandingReducer(state: BrandingState = initialState, action: BrandingActionTypes): BrandingState {
  switch (action.type) {
    case GET_BRANDING_REQUEST:
      return {
        ...state,
        branding: null,
        isRequestCompleted: false,
      }
    case GET_BRANDING_SUCCESS:
      return {
        ...state,
        branding: action.payload.data,
        isRequestCompleted: true,
      }
    default:
      return state
  }
}

export const getBrandingState = (state: RootState) => {
  return state.brandingReducer.branding
}

export const getBrandingColour = (state: RootState, type: BrandingElementName): string => {
  const branding = state.brandingReducer.branding
  const elements = branding?.elements
  const elementColour = elements?.find((element) => element.name === type)
  const colourValue = elementColour?.colourValue?.value
  return colourValue ? colourValue : ''
}

export const getBrandingImageUrl = (state: RootState, type: BrandingElementName): string => {
  const branding = state.brandingReducer.branding
  const elements = branding?.elements
  const elementImageUrl = elements?.find((element) => element.name === type)
  const imageUrl = elementImageUrl?.imageValue?.url
  return imageUrl ? imageUrl : ''
}

export const getBrandingIconUrl = (state: RootState, type: BrandingIconType): string => {
  const branding = state.brandingReducer.branding
  const icons = branding?.icons
  const iconFav64 = icons?.find((icon) => icon.type === type)
  const imageUrl = iconFav64?.imageValue?.url
  return imageUrl ? imageUrl : ''
}
