import {
  AccessPointActionTypes,
  AccessPointState,
  POST_ACCESS_POINT_FAILURE,
  POST_ACCESS_POINT_REQUEST,
  POST_ACCESS_POINT_SUCCESS,
  POST_CASUAL_ACCESS_POINT_FAILURE,
  POST_CASUAL_ACCESS_POINT_REQUEST,
  POST_CASUAL_ACCESS_POINT_SUCCESS,
  RESET_ACCESS_POINT_DELIVERY_STATUS,
} from './types'

const initialState: AccessPointState = {
  consumerAccessError: false,
  casualAccessError: false,
  consumerAccess: null,
  casualAccess: null,
}

export function accessPointReducer(
  state: AccessPointState = initialState,
  action: AccessPointActionTypes,
): AccessPointState {
  switch (action.type) {
    case POST_ACCESS_POINT_REQUEST:
      return {
        ...state,
        consumerAccess: null,
        consumerAccessError: false,
      }
    case POST_ACCESS_POINT_SUCCESS:
      return {
        ...state,
        consumerAccess: action.payload.consumerAccess,
      }
    case POST_ACCESS_POINT_FAILURE:
      return {
        ...state,
        consumerAccessError: true,
      }
    case POST_CASUAL_ACCESS_POINT_REQUEST:
      return {
        ...state,
        casualAccess: null,
        casualAccessError: false,
      }
    case POST_CASUAL_ACCESS_POINT_SUCCESS:
      return {
        ...state,
        casualAccess: action.payload.data,
      }
    case POST_CASUAL_ACCESS_POINT_FAILURE:
      return {
        ...state,
        casualAccessError: true,
      }
    case RESET_ACCESS_POINT_DELIVERY_STATUS:
      return initialState
    default:
      return state
  }
}
