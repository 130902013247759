import { OOPS_ERROR_MESSAGE } from '../../common/messages'
import { AppThunk } from '../index'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { tenantConfigRequest, tenantConfigSuccess } from './actions'

export const getTenantConfigThunk =
  (tenantCode: string): AppThunk<any> =>
  async (dispatch) => {
    dispatch(tenantConfigRequest())
    const tenantPath = '/_tenant/park/' + tenantCode + '.json'
    fetch(tenantPath)
      .then((response) => response.json())
      .then((json) => dispatch(tenantConfigSuccess(json)))
      .catch(() => {
        dispatch(setNotification(NotificationType.ERROR, OOPS_ERROR_MESSAGE))
      })
  }
