import { BayReservedType, DayOfWeek, EntryStatus } from '../../common/types'
import { AgreementStatus } from '../my-agreements/types'

export const GET_ENTITLEMENTS_REQUEST = 'GET_ENTITLEMENTS_REQUEST'
export const GET_ENTITLEMENTS_SUCCESS = 'GET_ENTITLEMENTS_SUCCESS'
export const RESET_ENTITLEMENTS = 'RESET_ENTITLEMENTS'

export const GET_CONSUMER_ENTITLEMENTS_API_RESOURCE_PATH = '/operators/:operatorId/consumers/:consumerId/entitlements'

export interface Entitlement {
  id: string
  reference: string
  consumer: string
  firstName: string
  lastName: string
  facilityName: string
  bayReserveType: BayReservedType
  bayInfo: string
  bayNo: string
  allocationName: string | null
  layoutName: string
  zoneName: string
  startDate: string
  finishDate: string | null
  entryStatus: EntryStatus
  agreementStatus: AgreementStatus
  consumerReference: string
  consumerId: string
  timeZoneId: string
  createdInstant: number
  createdDateTime: string
  facilityId: string
  facilityPublicId: string
  facilityPublicName: string
  slots: Slot[]
}

export interface Slot {
  dayOfWeek: DayOfWeek | string
  fromMinutes: number | null
  toMinutes: number | null
}

interface getEntitlementsRequestAction {
  type: typeof GET_ENTITLEMENTS_REQUEST
}

interface getEntitlementsSuccessAction {
  type: typeof GET_ENTITLEMENTS_SUCCESS
  payload: {
    data: Entitlement[]
  }
}

interface resetEntitlementsAction {
  type: typeof RESET_ENTITLEMENTS
}

export interface EntitlementState {
  loading: boolean
  success: boolean
  entitlements: Entitlement[]
  isRequestFinished: boolean
}

export type EntitlementsActionTypes =
  | getEntitlementsRequestAction
  | getEntitlementsSuccessAction
  | resetEntitlementsAction
