import { GET_PERMIT_CONFIG_REQUEST, GET_PERMIT_CONFIG_SUCCESS, PermitConfig, PermitConfigActionTypes } from './types'

export function getPermitConfigRequest(): PermitConfigActionTypes {
  return {
    type: GET_PERMIT_CONFIG_REQUEST,
  }
}

export function getPermitConfigSuccess(permitConfig: PermitConfig): PermitConfigActionTypes {
  return {
    type: GET_PERMIT_CONFIG_SUCCESS,
    payload: {
      data: permitConfig,
    },
  }
}
