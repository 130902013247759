import { ErrorResponse } from '../../config/axios/types'
import { OrderAgreement } from '../my-agreements/types'

export const GUEST_ORDER_LOOKUP_REQUEST = 'GUEST_ORDER_LOOKUP_REQUEST'
export const GUEST_ORDER_LOOKUP_SUCCESS = 'GUEST_ORDER_LOOKUP_SUCCESS'
export const RESET_GUEST_ORDER_LOOKUP = 'RESET_GUEST_ORDER_LOOKUP'

export const GET_GUEST_AGREEMENTS_REQUEST = 'GET_GUEST_AGREEMENTS_REQUEST'
export const GET_GUEST_AGREEMENTS_SUCCESS = 'GET_GUEST_AGREEMENTS_SUCCESS'
export const RESET_GUEST_AGREEMENTS = 'RESET_GUEST_AGREEMENTS'

export interface GuestOrderSearchCriteria {
  orderReference: string
  receiptNo: string | null
  vehiclePlate: string
}

export interface GuestAgreementState {
  loading: boolean
  success: boolean
  error: string | null
  orderId: string | null
  orderAgreements: OrderAgreement[] | null
}

export interface OrderLookup {
  id: string
}

export interface GuestAgreementsPromiseResponse {
  error: ErrorResponse | null
  orderAgreement: OrderAgreement[] | null
}

// guest order lookup

interface guestOrderLookupRequestAction {
  type: typeof GUEST_ORDER_LOOKUP_REQUEST
}

interface guestOrderLookupSuccessAction {
  type: typeof GUEST_ORDER_LOOKUP_SUCCESS
  payload: {
    orderId: string
  }
}

interface resetGuestOrderLookupAction {
  type: typeof RESET_GUEST_ORDER_LOOKUP
}

// guest agreements

interface getGuestAgreementsRequestAction {
  type: typeof GET_GUEST_AGREEMENTS_REQUEST
}

interface getGuestAgreementsSuccessAction {
  type: typeof GET_GUEST_AGREEMENTS_SUCCESS
  payload: {
    orderAgreements: OrderAgreement[]
  }
}

interface resetGuestAgreementsAction {
  type: typeof RESET_GUEST_AGREEMENTS
}

export type GuestAgreementActionTypes =
  | guestOrderLookupRequestAction
  | guestOrderLookupSuccessAction
  | resetGuestOrderLookupAction
  | getGuestAgreementsRequestAction
  | getGuestAgreementsSuccessAction
  | resetGuestAgreementsAction
