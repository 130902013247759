import {
  FacilityIdNames,
  GET_CONSUMER_ORDERED_FACILITIES_REQUEST,
  GET_CONSUMER_ORDERED_FACILITIES_SUCCESS,
  OrderedFacilitiesActionTypes,
} from './types'

export function getConsumerOrderedFacilitiesRequest(): OrderedFacilitiesActionTypes {
  return {
    type: GET_CONSUMER_ORDERED_FACILITIES_REQUEST,
  }
}

export function getConsumerOrderedFacilitiesSuccess(facilityIdNames: FacilityIdNames[]): OrderedFacilitiesActionTypes {
  return {
    type: GET_CONSUMER_ORDERED_FACILITIES_SUCCESS,
    payload: {
      data: facilityIdNames,
    },
  }
}
