export const GET_ALL_CONSUMER_APP_NOTIFICATIONS_SUCCESS = 'GET_ALL_CONSUMER_APP_NOTIFICATIONS_SUCCESS'

export const DELETE_CONSUMER_APP_NOTIFICATIONS_SUCCESS = 'DELETE_CONSUMER_APP_NOTIFICATIONS_SUCCESS'

export const CLEAR_CONSUMER_APP_NOTIFICATIONS_SUCCESS = 'CLEAR_CONSUMER_APP_NOTIFICATIONS_SUCCESS'

export interface LocalStorageAppNotification {
  consumerId: string
  appNotifications: AppNotification[]
}

export interface AppNotification {
  messageId: string
  notification: Notification
}

export interface LocalStorageAppNotificationItem {
  consumerId: string
  appNotification: AppNotification
}

export interface Notification {
  title?: string
  body?: string
  orderId?: string
  agreementId?: string
  agreementReference?: string
  agreementAuthorityType?: string
}

export interface AppNotificationState {
  appNotifications: AppNotification[]
}

interface getAllConsumerAppNotificationSuccess {
  type: typeof GET_ALL_CONSUMER_APP_NOTIFICATIONS_SUCCESS
  payload: {
    appNotifications: AppNotification[]
  }
}

interface clearAllConsumerAppNotificationSuccess {
  type: typeof CLEAR_CONSUMER_APP_NOTIFICATIONS_SUCCESS
}

interface deleteConsumerAppNotificationSuccess {
  type: typeof DELETE_CONSUMER_APP_NOTIFICATIONS_SUCCESS
  payload: {
    appNotifications: AppNotification[]
  }
}

export type AppNotificationActionsTypes =
  | getAllConsumerAppNotificationSuccess
  | clearAllConsumerAppNotificationSuccess
  | deleteConsumerAppNotificationSuccess
