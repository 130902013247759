import { CLEAR_NOTIFICATION, NotificationActionTypes, NotificationType, SET_NOTIFICATION } from './types'

export function setNotification(type: NotificationType, message: string): NotificationActionTypes {
  return {
    type: SET_NOTIFICATION,
    payload: {
      type: type,
      message: message,
    },
  }
}

export function clearNotification(): NotificationActionTypes {
  return {
    type: CLEAR_NOTIFICATION,
  }
}
