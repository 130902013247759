import { CarParkFacility } from '../carpark-facilities/types'
import { MapBoxFeature, MapBoxSuggestion } from '../mapbox-geo-feature/types'
import { MyActivityDto } from '../my-agreements/types'
import { OrderFormActionTypes, Point, SearchFacilityFilters, SelectedContactDetails, SelectedDuration } from './types'

export const INIT_FLOW = 'INIT_FLOW'
export const SELECT_CARPARK_FACILITY = 'SELECT_CARPARK_FACILITY'
export const SELECT_LANDMARK = 'SELECT_LANDMARK'
export const POSITION_SEARCH = 'POSITION_SEARCH'
export const SELECT_MAP_BOX_SUGGESTION = 'SELECT_MAP_BOX_SUGGESTION'
export const SELECT_DURATION = 'SELECT_DURATION'
export const SELECT_CONTACT_DETAILS = 'SELECT_CONTACT_DETAILS'
export const SET_CONSUMER_ORDER = 'SET_CONSUMER_ORDER' //Amend
export const SET_CONSUMER_ORDER_TO_EXTEND = 'SET_CONSUMER_ORDER_TO_EXTEND' //Extend
export const SET_SEARCHED_FACILITY_FILTERS = 'SET_SEARCHED_FACILITY_FILTERS'
export const SET_MAP_VIEW = 'SET_MAP_VIEW'
export const RESET_ORDER_FORM = 'RESET_ORDER_FORM'

export function initFlowAction(): OrderFormActionTypes {
  return {
    type: INIT_FLOW,
  }
}

export function selectCarParkFacility(selectedCarParkFacility: CarParkFacility): OrderFormActionTypes {
  return {
    type: SELECT_CARPARK_FACILITY,
    payload: {
      data: selectedCarParkFacility,
    },
  }
}

export function selectLandmark(selectedLandmark: MapBoxFeature | null): OrderFormActionTypes {
  return {
    type: SELECT_LANDMARK,
    payload: {
      data: selectedLandmark,
    },
  }
}

export function positionSearch(selectedPoint: Point): OrderFormActionTypes {
  return {
    type: POSITION_SEARCH,
    payload: {
      data: selectedPoint,
    },
  }
}

export function selectMapBoxSuggestion(selectedMapBoxSuggestion: MapBoxSuggestion | null): OrderFormActionTypes {
  return {
    type: SELECT_MAP_BOX_SUGGESTION,
    payload: {
      data: selectedMapBoxSuggestion,
    },
  }
}

export function selectDuration(selectedDuration: SelectedDuration): OrderFormActionTypes {
  return {
    type: SELECT_DURATION,
    payload: {
      data: selectedDuration,
    },
  }
}

export function selectContactDetails(selectedContactDetails: SelectedContactDetails): OrderFormActionTypes {
  return {
    type: SELECT_CONTACT_DETAILS,
    payload: {
      data: selectedContactDetails,
    },
  }
}

export function setConsumerOrderToAmend(consumerOrderToAmend: MyActivityDto): OrderFormActionTypes {
  return {
    type: SET_CONSUMER_ORDER,
    payload: {
      data: consumerOrderToAmend,
    },
  }
}

export function setConsumerOrderToExtend(consumerOrderToExtend: MyActivityDto): OrderFormActionTypes {
  return {
    type: SET_CONSUMER_ORDER_TO_EXTEND,
    payload: {
      data: consumerOrderToExtend,
    },
  }
}

export function setSearchedFacilityFilters(searchedFacilityFilters: SearchFacilityFilters): OrderFormActionTypes {
  return {
    type: SET_SEARCHED_FACILITY_FILTERS,
    payload: {
      data: searchedFacilityFilters,
    },
  }
}

export function setIsMapView(isMapView: boolean): OrderFormActionTypes {
  return {
    type: SET_MAP_VIEW,
    payload: {
      data: isMapView,
    },
  }
}

export function resetOrderForm(): OrderFormActionTypes {
  return {
    type: RESET_ORDER_FORM,
  }
}
