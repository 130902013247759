import { createTheme } from '@mui/material/styles'
import MuiButton from './overrides/MuiButton'
import MuiSlider from './overrides/MuiSlider'
import MuiTextField from './overrides/MuiTextField'
import palette from './palette'

export const theme = createTheme({
  palette,
  components: {
    MuiTextField,
    MuiButton,
    MuiSlider,
  },
})
