export const GET_VEHICLE_MAKES_REQUEST = 'GET_VEHICLE_MAKES_REQUEST'
export const GET_VEHICLE_MAKES_SUCCESS = 'GET_VEHICLE_MAKES_SUCCESS'

export const GET_VEHICLE_MAKES_RESOURCE_API_PATH = '/_cdn/vehiclemake/v1.json'

export interface VehicleMakes {
  name: string
  iconPath: string
}

export interface VehicleMakesState {
  vehicleMakes: VehicleMakes[]
}

interface getVehicleMakesRequestAction {
  type: typeof GET_VEHICLE_MAKES_REQUEST
}

interface getVehicleMakesSuccessAction {
  type: typeof GET_VEHICLE_MAKES_SUCCESS
  payload: {
    vehicleMakes: VehicleMakes[]
  }
}

export type VehicleMakesActionTypes = getVehicleMakesRequestAction | getVehicleMakesSuccessAction
