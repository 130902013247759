export interface PwaInstallState {
  deferredPrompt: any | null
}

export enum AppInstallStatus {
  WaitingForInstalled = 'WaitingForInstalled',
  Cancelled = 'Cancelled',
}

export const SET_DEFERRED_PROMPT = 'SET_DEFERRED_PROMPT'

interface setDeferredPromptAction {
  type: typeof SET_DEFERRED_PROMPT
  payload: {
    data: any
  }
}

export type PwaInstallActionTypes = setDeferredPromptAction
