import { IdOnly } from '../basket/types'

export const SAVE_CONSUMER_VEHICLE_REQUEST = 'SAVE_CONSUMER_VEHICLE_REQUEST'
export const SAVE_CONSUMER_VEHICLE_SUCCESS = 'SAVE_CONSUMER_VEHICLE_SUCCESS'

export const CHECK_CONSUMER_VEHICLE_EXISTS_REQUEST = 'CHECK_CONSUMER_VEHICLE_EXISTS_REQUEST'
export const CHECK_CONSUMER_VEHICLE_EXISTS_SUCCESS = 'CHECK_CONSUMER_VEHICLE_EXISTS_SUCCESS'

export const RESET_SAVE_CONSUMER_VEHICLE = 'RESET_SAVE_CONSUMER_VEHICLE'
export const RESET_CHECK_CONSUMER_VEHICLE_EXISTS = 'RESET_CHECK_CONSUMER_VEHICLE_EXISTS'

export interface ConsumerVehicle {
  id: string | null
  plate: string
  makeCode: string
  consumerId: string | null
  plateIssuer: string
  defaultSelected: boolean | null
  confirmedOwner?: boolean
}

export interface CheckConsumerVehicleExists {
  consumerVehicle: ConsumerVehicle
  openVehiclePlateDialog: boolean
  vehicleExistsOnConsumer: boolean
  vehicleExistsOnEntitlement: boolean
  vehicleExistsOnDifferentConsumer: boolean
}

export interface ConsumerVehicleState {
  saveVehicleSuccess: boolean
  savedVehicleId: IdOnly | null
  checkConsumerVehicleExists: CheckConsumerVehicleExists | null
}

interface saveConsumerVehicleRequestAction {
  type: typeof SAVE_CONSUMER_VEHICLE_REQUEST
}

interface saveConsumerVehicleSuccessAction {
  type: typeof SAVE_CONSUMER_VEHICLE_SUCCESS
  payload: {
    data: IdOnly
  }
}

interface checkConsumerVehicleExistsRequestAction {
  type: typeof CHECK_CONSUMER_VEHICLE_EXISTS_REQUEST
}

interface checkConsumerVehicleExistsSuccessAction {
  type: typeof CHECK_CONSUMER_VEHICLE_EXISTS_SUCCESS
  payload: {
    data: CheckConsumerVehicleExists
  }
}

interface resetSaveConsumerVehicleAction {
  type: typeof RESET_SAVE_CONSUMER_VEHICLE
}

interface resetCheckConsumerVehicleExistsAction {
  type: typeof RESET_CHECK_CONSUMER_VEHICLE_EXISTS
}

export type ConsumerVehicleActionTypes =
  | saveConsumerVehicleRequestAction
  | saveConsumerVehicleSuccessAction
  | checkConsumerVehicleExistsRequestAction
  | checkConsumerVehicleExistsSuccessAction
  | resetSaveConsumerVehicleAction
  | resetCheckConsumerVehicleExistsAction
