import {
  Entitlement,
  EntitlementsActionTypes,
  GET_ENTITLEMENTS_REQUEST,
  GET_ENTITLEMENTS_SUCCESS,
  RESET_ENTITLEMENTS,
} from './types'

export function getEntitlementsRequest(): EntitlementsActionTypes {
  return {
    type: GET_ENTITLEMENTS_REQUEST,
  }
}

export function getEntitlementsSuccess(entitlements: Entitlement[]): EntitlementsActionTypes {
  return {
    type: GET_ENTITLEMENTS_SUCCESS,
    payload: {
      data: entitlements,
    },
  }
}

export function resetEntitlements(): EntitlementsActionTypes {
  return {
    type: RESET_ENTITLEMENTS,
  }
}
