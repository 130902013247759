import axios from 'axios'
import { buildAxiosConfig } from '../../../common/utility'
import { ErrorResponse } from '../../../config/axios/types'
import { AppThunk } from '../../index'
import { setNotification } from '../../notifications/action'
import { NotificationType } from '../../notifications/types'
import { getTerritoriesRequest, getTerritoriesSuccess } from './actions'
import { GET_TERRITORIES_API_RESOURCE_PATH } from './types'

export const getTerritories = (): AppThunk<any> => async (dispatch, getState) => {
  dispatch(getTerritoriesRequest())
  const authConfig = await buildAxiosConfig(getState())
  await axios
    .get(GET_TERRITORIES_API_RESOURCE_PATH, authConfig)
    .then((response) => {
      dispatch(getTerritoriesSuccess(response.data))
    })
    .catch((error: ErrorResponse | string) => {
      if (typeof error === 'object') {
        dispatch(setNotification(NotificationType.ERROR, error.message))
      }
    })
}
