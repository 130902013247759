import axios from 'axios'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { AppThunk, RootState } from '../index'
import { postConsumerPresenceSuccess } from './actions'
import { MemberPresence } from './types'

const CONSUMER_PRESENCE_RESOURCE_PATH = '/operators/:operatorId/consumers/:consumerId/presences'

export const postPresence =
  (consumerId: string, memberPresence: MemberPresence): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState())
    await axios
      .post(
        CONSUMER_PRESENCE_RESOURCE_PATH.replace(':consumerId', consumerId).replace(':operatorId', operatorId),
        memberPresence,
        authConfig,
      )
      .then((response) => {
        dispatch(postConsumerPresenceSuccess(response.data))
      })
      .catch(() => {})
  }

export const deletePresence =
  (consumerId: string, presenceId: string): AppThunk<any> =>
  async (_dispatch, getState) => {
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState())
    await axios
      .delete(
        CONSUMER_PRESENCE_RESOURCE_PATH.replace(':consumerId', consumerId).replace(':operatorId', operatorId) +
          '/' +
          presenceId,
        authConfig,
      )
      .then(() => {})
      .catch(() => {})
  }
