import { IdOnly } from '../basket/types'

export const POST_CONSUMER_PRESENCE_SUCCESS = 'POST_CONSUMER_PRESENCE_SUCCESS'

export interface MemberPresence {
  id: string | null
  messagingToken: string | null
  deviceFingerprint: string | null
  deviceDetails: MemberDevice | null
  userAgent: String | null // TODO app name / version or browser user agent.
}

export interface MemberDevice {
  make: string
  model: string
  operatingSystem: string
}

export interface ConsumerPresenceState {
  idOnly: IdOnly | null
}

interface postConsumerPresenceSuccessAction {
  type: typeof POST_CONSUMER_PRESENCE_SUCCESS
  payload: {
    idOnly: IdOnly | null
  }
}

export type ConsumerPresenceActionTypes = postConsumerPresenceSuccessAction
