import { CatalogueInfo, RetailProductCategory } from '../basket/types'
import { SalesChannelType, VirtualMeterInfo } from '../park-and-pay/types'
import { ExistingParkingQuoteItem } from '../parking-quotes-exit-times/types'

export const GET_QUOTE_REQUEST = 'GET_QUOTE_REQUEST'
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS'
export const GET_QUOTE_FAILURE = 'GET_QUOTE_FAILURE'
export const SET_QUOTE_EXPIRED = 'SET_QUOTE_EXPIRED'
export const RESET_QUOTE = 'RESET_QUOTE'

export enum QuoteWarning {
  FullValueOfExtendedOrder = 'FullValueOfExtendedOrder',
  OverlappingActiveOrder = 'OverlappingActiveOrder',
  Restricted = 'Restricted',
}

export interface RelatedAgreement {
  relationType: AgreementRelationType
  id: string
}

export interface ParkingQuoteRequestDto {
  kind: string
  salesChannelType: string | null
  consumerId?: string
  vehicleQuantity?: number
  stayQuantity?: number
  facilityIds?: string[]
  stayRequest?: StayRequest
  relatedParkingItem?: RelatedAgreement
  agreementId?: string
  offerId?: string
  promoCode?: string
}

export interface ParkingQuoteDto {
  id: string
  kind: 'ParkingQuote'
  selfLink?: string
  quote: QuoteDto
  vehicleQuantity: number
  requestedFacilityIds: string[]
  stayRequest: StayRequest
  itemList: ParkingQuoteItem[]
  relatedAgreement?: RelatedAgreement
  quoteResult: QuoteResult
  existingList: ExistingParkingQuoteItem[]
  warnings?: QuoteWarning[]
  promoCode?: string
}

export interface QuoteDto {
  salesChannelType: SalesChannelType
  consumerId?: string
  createdByUserId?: string
  createdByUserClass?: UserClass
  operatorId: string
}

export interface ParkingQuoteItem {
  id: string
  offer: OfferDto
  subscription: SubscriptionDto
  sortOrder: number
  stayAgreements: StayAgreementDto[]
  notifications: ParkingNotificationQuote[]
  purchaseType: PurchaseType
  allowAmend: boolean
  allowCancel: boolean
  allowTimeExtend: boolean
  maxStayOperationHours: number
  soldOut: boolean
}

export interface OfferFeature {
  featureOne: string | null
  featureTwo: string | null
  featureThree: string | null
  featureFour: string | null
}

export interface OfferDto {
  id: string
  name: string
  imageUrl?: string
  offerClass: OfferClass
  marketing: Info
  terms: Info
  showSoldOut: boolean
  termsUrl?: string
  listItemBranding?: ListItemBranding
  feature?: OfferFeature
  discountPercentage: number
}

export interface ListItemBranding {
  backgroundColour: string
  imageUrl: string
  textColour: string
  iconColour: string
}

export interface SubscriptionDto {
  id: string
  name: string
  summary: string
  imageUrl?: string
  subscriptionFeeList: SubscriptionFeeDto[]
  accountMaintenanceList: SubscriptionAccountMaintenanceDto[]
  termsHeadline?: string
  termsSummary?: string
  marketingHeadline?: string
  termsUrl?: string
}

export interface SubscriptionFeeDto {
  id: string
  name: string
  amount: number
  feeType: SubscriptionFeeType
  feeCycle: SubscriptionFeeCycle
  chargeType: SubscriptionChargeType
}

export interface SubscriptionAccountMaintenanceDto {
  id: string
  name: string
  amount: number
  maintenanceType: SubscriptionAccountMaintenanceType
}

export enum PurchaseType {
  PrePay = 'PrePay',
  PostPay = 'PostPay',
}

export enum QuoteResult {
  FreeToExit = 'FreeToExit',
  QuoteReturned = 'QuoteReturned',
}

export interface StayAgreementDto {
  charge: number
  price: number
  discountAmount: number
  preDiscountCharge: number
  termFromPrice: FromPrice
  facilityRevision: FacilityRevision
  timeZoneId: string
  recurring: AgreementRecurring
  singleStay: SingleStayDto
  dailyStay: DailyStayDto
  quantity?: number
  accessModeMap?: any
  allocationInfo?: AllocationInfo
  virtualMeterInfo?: VirtualMeterInfo
  agreementId: string
}

export interface AllocationInfo {
  allocationMethod: string
  bayAssignmentType: string
  zoneId: string
  zoneName: string
  zonePublicId: string
}

export interface FacilityRevision {
  id: string
  clientRef: string
  code: string
  name: string
  publicId: string
  publicName: string
  version: number
}

export interface StayRequest {
  stayType?: StayType | null
  accessPeriod?: AccessPeriod | null
  requestedStartDateTime: string | null
  requestedFinishDateTime: string | null | undefined
}

export interface SingleStayDto {
  startInstant: number
  startDateTime: string
  startDateTimeFormatted: string
  finishInstant: number
  finishDateTime: string
  finishDateTimeFormatted: string
  durationMinutes: number
}

export interface DailyStayDto {
  startTime: string
  finishTime: string
  startDate: string
  finishDate: string
  accessPeriod: AccessPeriod
}

export interface ParkingNotificationQuote {
  revision: Revision
  name: string
  notificationType: NotificationType
  communicationType: CommunicationType
  eventTrigger: EventTrigger
  eventType: EventType
  minutes: number
  consumerCharge?: number
  marketing: Info
  terms: Terms
  imageUrl: string
}

export interface ParkingDurationCharge {
  durationMinutes: number
  charge: number
}

export interface Info {
  headline: string
  details: string
}

export interface Terms {
  headline: string
  details: string
}

export interface AgreementRecurring {
  charge: number
  interval: number
  timeUnit: TariffTimeUnit
}

export interface FromPrice {
  label: string
  fee: number
  timeUnit: TariffTimeUnit
}

export interface Revision {
  id: string
  code: string
  clientRef: string
  version: number
}

export interface ProductBundledQuoteRequestDto {
  salesChannelType: SalesChannelType | null
  consumerId?: string
  parkingQuoteId: string | null | undefined
  parkingQuoteItemId: string | null
  retailProductCategory?: RetailProductCategory
}

export interface ProductBundledQuoteDto {
  id: string
  kind: 'ProductBundledQuote'
  selfLink: string
  quote: QuoteDto
  parkingQuoteId: string
  parkingQuoteItemId: string
  retailProductCategory: RetailProductCategory
  itemList: ProductQuoteItemDto[]
  existingList: ExistingParkingQuoteItem[]
}

export interface ProductQuoteItemDto {
  id: string
  productId: string
  name: string
  clientRef: string
  sortOrder: number
  fromPrice: number
  productPrice: ProductItemPriceDto
  variantList: ProductQuoteItemVariantDto[]
  retailProductCategory: RetailProductCategory
  imageUrl: string
  supplierId: string
  marketing: Info
  terms: Terms
  maxStockPerTransaction: number
  catalogueInfo: CatalogueInfo
}

export interface ProductItemPriceDto {
  onSale: boolean
  retailPrice: number
  priceIncludingCharges: number
}

export interface ProductQuoteItemVariantDto {
  retailProductVariantId: string
  imageUrl: string
  name: string
  sortOrder: number
  price: ProductItemPriceDto
  supplementaryInfoMap: Record<string, boolean>
  marketing: Info
  terms: Terms
  maxStockPerTransaction: number
  catalogueInfo: CatalogueInfo
}

export enum EventTrigger {
  Before = 'Before',
  On = 'On',
  After = 'After',
}

export enum EventType {
  Order = 'Order',
  Reload = 'Reload',
  ReloadFail = 'ReloadFail',
  Start = 'Start',
  End = 'End,',
  Amend = 'Amend',
  Cancel = 'Cancel',
  Extend = 'Extend,',
}

export enum NotificationType {
  Fulfilment = 'Fulfilment',
  Convenience = 'Convenience',
}

export enum CommunicationType {
  Email = 'Email',
  SMS = 'SMS',
  Push = 'Push',
}

export enum AgreementRelationType {
  Extends = 'Extends',
  Amends = 'Amends',
  NewSession = 'NewSession',
}

export enum SubscriptionFeeType {
  Application = 'Application',
  Establishment = 'Establishment',
  Maintenance = 'Maintenance',
}

export enum SubscriptionFeeCycle {
  Monthly = 'Monthly',
}

export enum SubscriptionChargeType {
  PerSubscription = 'PerSubscription',
  PerFacility = 'PerFacility',
}

export enum SubscriptionAccountMaintenanceType {
  InitialBalance = 'InitialBalance',
  MinimumBalance = 'MinimumBalance',
  MaximumBalance = 'MaximumBalance',
  MinimumTopup = 'MinimumTopup',
  MaximumTopup = 'MaximumTopup',
}

export enum OfferClass {
  Base = 'Base',
  Promo = 'Promo',
}

export enum TariffTimeUnit {
  Day = 'Day',
  Hour = 'Hour',
  Month = 'Month',
}

export enum UserClass {
  None = 'None',
  Consumer = 'Consumer',
  Admin = 'Admin',
  Core = 'Core',
}

export enum StayType {
  Restricted = 'Restricted',
  Unrestricted = 'Unrestricted',
}

export enum AccessPeriod {
  AnyDay = 'AnyDay',
  Weekdays = 'Weekdays',
  Weekends = 'Weekends',
}

export enum ProductSupplementaryInfoStatus {
  InitialState = 'InitialState',
  PendingSupplementaryInfo = 'PendingSupplementaryInfo',
  ReceivedSupplementaryInfo = 'ReceivedSupplementaryInfo',
  UpdatedSupplementaryInfo = 'UpdatedSupplementaryInfo',
}

export interface QuotePromiseResponse {
  id: string
  itemList: ParkingQuoteItem[]
  success: boolean
  warnings?: QuoteWarning[]
  isCarParkEntryClosed?: boolean
}
