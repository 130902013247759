import { CarPark } from '../park-and-pay/types'
import { CarParkActionTypes, GET_CAR_PARK_REQUEST, GET_CAR_PARK_SUCCESS, RESET_CAR_PARK } from './types'

export function getCarParkRequest(): CarParkActionTypes {
  return {
    type: GET_CAR_PARK_REQUEST,
  }
}

export function getCarParkSuccess(data: CarPark): CarParkActionTypes {
  return {
    type: GET_CAR_PARK_SUCCESS,
    payload: {
      carPark: data,
    },
  }
}

export function resetCarParkSearch(): CarParkActionTypes {
  return {
    type: RESET_CAR_PARK,
  }
}
