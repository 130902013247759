import { Branding, BrandingActionTypes, GET_BRANDING_REQUEST, GET_BRANDING_SUCCESS } from './types'

export function getBrandingRequest(): BrandingActionTypes {
  return {
    type: GET_BRANDING_REQUEST,
  }
}

export function getBrandingSuccess(branding: Branding | null): BrandingActionTypes {
  return {
    type: GET_BRANDING_SUCCESS,
    payload: {
      data: branding,
    },
  }
}
