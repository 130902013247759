import { getConsumerFromFullProfile } from '../../common/utility'
import { RootState } from '../index'
import {
  AuthActionTypes,
  AuthState,
  GET_CHARGE_ACCOUNT_DETAILS_REQUEST,
  GET_CHARGE_ACCOUNT_DETAILS_SUCCESS,
  GET_CONSUMER_CARDS_SUCCESS,
  GET_CONSUMER_FAVOURITE_CARPARKS_SUCCESS,
  GET_CONSUMER_FULL_PROFILE_REQUEST,
  GET_CONSUMER_FULL_PROFILE_SUCCESS,
  GET_CONSUMER_VEHICLE_SUCCESS,
  SAVE_CONSUMER_REQUEST,
  SAVE_CONSUMER_SUCCESS,
  SET_FIREBASE_INITIALIZED,
  SET_LOGGED_OFF,
  SET_LOGGED_ON,
  UPDATE_CONSUMER_ACCESS_CREDENTIALS,
  UPDATE_CONSUMER_NOTIFICATIONS_ENABLED,
  UPDATE_CONSUMER_SUCCESS,
  WHOAMI_REQUEST,
  WHOAMI_SUCCESS,
} from './types'

const initialState: AuthState = {
  user: null, // WhoAmI
  firebaseUser: null, // Firebase
  consumerSaveResponse: null,
  consumer: null, // Full-Profile
  settings: null,
  vehicles: null,
  paymentCards: null,
  favouritesCarParks: null,
  accessCredentials: [],
  firebaseInitialized: false,
  isWhoAmIRequestCompleted: false,
  isConsumerRequestCompleted: false,
}

export function authReducer(state: AuthState = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case SET_LOGGED_OFF:
      return {
        ...initialState,
        user: null,
        consumer: null,
        firebaseUser: null,
      }
    case SET_LOGGED_ON:
      return {
        ...state,
        firebaseUser: action.payload.data,
      }
    case WHOAMI_REQUEST:
      return {
        ...state,
        user: null,
        isWhoAmIRequestCompleted: false,
      }
    case WHOAMI_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        isWhoAmIRequestCompleted: true,
      }
    case SAVE_CONSUMER_REQUEST:
      return {
        ...state,
        consumerSaveResponse: null,
      }
    case SAVE_CONSUMER_SUCCESS:
      return {
        ...state,
        consumerSaveResponse: action.payload.data,
      }
    case GET_CONSUMER_FULL_PROFILE_REQUEST:
      return {
        ...state,
        consumer: null,
        settings: null,
        vehicles: null,
        paymentCards: null,
        accessCredentials: [],
        favouritesCarParks: null,
        isConsumerRequestCompleted: false,
      }
    case GET_CONSUMER_FULL_PROFILE_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          consumer: getConsumerFromFullProfile(action.payload.data),
          settings: action.payload.data.settings,
          favouritesCarParks: action.payload.data.favouritesCarParks,
          vehicles: action.payload.data.vehicles,
          paymentCards: action.payload.data.paymentCards,
          accessCredentials: action.payload.data.accessCredentials,
          chargeAccount: action.payload.data.chargeAccount,
          isConsumerRequestCompleted: true,
        }
      } else {
        return {
          ...state,
          consumer: null,
          settings: null,
          vehicles: null,
          paymentCards: null,
          accessCredentials: [],
          favouritesCarParks: null,
          isConsumerRequestCompleted: true,
        }
      }
    case GET_CONSUMER_CARDS_SUCCESS:
      return {
        ...state,
        paymentCards: action.payload.cards,
      }
    case GET_CONSUMER_FAVOURITE_CARPARKS_SUCCESS:
      return {
        ...state,
        favouritesCarParks: action.payload.favouriteCarParks,
      }
    case GET_CONSUMER_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: action.payload.vehicles,
      }
    case GET_CHARGE_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        chargeAccount: undefined,
      }
    case GET_CHARGE_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        chargeAccount: action.payload.data,
      }
    case UPDATE_CONSUMER_SUCCESS:
      return {
        ...state,
        consumer: action.payload.data,
      }
    case UPDATE_CONSUMER_NOTIFICATIONS_ENABLED:
      return {
        ...state,
        settings: action.payload.settings,
      }
    case UPDATE_CONSUMER_ACCESS_CREDENTIALS:
      return {
        ...state,
        accessCredentials: action.payload.data,
      }
    case SET_FIREBASE_INITIALIZED:
      return {
        ...state,
        firebaseInitialized: true,
      }
    default:
      return state
  }
}

export const currentUser = (state: RootState) => {
  return state.authReducer.user
}

export const currentConsumer = (state: RootState) => {
  return state.authReducer.consumer
}
