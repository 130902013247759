import {
  EntitlementDetailsActionTypes,
  EntitlementDetailsState,
  GET_ENTITLEMENT_DETAILS_SUCCESS,
  RESET_ENTITLEMENT_DETAILS,
} from './types'

const initialState: EntitlementDetailsState = {
  hasRequestCompleted: false,
  entitlement: null,
}

export function entitlementDetailsReducer(
  state: EntitlementDetailsState = initialState,
  action: EntitlementDetailsActionTypes,
): EntitlementDetailsState {
  switch (action.type) {
    case GET_ENTITLEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        hasRequestCompleted: true,
        entitlement: action.payload.entitlement,
      }
    case RESET_ENTITLEMENT_DETAILS:
      return initialState
    default:
      return state
  }
}
