import {
  ParkFlowActionTypes,
  ParkFlowState,
  RESET_PARK_FLOW,
  SET_SAVE_MY_DETAILS,
  SET_SELECTED_PARK_CARD,
  SET_SELECTED_PARK_GEO_FEATURE,
  SET_SELECTED_PARK_VEHICLE,
  SET_UNSAVED_CARDS,
  SET_UNSAVED_VEHICLES,
} from './types'

const initialState: ParkFlowState = {
  selectedParkVehicle: null,
  unsavedVehicles: [],
  selectedParkCard: null,
  unsavedCards: [],
  selectedGeoFeature: null,
  isSaveMyDetails: false,
}

export function parkFlowReducer(state: ParkFlowState = initialState, action: ParkFlowActionTypes): ParkFlowState {
  switch (action.type) {
    case SET_SELECTED_PARK_VEHICLE:
      return {
        ...state,
        selectedParkVehicle: action.payload.data,
      }
    case SET_UNSAVED_VEHICLES:
      return {
        ...state,
        unsavedVehicles: action.payload.data,
      }
    case SET_SELECTED_PARK_CARD:
      return {
        ...state,
        selectedParkCard: action.payload.data,
      }
    case SET_UNSAVED_CARDS:
      return {
        ...state,
        unsavedCards: action.payload.data,
      }
    case SET_SAVE_MY_DETAILS:
      return {
        ...state,
        isSaveMyDetails: action.payload.data,
      }
    case SET_SELECTED_PARK_GEO_FEATURE:
      return {
        ...state,
        selectedGeoFeature: action.payload.data,
      }
    case RESET_PARK_FLOW:
      return initialState
    default:
      return state
  }
}
