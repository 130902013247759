import { AccessCredential, EntryStatus, GeoFacilityType, UserAgentType } from '../../common/types'
import { BasePriceUnit } from '../../common/utility'
import {
  CommunicationType,
  CreditCardType,
  EventTrigger,
  EventType,
  PaymentChannel,
  RetailProductCategory,
} from '../basket/types'
import { CarParkFacility } from '../carpark-facilities/types'
import { AccessControlType, LocationClass } from '../carpark-long-info/types'
import { MapBoxFeature, MapBoxSuggestion } from '../mapbox-geo-feature/types'
import { AgreementStatus, MyActivityDto } from '../my-agreements/types'
import { AccessibleReason } from '../operating-hours/types'
import {
  AccessPeriod,
  AgreementRelationType,
  DailyStayDto,
  NotificationType,
  PurchaseType,
  Revision,
  SingleStayDto,
  StayType,
  SubscriptionDto,
  SubscriptionFeeType,
  TariffTimeUnit,
} from '../parking-quote/types'
import {
  INIT_FLOW,
  POSITION_SEARCH,
  RESET_ORDER_FORM,
  SELECT_CARPARK_FACILITY,
  SELECT_CONTACT_DETAILS,
  SELECT_DURATION,
  SELECT_LANDMARK,
  SELECT_MAP_BOX_SUGGESTION,
  SET_CONSUMER_ORDER,
  SET_CONSUMER_ORDER_TO_EXTEND,
  SET_MAP_VIEW,
  SET_SEARCHED_FACILITY_FILTERS,
} from './action'

export interface OrderRequest {
  kind: string
  salesChannelType: SalesChannelType
  basketId: string
  sendPromoAndOffers?: boolean
  userAgentType: UserAgentType
  referer: string
}

export interface SelectedDuration {
  durationMinutes: number
  entryDateTimeIso8601: string
  endDateTimeIso8601: string
  entryDate: string
  endDate: string
  entryTime: Date
  endTime: Date
  selectorIndex: number
  promoCode?: string
}

export interface SelectedContactDetails {
  firstName: string
  lastName: string
  mobileNumber: string
  email: string
}

export interface SubscriptionLineItemDto {
  id: string
  subscriptionRevision: Revision
  name: string
  summary: string
  imageUrl: string
  subscriptionFeeList: SubscriptionLineItemFeeDto[]
  accountTopUpList: SubscriptionLineItemTopUpDto[]
}

export interface SubscriptionLineItemFeeDto {
  id: string
  feeId: string
  feeName: string
  feeType: SubscriptionFeeType
  feeAmount: number
}

export interface SubscriptionLineItemTopUpDto {
  id: string
  topUpId: string
  topUpType: TopUpType
  topUpName: string
  topUpAmount: number
}

export enum TopUpType {
  Initial = 'Initial',
}

export interface OrderMaskedContactDto {
  maskedMobileNumber: string
  maskedEmail: string
  mobileNumber: string
  email: string
  firstName: string
  lastName: string
}

export interface ParkingLineItem {
  itemId: string
  totalCharge: number
  vehicleMakeCode: string
  vehiclePlate: string
  stayAgreements: ParkingLineItemStayAgreementDto[]
  stayRequests: StayRequest[]
  requestedVirtualCarParkIds: string[]
  offer: OfferItemDto
  consumerVehicleId: string
  notifications: OfferNotification[]
  subscription: SubscriptionDto[]
  purchaseType: PurchaseType
}

export interface StayRequest {
  stayType: StayType
  accessPeriod: AccessPeriod
  requestedStartDateTime: string
  requestedFinishDateTime: string
}

export interface OfferItemDto {
  id: string
  offer: ItemInfo
  name: string
  discountPercentage: number
  subscription: SubscriptionDto
}

export interface ItemInfo {
  id: string
  clientRef: string
  code: string
  name: string
  revision: number
  charge: number
}

export interface OfferNotification {
  notificationId: string
  notificationRevision: number
  notificationName: string
  notificationCode: string
  notificationClientRef: string
  charge: number
  eventTrigger: EventTrigger
  eventType: EventType
  communicationType: CommunicationType
  notificationMinutes: number
  notificationType: NotificationType
}

export interface RetailProduct {
  retailProduct: ItemInfo
  retailProductCategory: RetailProductCategory
  supplierId: string
  variants: VariantInfo[]
}

export interface VariantInfo {
  clientRef: string
  code: string
  name: string
  revision: number
  quantity: number
  unitPerSale: number
  price: number
}
export interface ParkingLineItemStayAgreementDto {
  term: Revision
  charge: number
  discountAmount: number
  preDiscountCharge: number
  quantity: number
  accessCharge: AccessChargeDto
  virtualCarParkId: string
  timeZoneId: string
  singleStay: SingleStayDto
  dailyStay: DailyStayDto
  stayType: StayType
  agreementRecurring: AgreementRecurringDto
  entryStatus: EntryStatus
  agreementStatus: AgreementStatus
  id: string
  vehiclePlate: string
  vehiclePlateIssuer: string
  facilityName: string
  facilityPublicName: string
  facilityPublicId: string
  facilityId: string
  consumerVehicleId: string
  vehicleMakeCode: string
  accessCredentials: AccessCredential[]
  bayAssignments: BayAssignments[]
  virtualMeterInfo?: VirtualMeterInfo
}

export interface BayAssignments {
  bayAssignmentType: string
  facilityZoneId?: string
  facilityZoneName?: string
  facilityZonePublicId?: string
}

export interface AccessChargeDto {
  price: number
  timeUnit: TariffTimeUnit
}

export interface AgreementRecurringDto {
  charge: number
  interval: number
  timeUnit: TariffTimeUnit
}

export interface OrderCharge {
  note: string
  id: string
  clientRef: string
  code: string
  name: string
  revision: number
  charge: number
  orderChargeType: OrderChargeType
}

export enum RevisionStatus {
  Current = 'Current',
  Amended = 'Amended',
}

export enum OrderChargeType {
  ServiceCharge = 'ServiceCharge',
  Levy = 'Levy',
  Operator = 'Operator',
  InclusiveTax = 'InclusiveTax',
  PaymentRecovery = 'PaymentRecovery',
}

export interface OrderFinancialTransaction {
  paymentChannel: PaymentChannel | null
  cardType: CreditCardType
  cardExpiryMonth: number
  cardExpiryYear: number
  cardMasked: string | null
  responseCode: string
  responseText: string
  createdInstant: number
  createdDateTime: string
  transactionStatus: TransactionStatus
  transactionType: TransactionType
  publicTransactionType: TransactionType
  tokenizedCardId: string | null
  amount: number | null
}

export enum TransactionStatus {
  Pending = 'Pending',
  Success = 'Success',
  Rejected = 'Rejected', // Bank error
  Failed = 'Failed', // System Error
}

export enum TransactionType {
  Refund = 'Refund',
  Payment = 'Payment',
  PreAuth = 'PreAuth',
  Reversal = 'Reversal',
  Completion = 'Completion',
}

export interface TicketVehicle {
  makeId: string
  plate: string
}

export interface TokenizePaymentCard {
  id: string | null
  tokenizedCardId: string | null
  consumerId?: string
  defaultSelected: boolean | null
}

export enum FlowType {
  Park = 'Park',
  Park_Map = 'Park_Map',
  Res = 'Res',
  Res_Map = 'Res_Map',
  Charge = 'Charge',
  Charge_Map = 'Charge_Map',
  Lease = 'Lease',
  Pass = 'Pass',
  Promos = 'Promos',
  Pass_Map = 'Pass_Map',
  Valido = 'Valido',
}

export enum PaymentCardUse {
  Personal,
  Business,
}

export interface NewParkUser {
  firstName: string
  lastName: string
  mobile: Mobile
  email: string | null
}

export interface Mobile {
  number: string
  country: string
}

export enum LocationType {
  LineString = 'LineString',
  Point = 'Point',
}

interface LocationLineString {
  points: Point[]
}

export interface GeoFeature {
  accessControlType: AccessControlType
  accessible: boolean | null
  accessibleReason: AccessibleReason | null
  basePriceFee: number
  basePriceLabel: string
  basePriceUnit: BasePriceUnit
  distanceFromPointMetres: number
  endTimeMinutes: number
  facilityId: string
  facilityZoneId: string
  feature: Feature
  geoFacilityType: GeoFacilityType
  location: Point
  locationClass: LocationClass
  locationLineString: LocationLineString | null
  locationPoint: Point | null
  locationType: LocationType
  maxStayInMinutes: number | null
  name: string
  publicId: string
  address: Address
  mainImageUrl: string
}

export interface Point {
  latitude: number
  longitude: number
}

export interface Feature {
  kind: 'CarPark' | 'Address' | 'Landmark' | 'City'
  id: string
}

export interface GeoMapLineString {
  points: Point[]
  heading: string
}

export interface ZoneShortInfo {
  id: string
  code: string
  clientRef: string
  publicId: string
  name: string
  operatorId: string
  version: number
  location: Point | null
  geoMapType: LocationType
  geoMapPoint: Point | null
  geoMapLineString: GeoMapLineString
}

export interface VirtualMeterInfo {
  id: string
  name: string
  mapPoint: Point
  publicId: string
}

export interface CarPark {
  id: string
  publicName: string
  publicId: string
  clearance: number
  location: Point
  address: Address
  operatorId: string
  amenities?: string[]
  marketing?: Marketing | null
  code: string
  clientRef: string
  name: string
  basePriceLabel: string
  basePriceFee: number
  basePriceUnit: BasePriceUnit | null
  version: number
  mainImageUrl: string | null
  timeZoneId: string
  accessible?: boolean
  accessibleReason?: AccessibleReason
  maxStayInMinutes?: number
  endTimeMinutes: number
  zoneShortInfo: ZoneShortInfo | null
  accessControlType: AccessControlType
  locationClass: LocationClass
  geoMapType?: LocationType
  geoMapPoint?: Point
  geoMapLineString?: GeoMapLineString
  distanceFromPointMetres: number | null
  virtualMeterInfo?: VirtualMeterInfo
}

export enum FacilityType {
  PrimaryCarPark = 'PrimaryCarPark',
  NestedCarPark = 'NestedCarPark',
  VirtualCarPark = 'VirtualCarPark',
}

export interface Marketing {
  id: string
  name: string
  marketingType: MarketingType
  operatorId: string
  mainImageId: string
  mainImageUrl: string
  termsPdfId: string
  termsPdfUrl: string
  marketing: Info
  terms: Info
  basePrice: BasePrice
}

export enum MarketingType {
  Facility = 'Facility',
  Offer = 'Offer',
  Product = 'Product',
  ProductVariant = 'ProductVariant',
  Notification = 'Notification',
  CarParkTenancy = 'CarParkTenancy',
  Subscription = 'Subscription',
}

export interface Info {
  headline: string
  details: string
}

export interface BasePrice {
  label: string
  fee: number
  unit: string
}

export interface Address {
  line1: string
  line2: string
  suburb: string
  postcode: string
  countryIso: string
  state: string
}

export interface SmsReminderNotification {
  charge: number
  timeMinutes: number
  text: string
}

export interface ExitTime {
  exitDateTimeIso8601: string | null
}

export interface ParkDuration {
  minutes: number
  display: string
}

export enum ExitTimeDisplayType {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
}

export enum SalesChannelType {
  Pay = 'Pay',
  Res = 'Res',
  Cpms = 'Cpms',
  Park = 'Park',
  Lease = 'Lease',
  Access = 'Access',
  Charge = 'Charge',
}

export enum CarparkOptions {
  FindCarpark = 'FindCarpark',
  RepeatPark = 'RepeatPark',
  ManagePark = 'ManagePark',
}

export enum ConsumerType {
  Guest = 'Guest',
  SignedUp = 'SignedUp',
  CarParkTenantMember = 'CarParkTenantMember',
  DistributionChannelMember = 'DistributionChannelMember',
  None = 'None',
}

export enum Lifecycle {
  Draft = 'Draft',
  Active = 'Active',
  Suspended = 'Suspended',
  Deleted = 'Deleted',
}

export interface RelatedAgreementInFlow {
  relationType: AgreementRelationType
  relatedAgreementId: string
}

export enum DisplayType {
  AMENITY = 'AMENITY',
  HOURS = 'HOURS',
  RATE = 'RATE',
}

export interface SearchFacilityFilters {
  distance: number
  clearance: number
  selectedAmenities: string[]
}

export interface initFlow {
  type: typeof INIT_FLOW
}

export interface selectCarParkFacility {
  type: typeof SELECT_CARPARK_FACILITY
  payload: {
    data: CarParkFacility
  }
}

export interface selectLandmark {
  type: typeof SELECT_LANDMARK
  payload: {
    data: MapBoxFeature | null
  }
}

export interface positionSearch {
  type: typeof POSITION_SEARCH
  payload: {
    data: Point
  }
}

export interface selectMapBoxSuggestion {
  type: typeof SELECT_MAP_BOX_SUGGESTION
  payload: {
    data: MapBoxSuggestion | null
  }
}

export interface selectDuration {
  type: typeof SELECT_DURATION
  payload: {
    data: SelectedDuration
  }
}

export interface selectContactDetails {
  type: typeof SELECT_CONTACT_DETAILS
  payload: {
    data: SelectedContactDetails
  }
}

export interface setConsumerOrderToAmend {
  type: typeof SET_CONSUMER_ORDER
  payload: {
    data: MyActivityDto
  }
}

export interface setConsumerOrderToExtend {
  type: typeof SET_CONSUMER_ORDER_TO_EXTEND
  payload: {
    data: MyActivityDto
  }
}

export interface setSearchedFacilityFilters {
  type: typeof SET_SEARCHED_FACILITY_FILTERS
  payload: {
    data: SearchFacilityFilters
  }
}

export interface setIsMapView {
  type: typeof SET_MAP_VIEW
  payload: {
    data: boolean
  }
}

export interface resetOrderForm {
  type: typeof RESET_ORDER_FORM
}

export type OrderFormActionTypes =
  | initFlow
  | selectDuration
  | selectContactDetails
  | setConsumerOrderToAmend
  | setConsumerOrderToExtend
  | selectCarParkFacility
  | selectLandmark
  | positionSearch
  | selectMapBoxSuggestion
  | setSearchedFacilityFilters
  | setIsMapView
  | resetOrderForm

export const ROUTE_PATH_CARPARK_DETAILS = '/carpark-details'
export const ROUTE_PATH_MAP_VIEW = '/parking-map'
export const ROUTE_PATH_MANAGE_PARK = '/manage-park'
export const ROUTE_PATH_VIEW_INVOICES = '/view-invoices'
export const ROUTE_PATH_AMEND_DETAILS = '/amend-details'
export const ROUTE_PATH_CANCEL_BOOKING = '/cancel-booking'
export const ROUTE_PATH_BOOKING_CONFIRMATION = '/booking-confirmation'
export const ROUTE_PATH_TRANSACTION_DECLINED = '/transaction-declined'
export const ROUTE_PATH_AMEND_VEHICLE_DETAILS = '/amend-vehicle-details'
export const ROUTE_PATH_AMEND_GUEST_VEHICLE_DETAILS = '/amend-guest-vehicle-details'

// agreement-access
export const ROUTE_PATH_AGREEMENT_ACCESS = '/agreement-access'
export const ROUTE_PATH_ACCESS_FAILURE = '/access-failure'
export const ROUTE_PATH_CELLULAR_ACCESS = '/cellular-access'
export const ROUTE_PATH_QRCODE_ACCESS = '/qrcode-access'
export const ROUTE_PATH_REVERSE_QRCODE_ACCESS = '/reverse-qrcode-access'
export const ROUTE_PATH_ACCESS_GATE_CONNECTING = '/access-gate-connecting'
export const ROUTE_PATH_ACCESS_GATE_ERROR = '/access-gate-error'
export const ROUTE_PATH_ACCESS_GATE_FAILURE = '/access-gate-failure'
export const ROUTE_PATH_ACCESS_GATE_SUCCESS = '/access-gate-success'
export const ROUTE_PATH_ACCESS_INVALID_QR = '/access-invalid-qr'
export const ROUTE_PATH_ACCESS_INVALID_ORDER = '/access-invalid-order'

// casual-access
export const ROUTE_PATH_CASUAL_ACCESS_ENTRY = '/rqr-e'
export const ROUTE_PATH_CASUAL_ACCESS_EXIT = '/rqr-x'
export const ROUTE_PATH_CASUAL_ACCESS_PAY = '/rqr-p'
export const ROUTE_PATH_CASUAL_ACCESS_PARKING_SUMMARY = '/casual-parking-summary'
export const ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DETAILS = '/casual-payment-details'
export const ROUTE_PATH_CASUAL_ACCESS_GUEST_PAYMENT_DETAILS = '/casual-guest-payment-details'
export const ROUTE_PATH_CASUAL_ACCESS_CONFIRM_ORDER = '/casual-confirm-order'
export const ROUTE_PATH_CASUAL_ACCESS_PAYMENT_PROCESSING = '/casual-payment-processing'
export const ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DECLINED = '/casual-payment-declined'
export const ROUTE_PATH_CASUAL_ACCESS_PAYMENT_APPROVED = '/casual-payment-approved'
export const ROUTE_PATH_CASUAL_ACCESS_CREATE_ACCOUNT = '/casual-create-account'
export const ROUTE_PATH_CASUAL_ACCESS_ORDER_REQUIRED = '/casual-access-order-req'
