import {
  CarParkFacilitiesActionTypes,
  CarParkFacilitiesState,
  GET_CARPARK_FACILITIES_REQUEST,
  GET_CARPARK_FACILITIES_SUCCESS,
} from './types'

const initialState: CarParkFacilitiesState = {
  carParkFacilities: null,
}

export function carParkFacilitiesReducer(
  state: CarParkFacilitiesState = initialState,
  action: CarParkFacilitiesActionTypes,
): CarParkFacilitiesState {
  switch (action.type) {
    case GET_CARPARK_FACILITIES_REQUEST:
      return {
        ...state,
        carParkFacilities: [],
      }
    case GET_CARPARK_FACILITIES_SUCCESS:
      return {
        ...state,
        carParkFacilities: action.payload.data,
      }
    default:
      return state
  }
}
