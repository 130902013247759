import { PwaInstallActionTypes, SET_DEFERRED_PROMPT } from './types'

export function setDeferredPrompt(deferredPrompt: any): PwaInstallActionTypes {
  return {
    type: SET_DEFERRED_PROMPT,
    payload: {
      data: deferredPrompt,
    },
  }
}
