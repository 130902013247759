export const GET_AMENITY_REQUEST = 'GET_AMENITY_REQUEST'
export const GET_AMENITY_SUCCESS = 'GET_AMENITY_SUCCESS'

export interface Amenity {
  id: string
  kind: string
  name: string
  iconPath: string
}

export interface AmenityState {
  success: boolean
  amenities: Amenity[]
}

interface getAmenityRequestAction {
  type: typeof GET_AMENITY_REQUEST
}

interface getAmenitySuccessAction {
  type: typeof GET_AMENITY_SUCCESS
  payload: {
    amenities: Amenity[]
  }
}

export type AmenityActionTypes = getAmenityRequestAction | getAmenitySuccessAction
