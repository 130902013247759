import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { currentConsumer } from '../../store/auth/reducers'
import { getGuestAgreementsListSuccess } from '../../store/guest-agreements/actions'
import { getGuestAgreementsList } from '../../store/guest-agreements/thunks'

const useGuestAgreements = () => {
  const dispatch = useDispatch()

  const { firebaseInitialized, guestAgreementsList, guestAgreementsRequestCompleted } = useSelector(
    (state: RootState) => ({
      firebaseInitialized: state.authReducer.firebaseInitialized,
      guestAgreementsList: state.guestAgreementsListReducer.guestAgreementsList,
      guestAgreementsRequestCompleted: state.guestAgreementsListReducer.isRequestedCompleted,
    }),
    shallowEqual,
  )
  const consumer = useSelector(currentConsumer)

  useEffect(() => {
    if (firebaseInitialized && !consumer) {
      if (guestAgreementsList.length === 0 && !guestAgreementsRequestCompleted) {
        const guestAgreementIds: string | null = localStorage.getItem('guestAgreementIds')
        if (guestAgreementIds) {
          const guestAgreementIdsList = JSON.parse(guestAgreementIds)
          const agreementIds: string[] = guestAgreementIdsList.map(
            (guestAgreementId: any) => guestAgreementId.agreementId,
          )
          dispatch(getGuestAgreementsList(agreementIds))
        } else {
          dispatch(getGuestAgreementsListSuccess([]))
        }
      }
    }
    // eslint-disable-next-line
  }, [firebaseInitialized, consumer, guestAgreementsList, guestAgreementsRequestCompleted])

  useEffect(() => {
    if (firebaseInitialized && !consumer) {
      if (guestAgreementsRequestCompleted) {
        localStorage.removeItem('guestAgreementIds')
        if (guestAgreementsList.length > 0) {
          const guestAgreementIdsList = guestAgreementsList.map((guestAgreement) => ({
            agreementId: guestAgreement.id,
            salesChannelType: guestAgreement.salesChannelType,
          }))
          localStorage.setItem('guestAgreementIds', JSON.stringify(guestAgreementIdsList))
        }
      }
    }
    // eslint-disable-next-line
  }, [firebaseInitialized, consumer, guestAgreementsList, guestAgreementsRequestCompleted])
}

export default useGuestAgreements
