import { Backdrop, CircularProgress } from '@mui/material'
import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  backdrop: {
    top: 90,
    height: 150,
    zIndex: 9999,
    alignItems: 'flex-end',
    background: 'transparent',
  },
  progressSpinner: {
    width: '50px !important',
    height: '50px !important',
    color: 'var(--C_128DC3)',
  },
}))

const Loading: React.FC = () => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress className={classes.progressSpinner} />
    </Backdrop>
  )
}

export default Loading
