import axios from 'axios'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { getConsumerVehicleSuccess } from '../auth/actions'
import { AppThunk } from '../index'
import { setLoading } from '../loading/reducers'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import {
  checkConsumerVehicleExistsRequest,
  checkConsumerVehicleExistsSuccess,
  saveConsumerVehicleRequest,
  saveConsumerVehicleSuccess,
} from './actions'
import { CheckConsumerVehicleExists, ConsumerVehicle } from './types'

const GET_CONSUMER_VEHICLE_VEHICLE_API_RESOURCE_PATH = '/operators/:operatorId/consumers/:consumerId/vehicles'
const CHECK_CONSUMER_VEHICLE_EXISTS_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/vehicles:checkVehicleExists'
const UPDATE_DEFAULT_CONSUMER_VEHICLE_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/defaultVehicleId'

export const getConsumerVehicleDetails =
  (consumerId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    await axios
      .get(
        GET_CONSUMER_VEHICLE_VEHICLE_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ),
        config,
      )
      .then((response) => {
        dispatch(setLoading(false))
        dispatch(getConsumerVehicleSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }

export const checkAndSaveConsumerVehicle =
  (consumerId: string, consumerVehicle: ConsumerVehicle): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    let vehicleAlreadyExists: boolean = false
    dispatch(checkConsumerVehicleExistsRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    const vehiclePlateAndIssuer = {
      plate: consumerVehicle.plate,
      plateIssuer: consumerVehicle.plateIssuer,
    }
    await axios
      .post(
        CHECK_CONSUMER_VEHICLE_EXISTS_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ),
        vehiclePlateAndIssuer,
        config,
      )
      .then((response) => {
        if (response.data.existsOnConsumer) {
          dispatch(setLoading(false))
          vehicleAlreadyExists = true
          const checkConsumerVehicleExistsResponse: CheckConsumerVehicleExists = {
            consumerVehicle: consumerVehicle,
            openVehiclePlateDialog: true,
            vehicleExistsOnConsumer: true,
            vehicleExistsOnEntitlement: false,
            vehicleExistsOnDifferentConsumer: false,
          }
          dispatch(checkConsumerVehicleExistsSuccess(checkConsumerVehicleExistsResponse))
        } else if (response.data.existsOnDifferentConsumer) {
          dispatch(setLoading(false))
          vehicleAlreadyExists = true
          const checkConsumerVehicleExistsResponse: CheckConsumerVehicleExists = {
            consumerVehicle: consumerVehicle,
            openVehiclePlateDialog: true,
            vehicleExistsOnConsumer: false,
            vehicleExistsOnEntitlement: false,
            vehicleExistsOnDifferentConsumer: true,
          }
          dispatch(checkConsumerVehicleExistsSuccess(checkConsumerVehicleExistsResponse))
        } else {
          dispatch(saveConsumerVehicle(consumerId, consumerVehicle))
        }
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return vehicleAlreadyExists
  }

export const saveConsumerVehicle =
  (consumerId: string, consumerVehicle: ConsumerVehicle): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    dispatch(saveConsumerVehicleRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    await axios
      .post(
        GET_CONSUMER_VEHICLE_VEHICLE_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ),
        consumerVehicle,
        config,
      )
      .then((response) => {
        dispatch(getConsumerVehicleDetails(consumerId))
        dispatch(saveConsumerVehicleSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }

export const deleteConsumerVehicle =
  (consumerId: string, consumerVehicle: ConsumerVehicle): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    await axios
      .delete(
        GET_CONSUMER_VEHICLE_VEHICLE_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ) +
          '/' +
          consumerVehicle.id,
        config,
      )
      .then(() => {
        dispatch(getConsumerVehicleDetails(consumerId))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }

export const checkAndUpdateConsumerVehicle =
  (consumerId: string, consumerVehicle: ConsumerVehicle): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    let updateConsumerVehicleSuccess: boolean = false
    dispatch(checkConsumerVehicleExistsRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    const vehiclePlateAndIssuer = {
      plate: consumerVehicle.plate,
      plateIssuer: consumerVehicle.plateIssuer,
    }
    await axios
      .post(
        CHECK_CONSUMER_VEHICLE_EXISTS_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ),
        vehiclePlateAndIssuer,
        config,
      )
      .then(async (response) => {
        if (response.data.existsOnConsumer) {
          dispatch(setLoading(false))
          const checkConsumerVehicleExistsResponse: CheckConsumerVehicleExists = {
            consumerVehicle: consumerVehicle,
            openVehiclePlateDialog: true,
            vehicleExistsOnConsumer: true,
            vehicleExistsOnEntitlement: false,
            vehicleExistsOnDifferentConsumer: false,
          }
          dispatch(checkConsumerVehicleExistsSuccess(checkConsumerVehicleExistsResponse))
        } else if (response.data.existsOnDifferentConsumer) {
          dispatch(setLoading(false))
          const checkConsumerVehicleExistsResponse: CheckConsumerVehicleExists = {
            consumerVehicle: consumerVehicle,
            openVehiclePlateDialog: true,
            vehicleExistsOnConsumer: false,
            vehicleExistsOnEntitlement: false,
            vehicleExistsOnDifferentConsumer: true,
          }
          dispatch(checkConsumerVehicleExistsSuccess(checkConsumerVehicleExistsResponse))
        } else {
          await axios
            .put(
              GET_CONSUMER_VEHICLE_VEHICLE_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
                ':consumerId',
                consumerId,
              ) +
                '/' +
                consumerVehicle.id,
              consumerVehicle,
              config,
            )
            .then(() => {
              updateConsumerVehicleSuccess = true
              dispatch(getConsumerVehicleDetails(consumerId))
            })
            .catch((error: ErrorResponse | string) => {
              dispatch(setLoading(false))
              if (typeof error === 'object') {
                dispatch(setNotification(NotificationType.ERROR, error.message))
              }
            })
        }
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return updateConsumerVehicleSuccess
  }

export const updateConsumerVehicle =
  (consumerId: string, consumerVehicle: ConsumerVehicle): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    let updateConsumerVehicleSuccess: boolean = false
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    await axios
      .put(
        GET_CONSUMER_VEHICLE_VEHICLE_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ) +
          '/' +
          consumerVehicle.id,
        consumerVehicle,
        config,
      )
      .then(() => {
        updateConsumerVehicleSuccess = true
        dispatch(getConsumerVehicleDetails(consumerId))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return updateConsumerVehicleSuccess
  }

export const updateDefaultConsumerVehicle =
  (consumerId: string, consumerVehicleId: string): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let updateDefaultConsumerVehicleSuccess: boolean = false
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    await axios
      .patch(
        UPDATE_DEFAULT_CONSUMER_VEHICLE_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':consumerId',
          consumerId,
        ),
        { id: consumerVehicleId },
        config,
      )
      .then(() => {
        updateDefaultConsumerVehicleSuccess = true
        dispatch(getConsumerVehicleDetails(consumerId))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return updateDefaultConsumerVehicleSuccess
  }
