import {
  FacilitiesByOfferActionTypes,
  GET_FACILITIES_BY_PUBLIC_OFFER_SUCCESS,
  PublicOfferFacilitiesState,
} from './types'

const initialState: PublicOfferFacilitiesState = {
  publicOfferFacilities: null,
}

export function facilitiesByOfferReducer(
  state: PublicOfferFacilitiesState = initialState,
  action: FacilitiesByOfferActionTypes,
): PublicOfferFacilitiesState {
  switch (action.type) {
    case GET_FACILITIES_BY_PUBLIC_OFFER_SUCCESS:
      return {
        ...state,
        publicOfferFacilities: action.payload.data,
      }
    default:
      return state
  }
}
