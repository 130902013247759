import {
  Basket,
  BasketActionTypes,
  CostRecoveryCharge,
  GET_CONFIGURED_CHARGES_REQUEST,
  GET_CONFIGURED_CHARGES_SUCCESS,
  RESET_BASKET,
  RESET_BASKET_CONTACT,
  SET_PARKING_BASKET_EXPIRED,
  SET_PARKING_BASKET_REQUEST,
  SET_PARKING_BASKET_SUCCESS,
} from './types'

export function setParkingBasketRequest(): BasketActionTypes {
  return {
    type: SET_PARKING_BASKET_REQUEST,
  }
}

export function setParkingBasketSuccess(data: Basket): BasketActionTypes {
  return {
    type: SET_PARKING_BASKET_SUCCESS,
    payload: {
      basket: data,
    },
  }
}

export function getConfiguredChargesRequest(): BasketActionTypes {
  return {
    type: GET_CONFIGURED_CHARGES_REQUEST,
  }
}

export function getConfiguredChargesSuccess(data: CostRecoveryCharge[]): BasketActionTypes {
  return {
    type: GET_CONFIGURED_CHARGES_SUCCESS,
    payload: {
      charges: data,
    },
  }
}

export function setParkingBasketExpired(): BasketActionTypes {
  return {
    type: SET_PARKING_BASKET_EXPIRED,
  }
}

export function resetParkingBasketContact(): BasketActionTypes {
  return {
    type: RESET_BASKET_CONTACT,
  }
}

export function resetParkingBasket(): BasketActionTypes {
  return {
    type: RESET_BASKET,
  }
}
