import { TenantConfig } from '../../common/types'
import { AppConfigActionTypes, TENANT_CONFIG_REQUEST, TENANT_CONFIG_SUCCESS } from './types'

export function tenantConfigRequest(): AppConfigActionTypes {
  return {
    type: TENANT_CONFIG_REQUEST,
  }
}

export function tenantConfigSuccess(data: TenantConfig): AppConfigActionTypes {
  return {
    type: TENANT_CONFIG_SUCCESS,
    payload: {
      data: data,
    },
  }
}
