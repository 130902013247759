const palette = {
  primary: {
    main: '#195591',
  },
  secondary: {
    main: '#FD5A5F',
  },
  background: {
    default: '#FFFFFF',
    paper: 'white',
  },
  action: {
    disabledBackground: '#d3dce4',
    disabled: '#FFFFFF',
  },
  text: {
    primary: '#383838',
    secondary: '#607C96',
    disabled: '#383838',
    formLabel: '#383838',
    formError: '#e53935',
    formDefault: '#E2E2E2',
    border: '#dcd9d9',
    sidebarText: '#EFF2F4',
    link: '#5CADFF',
  },
}

export default palette
