import { IdOnly } from '../basket/types'
import {
  CHECK_CONSUMER_VEHICLE_EXISTS_REQUEST,
  CHECK_CONSUMER_VEHICLE_EXISTS_SUCCESS,
  CheckConsumerVehicleExists,
  ConsumerVehicleActionTypes,
  RESET_CHECK_CONSUMER_VEHICLE_EXISTS,
  RESET_SAVE_CONSUMER_VEHICLE,
  SAVE_CONSUMER_VEHICLE_REQUEST,
  SAVE_CONSUMER_VEHICLE_SUCCESS,
} from './types'

export function saveConsumerVehicleRequest(): ConsumerVehicleActionTypes {
  return {
    type: SAVE_CONSUMER_VEHICLE_REQUEST,
  }
}

export function saveConsumerVehicleSuccess(savedVehicleId: IdOnly): ConsumerVehicleActionTypes {
  return {
    type: SAVE_CONSUMER_VEHICLE_SUCCESS,
    payload: {
      data: savedVehicleId,
    },
  }
}

export function checkConsumerVehicleExistsRequest(): ConsumerVehicleActionTypes {
  return {
    type: CHECK_CONSUMER_VEHICLE_EXISTS_REQUEST,
  }
}

export function checkConsumerVehicleExistsSuccess(
  checkConsumerVehicleExists: CheckConsumerVehicleExists,
): ConsumerVehicleActionTypes {
  return {
    type: CHECK_CONSUMER_VEHICLE_EXISTS_SUCCESS,
    payload: {
      data: checkConsumerVehicleExists,
    },
  }
}

export function resetSaveConsumerVehicle(): ConsumerVehicleActionTypes {
  return {
    type: RESET_SAVE_CONSUMER_VEHICLE,
  }
}

export function resetCheckConsumerVehicleExists(): ConsumerVehicleActionTypes {
  return {
    type: RESET_CHECK_CONSUMER_VEHICLE_EXISTS,
  }
}
