import { Address, FacilityType, Point } from '../park-and-pay/types'

export interface CarParkFacility {
  id: string
  publicName: string
  publicId: string
  locationClass: string
  clearance: number
  facilityType: FacilityType
  location: Point
  address: Address
  operatorId: string
  clientRef: string
}

export interface CarParkFacilitiesState {
  carParkFacilities: CarParkFacility[] | null
}

export const GET_CARPARK_FACILITIES_API_RESOURCE_PATH = 'public/operators/:operatorId/facilities/list'

export const GET_CARPARK_FACILITIES_REQUEST = 'GET_CARPARK_FACILITIES_REQUEST'
export const GET_CARPARK_FACILITIES_SUCCESS = 'GET_CARPARK_FACILITIES_SUCCESS'

export interface getCarParkFacilitiesRequest {
  type: typeof GET_CARPARK_FACILITIES_REQUEST
}

export interface getCarParkFacilitiesSuccess {
  type: typeof GET_CARPARK_FACILITIES_SUCCESS
  payload: {
    data: CarParkFacility[]
  }
}

export type CarParkFacilitiesActionTypes = getCarParkFacilitiesRequest | getCarParkFacilitiesSuccess
