export interface UrlConfig {
  id: string
  homeUrl: string | null
  privacyUrl: string | null
  disclaimerUrl: string | null
  termsUrl: string | null
  faqUrl: string | null
  supportEmail: string | null
  supportPhone: string | null
  supportUrl: string | null
  supportedSalesChannels: string[]
}

export interface UrlConfigState {
  urlConfig: UrlConfig | null
}

export const GET_URL_CONFIG_API_RESOURCE_PATH = '/public/operators/:operatorId/url-config'

export const GET_URL_CONFIG_REQUEST = 'GET_URL_CONFIG_REQUEST'
export const GET_URL_CONFIG_SUCCESS = 'GET_URL_CONFIG_SUCCESS'

export interface getUrlConfigRequest {
  type: typeof GET_URL_CONFIG_REQUEST
}

export interface getUrlConfigSuccess {
  type: typeof GET_URL_CONFIG_SUCCESS
  payload: {
    data: UrlConfig
  }
}

export type UrlConfigActionTypes = getUrlConfigRequest | getUrlConfigSuccess
