import { FeedbackActionTypes, FeedbackState, RESET_FEEDBACK_DATA, SET_FEEDBACK_DATA } from './types'

const initialState: FeedbackState = {
  storedFeedbackData: null,
}

export function feedbackReducer(state: FeedbackState = initialState, action: FeedbackActionTypes): FeedbackState {
  switch (action.type) {
    case SET_FEEDBACK_DATA:
      return {
        ...state,
        storedFeedbackData: action.payload.data,
      }
    case RESET_FEEDBACK_DATA:
      return initialState
    default:
      return state
  }
}
