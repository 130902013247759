import {
  GET_GUEST_AGREEMENTS_LIST_REQUEST,
  GET_GUEST_AGREEMENTS_LIST_SUCCESS,
  GuestAgreementsListActionTypes,
  GuestAgreementsListState,
  RESET_GUEST_AGREEMENTS_LIST,
} from './types'

const initialState: GuestAgreementsListState = {
  guestAgreementsList: [],
  isRequestedCompleted: false,
}

export function guestAgreementsListReducer(
  state: GuestAgreementsListState = initialState,
  action: GuestAgreementsListActionTypes,
): GuestAgreementsListState {
  switch (action.type) {
    case GET_GUEST_AGREEMENTS_LIST_REQUEST:
      return {
        ...state,
        isRequestedCompleted: false,
      }
    case GET_GUEST_AGREEMENTS_LIST_SUCCESS:
      return {
        ...state,
        guestAgreementsList: action.payload.data,
        isRequestedCompleted: true,
      }
    case RESET_GUEST_AGREEMENTS_LIST:
      return initialState
    default:
      return state
  }
}
