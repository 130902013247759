import { AccessControlType, LocationClass } from '../carpark-long-info/types'
import { CarPark } from '../park-and-pay/types'

export const GET_CAR_PARK_REQUEST = 'GET_CAR_PARK_REQUEST'
export const GET_CAR_PARK_SUCCESS = 'GET_CAR_PARK_SUCCESS'
export const RESET_CAR_PARK = 'RESET_CAR_PARK'

export interface CarParkSearchState {
  carPark: CarPark | null
}

export interface ShortInfoPromiseResponse {
  success: boolean
  publicId: string
  facilityId: string
  accessControlType: AccessControlType
  locationClass: LocationClass
  timeZoneId: string
  errorNotFound?: boolean
}

interface getCarParkRequestAction {
  type: typeof GET_CAR_PARK_REQUEST
}

interface getCarParkSuccessAction {
  type: typeof GET_CAR_PARK_SUCCESS
  payload: {
    carPark: CarPark
  }
}

interface resetCarParkSearchAction {
  type: typeof RESET_CAR_PARK
}

export type CarParkActionTypes = getCarParkRequestAction | getCarParkSuccessAction | resetCarParkSearchAction
