import { AccessMode, BayReservedType, EntryStatus } from '../../common/types'
import { EntitlementVehicle } from '../entitlement-vehicle/types'
import { Slot } from '../entitlements/types'
import { AgreementStatus } from '../my-agreements/types'

export const GET_ENTITLEMENT_DETAILS_SUCCESS = 'GET_ENTITLEMENT_DETAILS_SUCCESS'
export const RESET_ENTITLEMENT_DETAILS = 'RESET_ENTITLEMENT_DETAILS'

export interface Stay {
  startDate: string
  finishDate: string | null
  slots: Slot[]
}

export interface EntitlementDetails {
  id: string
  reference: string
  specifyTime: boolean
  exclusive: boolean
  bayAssignment: {
    entitlementBayId: string
    bayReservedType: BayReservedType
    facilityId: string
    facilityAllocationId: string
    facilityZoneId: string
    fullBayNo: string
    bayId: string
    entitlementBayVersion: number
    facilityName: string
    facilityAllocationName: string
    facilityZoneName: string
    facilityPublicId: string
    facilityPublicName: string
  }
  consumerId: string
  timeZoneId: string
  stay: Stay
  version: number
  allocationId: string
  entryStatus: EntryStatus
  agreementStatus: AgreementStatus
  consumer: {
    firstName: string
    lastName: string
    email: string
    name: string
  }
  accessModeMap: {
    BLE: string
  }
  userGeneratedAccessCredentials: []
  systemGeneratedAccessCredentials: [
    {
      id: string
      accessModeType: AccessMode
      credentialValue: string
    },
  ]
  vehicles: EntitlementVehicle[]
  createdInstant: number
  createdDateTime: string
}

interface getEntitlementDetailsSuccessAction {
  type: typeof GET_ENTITLEMENT_DETAILS_SUCCESS
  payload: {
    entitlement: EntitlementDetails | null
  }
}

interface resetEntitlementDetailsAction {
  type: typeof RESET_ENTITLEMENT_DETAILS
}

export interface EntitlementDetailsState {
  hasRequestCompleted: boolean
  entitlement: EntitlementDetails | null
}

export type EntitlementDetailsActionTypes = getEntitlementDetailsSuccessAction | resetEntitlementDetailsAction
