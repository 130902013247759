import {
  ForgotPasswordActionTypes,
  ForgotPasswordState,
  RESET_FORGOT_PASSWORD,
  UPDATE_PASSWORD_RESET_SUCCESS,
} from './types'

const initialState: ForgotPasswordState = {
  updatePasswordResetResponse: null,
}

export function forgotPasswordReducer(
  state: ForgotPasswordState = initialState,
  action: ForgotPasswordActionTypes,
): ForgotPasswordState {
  switch (action.type) {
    case UPDATE_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        updatePasswordResetResponse: action.payload.data,
      }
    case RESET_FORGOT_PASSWORD:
      return initialState
    default:
      return state
  }
}
