import { datadogRum } from '@datadog/browser-rum'
import { DatadogConfig } from './types'

export interface DatadogSetup {
  tenancyCode: string
  tenantCode: string
  environment: string
  datadogConfig: DatadogConfig
  gitSha?: string
}

export function setupDatadog(datadogSetup: DatadogSetup) {
  const datadogSite = 'datadoghq.com'
  const service = 'nuevopark'
  const fullEnvironmentCode = datadogSetup.environment + '.' + datadogSetup.tenancyCode + '.' + datadogSetup.tenantCode

  datadogRum.init({
    applicationId: datadogSetup.datadogConfig.applicationId,
    clientToken: datadogSetup.datadogConfig.clientToken,
    site: datadogSite,
    service: service,
    env: fullEnvironmentCode,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: datadogSetup.gitSha ? datadogSetup.gitSha : '-',
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
  })
}
