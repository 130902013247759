import axios from 'axios'
import { AppThunk } from '..'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { getGuestAgreementsListRequest, getGuestAgreementsListSuccess } from './actions'
import { GET_GUEST_AGREEMENTS_LIST_API_RESOURCE_PATH } from './types'

export const getGuestAgreementsList =
  (guestAgreementIds: string[]): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(getGuestAgreementsListRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    config.params = {
      ids: guestAgreementIds.toString(),
      sortBy: 'StartDate',
      sortDirection: 'asc',
    }
    await axios
      .get(GET_GUEST_AGREEMENTS_LIST_API_RESOURCE_PATH.replace(':operatorId', operatorId), config)
      .then((response) => {
        dispatch(getGuestAgreementsListSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getGuestAgreementsListSuccess([]))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }
