import { CLEAR_NOTIFICATION, NotificationActionTypes, NotificationState, SET_NOTIFICATION } from './types'

const initialState: NotificationState = {
  type: null,
  message: '',
}

export function notificationsReducer(state = initialState, action: NotificationActionTypes): NotificationState {
  if (action.type !== SET_NOTIFICATION && action.type !== CLEAR_NOTIFICATION) {
    return state
  }

  switch (action.type) {
    case SET_NOTIFICATION: {
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
      }
    }
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        message: '',
      }
    }
    default:
      return state
  }
}
