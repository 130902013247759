import {
  EmailVerificationActionTypes,
  EmailVerificationResponse,
  GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_REQUEST,
  GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_SUCCESS,
  GET_SESSION_BY_ID_REQUEST,
  GET_SESSION_BY_ID_SUCCESS,
  MobileVerificationResponse,
  RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_REQUEST,
  RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_SUCCESS,
  RESEND_EMAIL_VERIFICATION_REQUEST,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_MOBILE_VERIFICATION_CODE_REQUEST,
  RESEND_MOBILE_VERIFICATION_CODE_SUCCESS,
  RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_REQUEST,
  RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_SUCCESS,
  Session,
  UPDATE_EMAIL_VERIFICATION_REQUEST,
  UPDATE_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_REQUEST,
  UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_SUCCESS,
} from './types'

export function resendEmailVerificationRequest(): EmailVerificationActionTypes {
  return {
    type: RESEND_EMAIL_VERIFICATION_REQUEST,
  }
}

export function resendEmailVerificationSuccess(
  emailVerificationResponse: EmailVerificationResponse,
): EmailVerificationActionTypes {
  return {
    type: RESEND_EMAIL_VERIFICATION_SUCCESS,
    payload: {
      data: emailVerificationResponse,
    },
  }
}

export function getSessionByIdRequest(): EmailVerificationActionTypes {
  return {
    type: GET_SESSION_BY_ID_REQUEST,
  }
}

export function getSessionByIdSuccess(session: Session): EmailVerificationActionTypes {
  return {
    type: GET_SESSION_BY_ID_SUCCESS,
    payload: {
      data: session,
    },
  }
}

export function updateEmailVerificationRequest(): EmailVerificationActionTypes {
  return {
    type: UPDATE_EMAIL_VERIFICATION_REQUEST,
  }
}

export function updateEmailVerificationSuccess(
  emailVerificationResponse: EmailVerificationResponse,
): EmailVerificationActionTypes {
  return {
    type: UPDATE_EMAIL_VERIFICATION_SUCCESS,
    payload: {
      data: emailVerificationResponse,
    },
  }
}

export function resendMobileVerificationCodeRequest(): EmailVerificationActionTypes {
  return {
    type: RESEND_MOBILE_VERIFICATION_CODE_REQUEST,
  }
}

export function resendMobileVerificationCodeSuccess(
  mobileVerificationResponse: MobileVerificationResponse,
): EmailVerificationActionTypes {
  return {
    type: RESEND_MOBILE_VERIFICATION_CODE_SUCCESS,
    payload: {
      data: mobileVerificationResponse,
    },
  }
}

export function updateMobileVerificationForSignUpRequest(): EmailVerificationActionTypes {
  return {
    type: UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_REQUEST,
  }
}

export function updateMobileVerificationForSignUpSuccess(
  mobileVerificationResponse: MobileVerificationResponse,
): EmailVerificationActionTypes {
  return {
    type: UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_SUCCESS,
    payload: {
      data: mobileVerificationResponse,
    },
  }
}

export function resendEmailVerificationForConsumerRequest(): EmailVerificationActionTypes {
  return {
    type: RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_REQUEST,
  }
}

export function resendEmailVerificationForConsumerSuccess(
  emailVerificationResponse: EmailVerificationResponse,
): EmailVerificationActionTypes {
  return {
    type: RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_SUCCESS,
    payload: {
      data: emailVerificationResponse,
    },
  }
}

export function resendMobileVerificationLinkForConsumerRequest(): EmailVerificationActionTypes {
  return {
    type: RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_REQUEST,
  }
}

export function resendMobileVerificationLinkForConsumerSuccess(
  mobileVerificationResponse: MobileVerificationResponse,
): EmailVerificationActionTypes {
  return {
    type: RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_SUCCESS,
    payload: {
      data: mobileVerificationResponse,
    },
  }
}

export function getMobileVerificationCodeForConsumerRequest(): EmailVerificationActionTypes {
  return {
    type: GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_REQUEST,
  }
}

export function getMobileVerificationCodeForConsumerSuccess(
  mobileVerificationResponse: MobileVerificationResponse,
): EmailVerificationActionTypes {
  return {
    type: GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_SUCCESS,
    payload: {
      data: mobileVerificationResponse,
    },
  }
}
