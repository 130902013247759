import { BasketNotification, Contact, FinancialTransactionMethodTokenize, Vehicle } from '../basket/types'
import { ConsumerCards } from '../consumer-cards/types'
import { ConsumerVehicle } from '../consumer-vehicle/types'
import { GeoFeature, SalesChannelType } from '../park-and-pay/types'

export interface ParkingItem {
  parkingQuoteId: string
  selectedZoneId?: string
  selectedMeterId?: string
  parkingQuoteItemId: string
  notificationSelections: BasketNotification[]
}

export interface AllParkingBasketItemsReq {
  kind: string
  parkingItem: ParkingItem
  paymentFinancialTransactionMethod?: FinancialTransactionMethodTokenize
  primaryContact?: Contact
  primaryVehicle: Vehicle
  salesChannelType: SalesChannelType
}

export interface StopParkingReq {
  agreementId: string
  facilityIds: string[]
  kind: string
  salesChannelType: SalesChannelType
  consumerId?: string
}

export interface CompleteTransactionReq {
  acceptedTermsAndPrivacy: boolean
  basketId: string
  salesChannelType: SalesChannelType
  sendPromoAndOffers: boolean
}

export interface CompleteTransactionPromiseResponse {
  id: string
  success: boolean
}

export interface ParkFlowState {
  selectedParkVehicle: ConsumerVehicle | null
  unsavedVehicles: ConsumerVehicle[]
  selectedParkCard: ConsumerCards | null
  unsavedCards: ConsumerCards[]
  isSaveMyDetails: boolean
  selectedGeoFeature: GeoFeature | null
}

export interface PrimaryDetail {
  contact: Contact
  vehicle: Vehicle
}

export const CREATE_PARKING_BASKET_API_RESOURCE_PATH = '/public/operators/:operatorId/baskets'
export const PUT_NOTIFICATION_TO_BASKET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/baskets/mybasket/parking-items/:basketItemId/convenience-notifications/:notificationId'
export const PUT_PRIMARY_DETAILS_TO_BASKET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/baskets/mybasket/parking-items/:basketItemId/primary-details'
export const PUT_PAYMENT_DETAILS_TO_BASKET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/baskets/mybasket/financial-transaction-methods/payment'
export const PUT_VEHICLE_DETAILS_TO_BASKET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/baskets/mybasket/parking-items/:basketItemId/vehicles/primary'
export const PUT_CONTACT_DETAILS_TO_BASKET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/baskets/mybasket/contacts/primary'
export const STOP_PARKING_API_RESOURCE_PATH = '/public/operators/:operatorId/baskets:stopParking'
export const COMPLETE_TRANSACTION_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/my-park-agreements/:agreementId:complete'
export const COMPLETE_PUBLIC_TRANSACTION_API_RESOURCE_PATH =
  '/public/operators/:operatorId/park-agreements/:agreementId:complete'
export const CANCEL_PARKING_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/my-park-agreements/:agreementId:cancel'
export const CANCEL_PUBLIC_PARKING_API_RESOURCE_PATH =
  '/public/operators/:operatorId/park-agreements/:agreementId:cancel'

export const SET_SELECTED_PARK_VEHICLE = 'SET_SELECTED_PARK_VEHICLE'
export const SET_UNSAVED_VEHICLES = 'SET_UNSAVED_VEHICLES'
export const SET_SELECTED_PARK_CARD = 'SET_SELECTED_PARK_CARD'
export const SET_UNSAVED_CARDS = 'SET_UNSAVED_CARDS'
export const SET_SAVE_MY_DETAILS = 'SET_SAVE_MY_DETAILS'
export const SET_SELECTED_PARK_GEO_FEATURE = 'SET_SELECTED_PARK_GEO_FEATURE'
export const RESET_PARK_FLOW = 'RESET_PARK_FLOW'

export interface setSelectedParkVehicle {
  type: typeof SET_SELECTED_PARK_VEHICLE
  payload: {
    data: ConsumerVehicle
  }
}

export interface setUnsavedVehicles {
  type: typeof SET_UNSAVED_VEHICLES
  payload: {
    data: ConsumerVehicle[]
  }
}

export interface setSelectedParkCard {
  type: typeof SET_SELECTED_PARK_CARD
  payload: {
    data: ConsumerCards
  }
}

export interface setUnsavedCards {
  type: typeof SET_UNSAVED_CARDS
  payload: {
    data: ConsumerCards[]
  }
}

export interface setSaveMyDetails {
  type: typeof SET_SAVE_MY_DETAILS
  payload: {
    data: boolean
  }
}

export interface setSelectedParkGeoFeature {
  type: typeof SET_SELECTED_PARK_GEO_FEATURE
  payload: {
    data: GeoFeature | null
  }
}

export interface resetParkFlow {
  type: typeof RESET_PARK_FLOW
}

export type ParkFlowActionTypes =
  | setSelectedParkVehicle
  | setUnsavedVehicles
  | setSelectedParkCard
  | setUnsavedCards
  | setSaveMyDetails
  | setSelectedParkGeoFeature
  | resetParkFlow
