import {
  GET_CONSUMER_ORDERED_FACILITIES_REQUEST,
  GET_CONSUMER_ORDERED_FACILITIES_SUCCESS,
  OrderedFacilitiesActionTypes,
  OrderedFacilitiesState,
} from './types'

const initialState: OrderedFacilitiesState = {
  facilityIdNames: [],
}

export function orderedFacilitiesReducer(
  state: OrderedFacilitiesState = initialState,
  action: OrderedFacilitiesActionTypes,
): OrderedFacilitiesState {
  switch (action.type) {
    case GET_CONSUMER_ORDERED_FACILITIES_REQUEST:
      return {
        ...state,
        facilityIdNames: [],
      }
    case GET_CONSUMER_ORDERED_FACILITIES_SUCCESS:
      return {
        ...state,
        facilityIdNames: action.payload.data,
      }
    default:
      return state
  }
}
