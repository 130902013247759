import { Amenity } from '../amenity/types'
import { AccessControlType, CarparkRateBoard, CarparkTimeBoard, LocationClass } from '../carpark-long-info/types'
import { Address, Marketing, Point, SalesChannelType } from '../park-and-pay/types'
import { Info, ListItemBranding, OfferClass, OfferFeature } from '../parking-quote/types'

export const GET_FACILITIES_BY_PUBLIC_OFFER_API_RESOURCE_PATH = `/public/operators/:operatorId/sales-channels/${SalesChannelType.Res}/public-offer-facilities/:clientRef`

export interface OfferFacility {
  id: string
  code: string
  clientRef: string
  publicName: string
  publicId: string
  name: string
  operatorId: string
  marketing: Marketing
  version: number
  clearance: number
  mainImageUrl: string
  mainSmallImageUrl: string | null
  mapDirectionsUrl: string
  timeZoneId: string
  address: Address
  rateBoard: CarparkRateBoard
  timeBoard: CarparkTimeBoard
  amenities: Amenity[]
  mapPoint: Point
  distanceFromPointMetres: number
  accessControlType: AccessControlType
  locationClass: LocationClass
  vacantSpace: number | null
}

export interface PublicOffer {
  id: string
  code: string
  clientRef: string
  version: number
  name: string
  imageUrl: string | null
  offerClass: OfferClass
  termsUrl: string | null
  marketing: Info
  terms: Info
  listItemBranding: ListItemBranding
  feature: OfferFeature
  allowAmend: boolean
  allowCancel: boolean
  allowExtend: boolean
  validFromDate: string
  validToDate: string
}

export interface PublicOfferFacilities {
  facilities: OfferFacility[]
  offer: PublicOffer
}

export interface PublicOfferFacilitiesState {
  publicOfferFacilities: PublicOfferFacilities | null
}

export interface PublicOfferFacilitiesPromiseResponse {
  success: boolean
  errorNotFound: boolean
}

export const GET_FACILITIES_BY_PUBLIC_OFFER_SUCCESS = 'GET_FACILITIES_BY_PUBLIC_OFFER_SUCCESS'

export interface getFacilitiesByPublicOfferSuccess {
  type: typeof GET_FACILITIES_BY_PUBLIC_OFFER_SUCCESS
  payload: {
    data: PublicOfferFacilities
  }
}

export type FacilitiesByOfferActionTypes = getFacilitiesByPublicOfferSuccess
