import { GeoFeature } from '../../park-and-pay/types'

export const GET_GEO_FEATURE_PROXIMITY_PATH =
  '/public/operators/:operatorId/sales-channel-links/:salesChannelType/geo-features/proximity'

export const GET_FACILITIES_PROXIMITY_PATH =
  '/public/operators/:operatorId/sales-channels/:salesChannelType/facilities/proximity'

export const GEO_FEATURE_PROXIMITY_REQUEST = 'GEO_FEATURE_PROXIMITY_REQUEST'
export const GEO_FEATURE_PROXIMITY_SUCCESS = 'GEO_FEATURE_PROXIMITY_SUCCESS'
export const RESET_GEO_FEATURE_PROXIMITY = 'RESET_GEO_FEATURE_PROXIMITY'

export interface GeoFeatureProximityState {
  geoFeatures: GeoFeature[] | null
}

interface geoFeatureProximityRequestAction {
  type: typeof GEO_FEATURE_PROXIMITY_REQUEST
}

interface geoFeatureProximitySuccessAction {
  type: typeof GEO_FEATURE_PROXIMITY_SUCCESS
  payload: {
    data: GeoFeature[]
  }
}

interface resetGeoFeatureProximityAction {
  type: typeof RESET_GEO_FEATURE_PROXIMITY
}

export type GeoFeatureProximityActionTypes =
  | geoFeatureProximityRequestAction
  | geoFeatureProximitySuccessAction
  | resetGeoFeatureProximityAction
