import { AmenityActionTypes, AmenityState, GET_AMENITY_REQUEST, GET_AMENITY_SUCCESS } from './types'

const initialState: AmenityState = {
  success: false,
  amenities: [],
}

export function amenityReducer(state: AmenityState = initialState, action: AmenityActionTypes): AmenityState {
  switch (action.type) {
    case GET_AMENITY_REQUEST:
      return {
        ...state,
        success: false,
        amenities: [],
      }
    case GET_AMENITY_SUCCESS:
      return {
        ...state,
        success: true,
        amenities: action.payload.amenities,
      }
    default:
      return state
  }
}
