import { Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Switch } from 'react-router-dom'
import {
  ROUTE_PATH_ACCOUNT_EXISTS_ERROR,
  ROUTE_PATH_ACCOUNT_SUSPENDED,
  ROUTE_PATH_ALLOW_COOKIE,
  ROUTE_PATH_APP_VERSION,
  ROUTE_PATH_CARPARK_ENTRY_CLOSED_ERROR,
  ROUTE_PATH_CHARGE_ACC_CARDS,
  ROUTE_PATH_CHARGE_ACC_COMPLETION,
  ROUTE_PATH_CHARGE_ACC_CONFIRMATION,
  ROUTE_PATH_CHARGE_ACC_VEHICLES,
  ROUTE_PATH_CONTACT_DETAILS,
  ROUTE_PATH_CPMS_PARKING_SUMMARY,
  ROUTE_PATH_DUPLICATE_BOOKING_WARNING,
  ROUTE_PATH_ENTER_CARPARK,
  ROUTE_PATH_ENTITLEMENT_DETAILS,
  ROUTE_PATH_ENTITLEMENT_VEHICLE,
  ROUTE_PATH_EXISTING_BOOKING_WARNING,
  ROUTE_PATH_FEEDBACK,
  ROUTE_PATH_FEEDBACK_SUCCESS,
  ROUTE_PATH_FIND_ACTIVE_PARK,
  ROUTE_PATH_FIND_BOOKING_RESPONSE,
  ROUTE_PATH_FORGET_ME,
  ROUTE_PATH_FORGOT_PASSWORD,
  ROUTE_PATH_GUEST_ACTIVE_PARKS,
  ROUTE_PATH_GUEST_AGREEMENTS,
  ROUTE_PATH_HOME,
  ROUTE_PATH_INSTALL_APP,
  ROUTE_PATH_INSTALL_APP_EXIST,
  ROUTE_PATH_INSTALL_APP_IOS,
  ROUTE_PATH_INSTALL_APP_LOADING,
  ROUTE_PATH_INSTALL_APP_SUCCESS,
  ROUTE_PATH_INVALID_OPERATION_REQUEST,
  ROUTE_PATH_LINK_ORDER_RESPONSE,
  ROUTE_PATH_LINK_ORDER_WARNING,
  ROUTE_PATH_MANAGE_ACCESS_ID,
  ROUTE_PATH_MANAGE_ACCESS_RESULT,
  ROUTE_PATH_MY_ACTIVITY,
  ROUTE_PATH_MY_ACTIVITY_HISTORY,
  ROUTE_PATH_MY_CARDS,
  ROUTE_PATH_MY_CARPARKS,
  ROUTE_PATH_MY_CHARGE_ACCOUNT,
  ROUTE_PATH_MY_PROFILE,
  ROUTE_PATH_MY_TRANSACTIONS,
  ROUTE_PATH_MY_VEHICLES,
  ROUTE_PATH_ORDER_COMPLETION,
  ROUTE_PATH_ORDER_CONFIRMATION,
  ROUTE_PATH_ORDER_DETAILS,
  ROUTE_PATH_ORDER_PROCESSING,
  ROUTE_PATH_PARKING_OFFER,
  ROUTE_PATH_PARKING_REMINDERS,
  ROUTE_PATH_PARK_CARDS,
  ROUTE_PATH_PARK_CONFIRM_ORDER,
  ROUTE_PATH_PARK_CONFIRM_PAYMENT,
  ROUTE_PATH_PARK_DUPLICATE_WARNING,
  ROUTE_PATH_PARK_FREE_PARKING,
  ROUTE_PATH_PARK_GUEST_EDIT_DETAILS,
  ROUTE_PATH_PARK_GUEST_PAYMENT_DETAILS,
  ROUTE_PATH_PARK_GUEST_YOUR_DETAILS,
  ROUTE_PATH_PARK_LOCATION_DETAILS,
  ROUTE_PATH_PARK_OVERSTAY_WARNING,
  ROUTE_PATH_PARK_PARKING_LOCATION,
  ROUTE_PATH_PARK_PARKING_PERMIT,
  ROUTE_PATH_PARK_PAYMENT_APPROVED,
  ROUTE_PATH_PARK_PAYMENT_DECLINED,
  ROUTE_PATH_PARK_PROCESSING_ORDER,
  ROUTE_PATH_PARK_PROCESSING_PAYMENT,
  ROUTE_PATH_PARK_RESTRICTED_PARKING,
  ROUTE_PATH_PARK_SELECT_DURATION,
  ROUTE_PATH_PARK_SELECT_DURATION_CLIENT_REF,
  ROUTE_PATH_PARK_VEHICLES,
  ROUTE_PATH_PARK_YOUR_DETAILS,
  ROUTE_PATH_PAYMENT_DECLINED,
  ROUTE_PATH_PAYMENT_DETAILS,
  ROUTE_PATH_PAYMENT_DETAILS_GUEST,
  ROUTE_PATH_PERMIT_WEB_API_LINK,
  ROUTE_PATH_QUICK_PARK,
  ROUTE_PATH_QUICK_PARK_GUEST,
  ROUTE_PATH_QUICK_PARK_PARKING_LOCATION,
  ROUTE_PATH_QUICK_PARK_SELECT_DURATION,
  ROUTE_PATH_RESET_PASSWORD,
  ROUTE_PATH_SALES_CHANNEL_PARK,
  ROUTE_PATH_SALES_CHANNEL_RES,
  ROUTE_PATH_SEARCH_CARPARK,
  ROUTE_PATH_SEARCH_CAR_PARK,
  ROUTE_PATH_SECURITY,
  ROUTE_PATH_SELECT_CARPARK_BY_OFFER,
  ROUTE_PATH_SELECT_DURATION,
  ROUTE_PATH_SESSION_TERMINATED,
  ROUTE_PATH_SETTINGS,
  ROUTE_PATH_SIGN_IN,
  ROUTE_PATH_SIGN_IN_WARNING,
  ROUTE_PATH_SIGN_UP,
  ROUTE_PATH_SUBSCRIPTIONS,
  ROUTE_PATH_VEHICLE_DETAILS,
  ROUTE_PATH_VEHICLE_DETAILS_GUEST,
  ROUTE_PATH_VERIFICATION_COMPLETE,
  ROUTE_PATH_VERIFICATION_FAILURE,
  ROUTE_PATH_VERIFICATION_INCOMPLETE,
  ROUTE_PATH_VERIFICATION_INVALID,
  ROUTE_PATH_VERIFY_CONSUMER_MOBILE,
  ROUTE_PATH_VERIFY_EMAIL,
  ROUTE_PATH_VERIFY_MOBILE,
  ROUTE_PATH_WEB_API_LINK,
} from '../common/constants'
import RouteWithLayout from '../layout/RouteWithLayout'
import { RootState } from '../store'
import {
  ROUTE_PATH_ACCESS_FAILURE,
  ROUTE_PATH_ACCESS_GATE_CONNECTING,
  ROUTE_PATH_ACCESS_GATE_ERROR,
  ROUTE_PATH_ACCESS_GATE_FAILURE,
  ROUTE_PATH_ACCESS_GATE_SUCCESS,
  ROUTE_PATH_ACCESS_INVALID_ORDER,
  ROUTE_PATH_ACCESS_INVALID_QR,
  ROUTE_PATH_AGREEMENT_ACCESS,
  ROUTE_PATH_AMEND_DETAILS,
  ROUTE_PATH_AMEND_GUEST_VEHICLE_DETAILS,
  ROUTE_PATH_AMEND_VEHICLE_DETAILS,
  ROUTE_PATH_BOOKING_CONFIRMATION,
  ROUTE_PATH_CANCEL_BOOKING,
  ROUTE_PATH_CARPARK_DETAILS,
  ROUTE_PATH_CASUAL_ACCESS_CONFIRM_ORDER,
  ROUTE_PATH_CASUAL_ACCESS_CREATE_ACCOUNT,
  ROUTE_PATH_CASUAL_ACCESS_ENTRY,
  ROUTE_PATH_CASUAL_ACCESS_EXIT,
  ROUTE_PATH_CASUAL_ACCESS_GUEST_PAYMENT_DETAILS,
  ROUTE_PATH_CASUAL_ACCESS_ORDER_REQUIRED,
  ROUTE_PATH_CASUAL_ACCESS_PARKING_SUMMARY,
  ROUTE_PATH_CASUAL_ACCESS_PAY,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_APPROVED,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DECLINED,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DETAILS,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_PROCESSING,
  ROUTE_PATH_CELLULAR_ACCESS,
  ROUTE_PATH_MANAGE_PARK,
  ROUTE_PATH_MAP_VIEW,
  ROUTE_PATH_QRCODE_ACCESS,
  ROUTE_PATH_REVERSE_QRCODE_ACCESS,
  ROUTE_PATH_TRANSACTION_DECLINED,
  ROUTE_PATH_VIEW_INVOICES,
  SalesChannelType,
} from '../store/park-and-pay/types'
import { PlatformStatus } from './types'

const AccountExistsError = lazy(() => import('../pages/auth/account-exists-error'))
const AccountSuspended = lazy(() => import('../pages/auth/account-suspended'))
const AppVersion = lazy(() => import('../pages/auth/app-version'))
const ForgotPassword = lazy(() => import('../pages/auth/forgot-password/forgot-password'))
const ResetPassword = lazy(() => import('../pages/auth/forgot-password/reset-password'))
const SignIn = lazy(() => import('../pages/auth/sign-in'))
const SignUp = lazy(() => import('../pages/auth/sign-up'))
const VerificationComplete = lazy(() => import('../pages/auth/verification-complete'))
const VerificationFailed = lazy(() => import('../pages/auth/verification-failed'))
const VerificationIncomplete = lazy(() => import('../pages/auth/verification-incomplete'))
const VerificationInvalid = lazy(() => import('../pages/auth/verification-invalid'))
const VerifyEmail = lazy(() => import('../pages/auth/verify-email'))
const VerifyMobile = lazy(() => import('../pages/auth/verify-mobile'))
const Home = lazy(() => import('../pages/home/home'))
const CarparkDetails = lazy(() => import('../pages/parking/carpark-details'))
const CarparkIdSearch = lazy(() => import('../pages/parking/carpark-id-search'))
const ChargeAccCards = lazy(() => import('../pages/parking/charge-acc/cards'))
const ChargeAccCompletion = lazy(() => import('../pages/parking/charge-acc/completion'))
const ChargeAccConfirmation = lazy(() => import('../pages/parking/charge-acc/confirmation'))
const ChargeAccVehicles = lazy(() => import('../pages/parking/charge-acc/vehicles'))
const CarparkEntryClosedError = lazy(() => import('../pages/parking/common/errors/carpark-entry-closed-error'))
const LinkOrderResponse = lazy(() => import('../pages/parking/common/link-order-response'))
const LinkOrderWarning = lazy(() => import('../pages/parking/common/link-order-warning'))
const SignInWarning = lazy(() => import('../pages/parking/common/sign-in-warning'))
const ContactDetails = lazy(() => import('../pages/parking/contact-details'))
const DuplicateBookingWarning = lazy(() => import('../pages/parking/duplicate-booking-warning'))
const EnterCarpark = lazy(() => import('../pages/parking/enter-carpark/enter-carpark'))
const Feedback = lazy(() => import('../pages/parking/feedback/feedback'))
const FeedbackSuccess = lazy(() => import('../pages/parking/feedback/feedback-success'))
const LocationIdSearch = lazy(() => import('../pages/parking/location-id-search'))
const Maintenance = lazy(() => import('../pages/parking/maintenance'))
const MapView = lazy(() => import('../pages/parking/map-view'))
const AccessFailure = lazy(() => import('../pages/parking/my-profile/agreement-access/access-failure'))
const AccessGateConnecting = lazy(() => import('../pages/parking/my-profile/agreement-access/access-gate-connecting'))
const AccessGateError = lazy(() => import('../pages/parking/my-profile/agreement-access/access-gate-error'))
const AccessGateFailure = lazy(() => import('../pages/parking/my-profile/agreement-access/access-gate-failure'))
const AccessGateSuccess = lazy(() => import('../pages/parking/my-profile/agreement-access/access-gate-success'))
const AccessInvalidOrder = lazy(() => import('../pages/parking/my-profile/agreement-access/access-invalid-order'))
const AccessInvalidQR = lazy(() => import('../pages/parking/my-profile/agreement-access/access-invalid-qr'))
const AgreementAccess = lazy(() => import('../pages/parking/my-profile/agreement-access/agreement-access'))
const CellularAccess = lazy(() => import('../pages/parking/my-profile/agreement-access/cellular-access'))
const QrcodeAccess = lazy(() => import('../pages/parking/my-profile/agreement-access/qrcode-access'))
const ReverseQrcodeAccess = lazy(() => import('../pages/parking/my-profile/agreement-access/reverse-qrcode-access'))
const CasualAccess = lazy(() => import('../pages/parking/my-profile/casual-access/casual-access'))
const CasualAccessExit = lazy(() => import('../pages/parking/my-profile/casual-access/casual-access-exit'))
const CasualAccessOrderReq = lazy(() => import('../pages/parking/my-profile/casual-access/casual-access-order-req'))
const CasualAccessPay = lazy(() => import('../pages/parking/my-profile/casual-access/casual-access-pay'))
const CasualParkConfirmOrder = lazy(() => import('../pages/parking/my-profile/casual-access/confirm-order'))
const CasualParkCreateAccount = lazy(() => import('../pages/parking/my-profile/casual-access/create-account'))
const CasualParkGuestPaymentDetails = lazy(
  () => import('../pages/parking/my-profile/casual-access/guest-payment-details'),
)
const CasualParkingSummary = lazy(() => import('../pages/parking/my-profile/casual-access/parking-summary'))
const CasualPaymentApproved = lazy(() => import('../pages/parking/my-profile/casual-access/payment-approved'))
const CasualPaymentDeclined = lazy(() => import('../pages/parking/my-profile/casual-access/payment-declined'))
const CasualParkPaymentDetails = lazy(() => import('../pages/parking/my-profile/casual-access/payment-details'))
const CasualPaymentProcessing = lazy(() => import('../pages/parking/my-profile/casual-access/payment-processing'))
const FindBooking = lazy(() => import('../pages/parking/my-profile/find-booking/find-booking'))
const FindBookingResponse = lazy(() => import('../pages/parking/my-profile/find-booking/find-booking-response'))
const GuestAgreements = lazy(() => import('../pages/parking/my-profile/find-booking/guest-agreements'))
const ForgetMe = lazy(() => import('../pages/parking/my-profile/forget-me'))
const AmendDetails = lazy(() => import('../pages/parking/my-profile/my-agreement/amend-details/amend-details'))
const AmendVehicleDetails = lazy(() => import('../pages/parking/my-profile/my-agreement/amend-details/vehicle-details'))
const AmendGuestVehicleDetails = lazy(
  () => import('../pages/parking/my-profile/my-agreement/amend-details/vehicle-details-guest'),
)
const BookingConfirmation = lazy(() => import('../pages/parking/my-profile/my-agreement/booking-confirmation'))
const CancelBooking = lazy(() => import('../pages/parking/my-profile/my-agreement/cancel-booking'))
const ManagePark = lazy(() => import('../pages/parking/my-profile/my-agreement/manage-park'))
const TransactionDeclined = lazy(() => import('../pages/parking/my-profile/my-agreement/transaction-declined'))
const ViewInvoices = lazy(() => import('../pages/parking/my-profile/my-agreement/view-invoices'))
const MyActivity = lazy(() => import('../pages/parking/my-profile/my-agreements/activity/my-activity'))
const MyEntitlementVehicle = lazy(
  () => import('../pages/parking/my-profile/my-agreements/entitlement/my-entitlement-vehicle'),
)
const ViewEntitlementDetails = lazy(
  () => import('../pages/parking/my-profile/my-agreements/entitlement/view-entitlement-details'),
)
const GuestActiveParks = lazy(() => import('../pages/parking/my-profile/my-agreements/guest-active-parks'))
const MyInactiveAgreements = lazy(
  () => import('../pages/parking/my-profile/my-agreements/history/my-inactive-agreements'),
)
const MyCards = lazy(() => import('../pages/parking/my-profile/my-cards'))
const MyCarparks = lazy(() => import('../pages/parking/my-profile/my-carparks'))
const MyChargeAcc = lazy(() => import('../pages/parking/my-profile/my-charge-acc'))
const MyProfile = lazy(() => import('../pages/parking/my-profile/my-profile'))
const ManageAccessID = lazy(() => import('../pages/parking/my-profile/manage-access-id'))
const ManageAccessResult = lazy(() => import('../pages/parking/my-profile/manage-access-result'))
const MySecurity = lazy(() => import('../pages/parking/my-profile/my-security'))
const MySetting = lazy(() => import('../pages/parking/my-profile/my-settings'))
const MySubscriptions = lazy(() => import('../pages/parking/my-profile/my-subscriptions'))
const MyVehicle = lazy(() => import('../pages/parking/my-profile/my-vehicle'))
const Transactions = lazy(() => import('../pages/parking/my-profile/transactions/transactions'))
const OrderCompletion = lazy(() => import('../pages/parking/order-completion'))
const OrderConfirm = lazy(() => import('../pages/parking/order-confirm'))
const OrderProcessing = lazy(() => import('../pages/parking/order-processing'))
const ParkConfirmOrder = lazy(() => import('../pages/parking/park/park-confirm-order'))
const ParkConfirmPayment = lazy(() => import('../pages/parking/park/park-confirm-payment'))
const ParkDuplicateWarning = lazy(() => import('../pages/parking/park/park-duplicate-warning'))
const ParkFreeParking = lazy(() => import('../pages/parking/park/park-free-parking'))
const ParkGuestEditDetails = lazy(() => import('../pages/parking/park/park-guest-edit-details'))
const ParkGuestPaymentDetails = lazy(() => import('../pages/parking/park/park-guest-payment-details'))
const ParkGuestYourDetails = lazy(() => import('../pages/parking/park/park-guest-your-details'))
const ParkLocationDetails = lazy(() => import('../pages/parking/park/park-location-details'))
const ParkOverstayWarning = lazy(() => import('../pages/parking/park/park-overstay-warning'))
const ParkParkingLocation = lazy(() => import('../pages/parking/park/park-parking-location'))
const ParkParkingPermit = lazy(() => import('../pages/parking/park/park-parking-permit'))
const ParkPaymentApproved = lazy(() => import('../pages/parking/park/park-payment-approved'))
const ParkPaymentDeclined = lazy(() => import('../pages/parking/park/park-payment-declined'))
const ParkProcessingOrder = lazy(() => import('../pages/parking/park/park-processing-order'))
const ParkProcessingPayment = lazy(() => import('../pages/parking/park/park-processing-payment'))
const ParkRestrictedParking = lazy(() => import('../pages/parking/park/park-restricted-parking'))
const ParkScanRedirector = lazy(() => import('../pages/parking/park/park-scan-redirector'))
const ParkSelectDuration = lazy(() => import('../pages/parking/park/park-select-duration'))
const ParkYourDetails = lazy(() => import('../pages/parking/park/park-your-details'))
const ParkCards = lazy(() => import('../pages/parking/park/vehicles-cards/park-cards'))
const ParkVehicles = lazy(() => import('../pages/parking/park/vehicles-cards/park-vehicles'))
const ParkingOffer = lazy(() => import('../pages/parking/parking-offer'))
const ParkingReminders = lazy(() => import('../pages/parking/parking-reminders'))
const PaymentDeclined = lazy(() => import('../pages/parking/payment-declined'))
const PaymentDetails = lazy(() => import('../pages/parking/payment-details'))
const PaymentDetailsGuest = lazy(() => import('../pages/parking/payment-details-guest'))
const ExistingBookingWarning = lazy(() => import('../pages/parking/quick-park/existing-booking-warning'))
const GuestQuickPark = lazy(() => import('../pages/parking/quick-park/guest-quick-park'))
const ParkingLocation = lazy(() => import('../pages/parking/quick-park/parking-location'))
const QuickPark = lazy(() => import('../pages/parking/quick-park/quick-park'))
const SelectStartDate = lazy(() => import('../pages/parking/quick-park/select-start-date'))
const SelectDuration = lazy(() => import('../pages/parking/select-duration'))
const VehicleDetails = lazy(() => import('../pages/parking/vehicle-details'))
const VehicleDetailsGuest = lazy(() => import('../pages/parking/vehicle-details-guest'))
const ViewOrderDetail = lazy(() => import('../pages/parking/view-order-detail'))
const WebApiLink = lazy(() => import('../pages/parking/web-api-link'))
const SelectCarparkByOffer = lazy(() => import('../pages/parking/select-carpark-by-offer'))
const InvalidOperationRequest = lazy(() => import('../pages/parking/invalid-operation-request'))
const PermitWebApi = lazy(() => import('../pages/parking/permits/permit-web-api'))
const CpmsParkingSummary = lazy(() => import('../pages/parking/my-profile/cpms/parking-summary'))
const AllowCookie = lazy(() => import('../pages/pwa/allow-cookie'))
const InstallApp = lazy(() => import('../pages/pwa/install-app'))
const InstallAppExist = lazy(() => import('../pages/pwa/install-app-exist'))
const InstallAppIOS = lazy(() => import('../pages/pwa/install-app-ios'))
const InstallAppIOSHome = lazy(() => import('../pages/pwa/install-app-ios-home'))
const InstallAppLoading = lazy(() => import('../pages/pwa/install-app-loading'))
const InstallAppSuccess = lazy(() => import('../pages/pwa/install-app-success'))

const AppRoutes: React.FC = () => {
  const isIPhone = /iPhone/i.test(navigator.userAgent)

  const { tenantConfig } = useSelector((state: RootState) => ({
    tenantConfig: state.appConfigReducer.tenantConfig,
  }))

  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Switch>
          <RouteWithLayout exact path={ROUTE_PATH_MY_ACTIVITY} component={MyActivity} isPrivateRoute={true} />
          <RouteWithLayout
            path={ROUTE_PATH_MY_ACTIVITY_HISTORY}
            component={MyInactiveAgreements}
            isPrivateRoute={true}
          />
          <RouteWithLayout path={ROUTE_PATH_MY_PROFILE} component={MyProfile} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_MANAGE_ACCESS_ID} component={ManageAccessID} isPrivateRoute={true} />
          <RouteWithLayout
            path={ROUTE_PATH_MANAGE_ACCESS_RESULT}
            component={ManageAccessResult}
            isPrivateRoute={true}
          />
          <RouteWithLayout path={ROUTE_PATH_MY_VEHICLES} component={MyVehicle} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_MY_CARDS} component={MyCards} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_MY_TRANSACTIONS} component={Transactions} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_MY_CARPARKS} component={MyCarparks} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_SETTINGS} component={MySetting} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_SECURITY} component={MySecurity} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_FORGET_ME} component={ForgetMe} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_PARK_VEHICLES} component={ParkVehicles} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_PARK_CARDS} component={ParkCards} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_PARK_YOUR_DETAILS} component={ParkYourDetails} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_VEHICLE_DETAILS} component={VehicleDetails} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_PAYMENT_DETAILS} component={PaymentDetails} isPrivateRoute={true} />
          <RouteWithLayout path={ROUTE_PATH_QUICK_PARK} component={QuickPark} isPrivateRoute={true} />
          <RouteWithLayout
            path={ROUTE_PATH_QUICK_PARK_SELECT_DURATION}
            component={SelectStartDate}
            isPrivateRoute={true}
          />
          <RouteWithLayout
            path={ROUTE_PATH_QUICK_PARK_PARKING_LOCATION}
            component={ParkingLocation}
            isPrivateRoute={true}
          />
          <RouteWithLayout
            path={ROUTE_PATH_EXISTING_BOOKING_WARNING}
            component={ExistingBookingWarning}
            isPrivateRoute={true}
          />
          <RouteWithLayout
            path={ROUTE_PATH_DUPLICATE_BOOKING_WARNING}
            component={DuplicateBookingWarning}
            isPrivateRoute={true}
          />

          {/* Res and Park flow */}

          <RouteWithLayout
            exact
            path={ROUTE_PATH_SALES_CHANNEL_RES}
            component={CarparkIdSearch}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_SEARCH_CAR_PARK}
            component={CarparkIdSearch}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_SELECT_DURATION}
            component={SelectDuration}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARKING_OFFER}
            component={ParkingOffer}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARKING_REMINDERS}
            component={ParkingReminders}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CONTACT_DETAILS}
            component={ContactDetails}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_VEHICLE_DETAILS_GUEST}
            component={VehicleDetailsGuest}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PAYMENT_DETAILS_GUEST}
            component={PaymentDetailsGuest}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PAYMENT_DECLINED}
            component={PaymentDeclined}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_ORDER_CONFIRMATION}
            component={OrderConfirm}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_ORDER_PROCESSING}
            component={OrderProcessing}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_ORDER_COMPLETION}
            component={OrderCompletion}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_WEB_API_LINK}
            component={WebApiLink}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_BOOKING_CONFIRMATION + '/:orderId'}
            component={BookingConfirmation}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CANCEL_BOOKING + '/:orderId'}
            component={CancelBooking}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_TRANSACTION_DECLINED}
            component={TransactionDeclined}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_AMEND_DETAILS + '/:orderId'}
            component={AmendDetails}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_AMEND_VEHICLE_DETAILS + '/:orderId'}
            component={AmendVehicleDetails}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_AMEND_GUEST_VEHICLE_DETAILS + '/:orderId'}
            component={AmendGuestVehicleDetails}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_SELECT_CARPARK_BY_OFFER}
            component={SelectCarparkByOffer}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout
            path={ROUTE_PATH_INVALID_OPERATION_REQUEST}
            component={InvalidOperationRequest}
            salesChannelType={SalesChannelType.Res}
          />
          <RouteWithLayout path={ROUTE_PATH_SEARCH_CARPARK} component={LocationIdSearch} />
          <RouteWithLayout path={ROUTE_PATH_CARPARK_DETAILS + '/:facilityId'} component={CarparkDetails} />
          <RouteWithLayout path={ROUTE_PATH_MAP_VIEW} component={MapView} />
          <RouteWithLayout path={ROUTE_PATH_ORDER_DETAILS + '/:orderId'} component={ViewOrderDetail} />
          <RouteWithLayout path={ROUTE_PATH_FIND_ACTIVE_PARK} component={FindBooking} />
          <RouteWithLayout path={ROUTE_PATH_FIND_BOOKING_RESPONSE} component={FindBookingResponse} />
          <RouteWithLayout path={ROUTE_PATH_GUEST_AGREEMENTS + '/:orderId'} component={GuestAgreements} />
          <RouteWithLayout path={ROUTE_PATH_SIGN_IN_WARNING} component={SignInWarning} />
          <RouteWithLayout path={ROUTE_PATH_LINK_ORDER_WARNING} component={LinkOrderWarning} />
          <RouteWithLayout path={ROUTE_PATH_LINK_ORDER_RESPONSE} component={LinkOrderResponse} />
          <RouteWithLayout path={ROUTE_PATH_GUEST_ACTIVE_PARKS} component={GuestActiveParks} />
          <RouteWithLayout path={ROUTE_PATH_MANAGE_PARK + '/:orderId'} component={ManagePark} />
          <RouteWithLayout path={ROUTE_PATH_VIEW_INVOICES + '/:orderId'} component={ViewInvoices} />

          {/* Res and Park flow */}

          {/* Agreement Access */}

          <RouteWithLayout path={ROUTE_PATH_ENTER_CARPARK} component={EnterCarpark} />
          <RouteWithLayout path={ROUTE_PATH_AGREEMENT_ACCESS + '/:agreementId'} component={AgreementAccess} />
          <RouteWithLayout path={ROUTE_PATH_CELLULAR_ACCESS} component={CellularAccess} />
          <RouteWithLayout path={ROUTE_PATH_QRCODE_ACCESS} component={QrcodeAccess} />
          <RouteWithLayout path={ROUTE_PATH_REVERSE_QRCODE_ACCESS} component={ReverseQrcodeAccess} />
          <RouteWithLayout path={ROUTE_PATH_ACCESS_FAILURE + '/:agreementId'} component={AccessFailure} />
          <RouteWithLayout path={ROUTE_PATH_ACCESS_GATE_CONNECTING} component={AccessGateConnecting} />
          <RouteWithLayout path={ROUTE_PATH_ACCESS_GATE_ERROR} component={AccessGateError} />
          <RouteWithLayout path={ROUTE_PATH_ACCESS_GATE_FAILURE + '/:agreementId'} component={AccessGateFailure} />
          <RouteWithLayout path={ROUTE_PATH_ACCESS_GATE_SUCCESS} component={AccessGateSuccess} />
          <RouteWithLayout path={ROUTE_PATH_ACCESS_INVALID_QR} component={AccessInvalidQR} />
          <RouteWithLayout path={ROUTE_PATH_ACCESS_INVALID_ORDER} component={AccessInvalidOrder} />

          {/* Agreement Access */}

          {/* Leases */}

          <RouteWithLayout
            path={ROUTE_PATH_SUBSCRIPTIONS}
            component={MySubscriptions}
            isPrivateRoute={true}
            salesChannelType={SalesChannelType.Lease}
          />

          {/* Leases */}

          {/* Charge Account */}

          <RouteWithLayout
            exact
            path={ROUTE_PATH_MY_CHARGE_ACCOUNT}
            component={MyChargeAcc}
            isPrivateRoute={true}
            salesChannelType={SalesChannelType.Charge}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CHARGE_ACC_VEHICLES}
            component={ChargeAccVehicles}
            isPrivateRoute={true}
            salesChannelType={SalesChannelType.Charge}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CHARGE_ACC_CARDS}
            component={ChargeAccCards}
            isPrivateRoute={true}
            salesChannelType={SalesChannelType.Charge}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CHARGE_ACC_CONFIRMATION}
            component={ChargeAccConfirmation}
            isPrivateRoute={true}
            salesChannelType={SalesChannelType.Charge}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CHARGE_ACC_COMPLETION}
            component={ChargeAccCompletion}
            isPrivateRoute={true}
            salesChannelType={SalesChannelType.Charge}
          />

          {/* Charge Account */}

          {/* Casual Access */}

          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_ENTRY}
            component={CasualAccess}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_EXIT}
            component={CasualAccessExit}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_PAY}
            component={CasualAccessPay}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_ORDER_REQUIRED}
            component={CasualAccessOrderReq}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_PARKING_SUMMARY + '/:agreementId'}
            component={CasualParkingSummary}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DETAILS}
            component={CasualParkPaymentDetails}
            isPrivateRoute={true}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_GUEST_PAYMENT_DETAILS}
            component={CasualParkGuestPaymentDetails}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_CONFIRM_ORDER}
            component={CasualParkConfirmOrder}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_PAYMENT_PROCESSING}
            component={CasualPaymentProcessing}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DECLINED}
            component={CasualPaymentDeclined}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_PAYMENT_APPROVED}
            component={CasualPaymentApproved}
            salesChannelType={SalesChannelType.Access}
          />
          <RouteWithLayout
            path={ROUTE_PATH_CASUAL_ACCESS_CREATE_ACCOUNT + '/:orderId'}
            component={CasualParkCreateAccount}
            salesChannelType={SalesChannelType.Access}
          />

          {/* Casual Access */}

          {/* Auth */}

          <RouteWithLayout path={ROUTE_PATH_SIGN_IN} component={SignIn} />
          <RouteWithLayout path={ROUTE_PATH_FORGOT_PASSWORD} component={ForgotPassword} />
          <RouteWithLayout path={ROUTE_PATH_SIGN_UP} component={SignUp} />
          <RouteWithLayout path={ROUTE_PATH_VERIFY_EMAIL} component={VerifyEmail} />
          <RouteWithLayout path={ROUTE_PATH_VERIFY_MOBILE} component={VerifyMobile} />
          <RouteWithLayout path={ROUTE_PATH_VERIFY_CONSUMER_MOBILE} component={VerifyMobile} />
          <RouteWithLayout path={ROUTE_PATH_VERIFICATION_FAILURE} component={VerificationFailed} />
          <RouteWithLayout path={ROUTE_PATH_VERIFICATION_INVALID} component={VerificationInvalid} />
          <RouteWithLayout path={ROUTE_PATH_RESET_PASSWORD} component={ResetPassword} />
          <RouteWithLayout path={ROUTE_PATH_VERIFICATION_COMPLETE} component={VerificationComplete} />
          <RouteWithLayout path={ROUTE_PATH_VERIFICATION_INCOMPLETE} component={VerificationIncomplete} />
          <RouteWithLayout path={ROUTE_PATH_ACCOUNT_EXISTS_ERROR} component={AccountExistsError} />
          <RouteWithLayout path={ROUTE_PATH_SESSION_TERMINATED} component={AccountSuspended} />
          <RouteWithLayout path={ROUTE_PATH_ACCOUNT_SUSPENDED} component={AccountSuspended} />

          {/* Auth */}

          {/* Feedback */}

          <RouteWithLayout path={ROUTE_PATH_FEEDBACK} component={Feedback} />
          <RouteWithLayout path={ROUTE_PATH_FEEDBACK_SUCCESS} component={FeedbackSuccess} />

          {/* Feedback */}

          {/* Park Flow */}

          <RouteWithLayout
            exact
            path={ROUTE_PATH_SALES_CHANNEL_PARK}
            component={ParkLocationDetails}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_LOCATION_DETAILS}
            component={ParkLocationDetails}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_PARKING_LOCATION}
            component={ParkParkingLocation}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_SELECT_DURATION}
            component={ParkSelectDuration}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_SELECT_DURATION_CLIENT_REF}
            component={ParkScanRedirector}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_GUEST_YOUR_DETAILS}
            component={ParkGuestYourDetails}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_GUEST_PAYMENT_DETAILS}
            component={ParkGuestPaymentDetails}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_GUEST_EDIT_DETAILS}
            component={ParkGuestEditDetails}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_CONFIRM_ORDER}
            component={ParkConfirmOrder}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_CONFIRM_PAYMENT}
            component={ParkConfirmPayment}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_PROCESSING_ORDER}
            component={ParkProcessingOrder}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_PROCESSING_PAYMENT}
            component={ParkProcessingPayment}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_PAYMENT_DECLINED}
            component={ParkPaymentDeclined}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_PAYMENT_APPROVED}
            component={ParkPaymentApproved}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_FREE_PARKING}
            component={ParkFreeParking}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_RESTRICTED_PARKING}
            component={ParkRestrictedParking}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_OVERSTAY_WARNING}
            component={ParkOverstayWarning}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_DUPLICATE_WARNING}
            component={ParkDuplicateWarning}
            salesChannelType={SalesChannelType.Park}
          />
          <RouteWithLayout
            path={ROUTE_PATH_PARK_PARKING_PERMIT}
            component={ParkParkingPermit}
            salesChannelType={SalesChannelType.Park}
          />

          {/* Park Flow */}

          <RouteWithLayout
            path={ROUTE_PATH_PERMIT_WEB_API_LINK}
            component={PermitWebApi}
            salesChannelType={SalesChannelType.Res}
          />

          <RouteWithLayout path={ROUTE_PATH_CPMS_PARKING_SUMMARY + '/:agreementId'} component={CpmsParkingSummary} />

          {/* Error Screens */}

          <RouteWithLayout path={ROUTE_PATH_CARPARK_ENTRY_CLOSED_ERROR} component={CarparkEntryClosedError} />

          {/* Error Screens */}

          {/* General */}

          <RouteWithLayout
            path={ROUTE_PATH_ENTITLEMENT_DETAILS + '/:entitlementId'}
            component={ViewEntitlementDetails}
          />
          <RouteWithLayout path={ROUTE_PATH_ENTITLEMENT_VEHICLE + '/:entitlementId'} component={MyEntitlementVehicle} />
          <RouteWithLayout path={ROUTE_PATH_QUICK_PARK_GUEST} component={GuestQuickPark} />
          <RouteWithLayout path={ROUTE_PATH_APP_VERSION} component={AppVersion} />
          <RouteWithLayout path={ROUTE_PATH_HOME} component={Home} />

          {/* General */}

          {/* PWA */}

          <RouteWithLayout
            exact
            path={ROUTE_PATH_ALLOW_COOKIE}
            component={
              tenantConfig && tenantConfig.config.platformStatus === PlatformStatus.Offline ? Maintenance : AllowCookie
            }
          />
          <RouteWithLayout path={ROUTE_PATH_INSTALL_APP} component={isIPhone ? InstallAppIOSHome : InstallApp} />
          <RouteWithLayout path={ROUTE_PATH_INSTALL_APP_LOADING} component={InstallAppLoading} />
          <RouteWithLayout path={ROUTE_PATH_INSTALL_APP_SUCCESS} component={InstallAppSuccess} />
          <RouteWithLayout path={ROUTE_PATH_INSTALL_APP_EXIST} component={InstallAppExist} />
          <RouteWithLayout path={ROUTE_PATH_INSTALL_APP_IOS} component={InstallAppIOS} />

          {/* PWA */}

          <RouteWithLayout path="*" component={Home} />

          {/* General */}
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default AppRoutes
