import { Alert, Box, Button, Container, Grid, Slide } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ROUTE_PATH_HOME } from '../../../common/constants'
import { SESSION_STORAGE_KEY_BASKET_ID, SESSION_STORAGE_KEY_PARKING_QUOTE_ID } from '../../../common/types'
import { RootState } from '../../../store'
import { resetParkingBasket } from '../../../store/basket/actions'
import { resetCarParkSearch } from '../../../store/carpark-search/actions'
import { resetOrderForm } from '../../../store/park-and-pay/action'

const useStyles = makeStyles(() => ({
  root: {
    padding: 8,
  },
  submitError: {
    fontSize: 14,
    color: '#e53935',
  },
}))

const BasketExpiredHandler: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [openBottomSheet, setOpenBottomSheet] = useState(false)

  const { parkingQuoteExpired, parkingBasketExpired } = useSelector(
    (state: RootState) => ({
      parkingQuoteExpired: state.quoteReducer.quoteExpired,
      parkingBasketExpired: state.basketReducer.parkingBasketExpired,
    }),
    shallowEqual,
  )

  const onExpiryButtonBack = () => {
    localStorage.removeItem('declinedPayments')
    sessionStorage.removeItem(SESSION_STORAGE_KEY_BASKET_ID)
    sessionStorage.removeItem(SESSION_STORAGE_KEY_PARKING_QUOTE_ID)
    dispatch(resetParkingBasket())
    dispatch(resetCarParkSearch())
    dispatch(resetOrderForm())
    setOpenBottomSheet(false)
    history.push(ROUTE_PATH_HOME)
  }

  // failed basket save
  useEffect(() => {
    if (parkingBasketExpired || parkingQuoteExpired) {
      setOpenBottomSheet(true)
    }
  }, [parkingBasketExpired, parkingQuoteExpired])

  return (
    <div className={openBottomSheet ? 'basket-expired-overlay-bg' : 'visibility-hidden'} style={{ zIndex: 10000 }}>
      <Slide direction="up" in={openBottomSheet}>
        <Box className="basket-expired-center">
          <Container style={{ margin: '10px 0 25px 0' }}>
            <Grid container>
              <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                  <Alert severity="error">
                    <div className={classes.submitError}>Sorry, your session has expired.</div>
                  </Alert>
                </Grid>
              </Grid>
              <Button
                className="basket-expiry-button"
                variant="contained"
                color="secondary"
                size="large"
                onClick={onExpiryButtonBack}
              >
                Start over
              </Button>
            </Grid>
          </Container>
        </Box>
      </Slide>
    </div>
  )
}

export default BasketExpiredHandler
