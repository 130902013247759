import { GeoFacilityType } from '../../../common/types'
import { AccessControlType, LocationClass } from '../../carpark-long-info/types'
import { Address, Feature, GeoMapLineString, LocationType, Point } from '../../park-and-pay/types'

export const GET_GEO_FEATURE_FACILITIES_PATH = '/public/operators/:operatorId/facilities/proximity'

export const GET_GEO_FEATURE_FACILITIES_REQUEST = 'GET_GEO_FEATURE_FACILITIES_REQUEST'
export const GET_GEO_FEATURE_FACILITIES_SUCCESS = 'GET_GEO_FEATURE_FACILITIES_SUCCESS'
export const RESET_GEO_FEATURE_FACILITIES = 'RESET_GEO_FEATURE_FACILITIES'

export interface GeoFeatureFacility {
  geoFacilityType: GeoFacilityType
  facilityId: string
  name: string
  publicId: string
  distanceFromPointMetres: number
  location: Point
  locationType: LocationType
  locationPoint: Point
  locationLineString: GeoMapLineString
  feature: Feature
  locationClass: LocationClass
  accessControlType: AccessControlType
  mainImageUrl: string
  address: Address
}

export interface GeoFeatureFacilitiesState {
  geoFeatureFacilities: GeoFeatureFacility[]
}

interface getGeoFeatureFacilitiesRequestAction {
  type: typeof GET_GEO_FEATURE_FACILITIES_REQUEST
}

interface getGeoFeatureFacilitiesSuccessAction {
  type: typeof GET_GEO_FEATURE_FACILITIES_SUCCESS
  payload: {
    data: GeoFeatureFacility[]
  }
}

interface resetGeoFeatureFacilitiesAction {
  type: typeof RESET_GEO_FEATURE_FACILITIES
}

export type GeoFeatureFacilitiesActionTypes =
  | getGeoFeatureFacilitiesRequestAction
  | getGeoFeatureFacilitiesSuccessAction
  | resetGeoFeatureFacilitiesAction
