import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { setDeferredPrompt } from '../../store/pwa-install/actions'

const useBeforeInstallPrompt = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const onInstallPrompt = (e: any) => {
      console.error('PWA: beforeinstallprompt event ===>', JSON.stringify(e))
      e.preventDefault()
      dispatch(setDeferredPrompt(e))
    }

    window.addEventListener('beforeinstallprompt', onInstallPrompt)

    return () => {
      window.removeEventListener('beforeinstallprompt', onInstallPrompt)
    }
  }, [dispatch])
}

export default useBeforeInstallPrompt
