import { createIndexedDb, indexedStore } from '../../services/idexed-db'
import { AppThunk } from '../index'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import {
  clearAllConsumerAppNotificationSuccess,
  deleteConsumerAppNotificationSuccess,
  getAllConsumerAppNotificationSuccess,
} from './actions'
import { LocalStorageAppNotification } from './types'

export const getAllNotificationFromIndexedDb = (): AppThunk<any> => async (dispatch, getState) => {
  const consumerId: string | undefined = getState().authReducer.consumer?.id
  if (consumerId) {
    try {
      let db: IDBDatabase | null = await createIndexedDb()
      if (db) {
        const transaction = db.transaction([indexedStore], 'readwrite')
        const objectStore = transaction.objectStore(indexedStore)
        const consumerInfo: IDBRequest<LocalStorageAppNotification> = objectStore.get(consumerId)
        consumerInfo.onsuccess = () => {
          if (consumerInfo && consumerInfo.result) {
            let asyncResult = consumerInfo.result.appNotifications
            dispatch(getAllConsumerAppNotificationSuccess(asyncResult ? asyncResult : []))
          } else {
            dispatch(getAllConsumerAppNotificationSuccess([]))
          }
        }
        consumerInfo.onerror = function () {
          console.error('Error when getting consumer notification')
          dispatch(setNotification(NotificationType.ERROR, 'Error while fetching notifications.'))
        }
        transaction.oncomplete = () => {
          console.log('Get Data Success')
        }
        transaction.onerror = () => {
          console.error('Transaction Error when getting consumer notification')
          dispatch(setNotification(NotificationType.ERROR, 'Error while fetching notifications.'))
        }
      }
    } catch (error) {
      console.error('Exception when getting form indexed db')
      dispatch(setNotification(NotificationType.ERROR, 'Error while fetching notifications.'))
    }
  }
}

export const clearAllNotificationFromIndexedDb =
  (consumerId: string): AppThunk<any> =>
  async (dispatch) => {
    try {
      let db: IDBDatabase | null = await createIndexedDb()
      if (db) {
        const transaction = db.transaction([indexedStore], 'readwrite')
        const objectStore = transaction.objectStore(indexedStore)
        const request = objectStore.delete(consumerId)
        request.onsuccess = () => {
          console.log('Deleted Success')
        }
        request.onerror = () => {
          console.error('Deleted Failed')
        }
        transaction.oncomplete = () => {
          dispatch(clearAllConsumerAppNotificationSuccess())
        }
        transaction.onerror = () => {
          console.error('error when Deleting')
        }
      }
    } catch (error) {
      console.error('Exception when Deleting form indexed db')
      dispatch(setNotification(NotificationType.ERROR, 'Error while clearing all notifications.'))
    }
  }

export const deleteMessageNotificationFromIndexedDb =
  (consumerId: string, messageId: string): AppThunk<any> =>
  async (dispatch) => {
    try {
      let db: IDBDatabase | null = await createIndexedDb()
      if (db) {
        const transaction = db.transaction([indexedStore], 'readwrite')
        const objectStore = transaction.objectStore(indexedStore)
        const consumerInfo: IDBRequest<LocalStorageAppNotification> = objectStore.get(consumerId)
        consumerInfo.onsuccess = function () {
          const updatedStorageItem = consumerInfo.result.appNotifications.filter((row) => row.messageId !== messageId)
          if (updatedStorageItem) {
            const storageItem: LocalStorageAppNotification = {
              consumerId: consumerId,
              appNotifications: updatedStorageItem,
            }
            objectStore.put(storageItem, consumerId)
          } else {
            objectStore.delete(consumerId)
          }
          const loadConsumerInfo: IDBRequest<LocalStorageAppNotification> = objectStore.get(consumerId)
          loadConsumerInfo.onsuccess = () => {
            if (loadConsumerInfo && loadConsumerInfo?.result) {
              let asyncResult = loadConsumerInfo?.result.appNotifications
              dispatch(deleteConsumerAppNotificationSuccess(asyncResult ? asyncResult : []))
            } else {
              dispatch(deleteConsumerAppNotificationSuccess([]))
            }
          }
        }
        consumerInfo.onerror = function () {
          console.error('error when deleting db - form indexed db')
          dispatch(setNotification(NotificationType.ERROR, 'Error while deleting notification.'))
        }
        transaction.oncomplete = () => {
          console.log('transaction complete')
        }
        transaction.onerror = () => {
          console.error('error when deleting db - form indexed db')
          dispatch(setNotification(NotificationType.ERROR, 'Error while deleting notification.'))
        }
      }
    } catch (error) {
      console.error('Exception when Deleting by id form indexed db')
      dispatch(setNotification(NotificationType.ERROR, 'Error while deleting notification.'))
    }
  }
