import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../store'

const useGoogleAnalytics = () => {
  const { tenantConfig } = useSelector(
    (state: RootState) => ({
      tenantConfig: state.appConfigReducer.tenantConfig,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (tenantConfig && tenantConfig.tagManager && tenantConfig.tagManager.tagManagerId) {
      const gtagScript = document.createElement('script')
      gtagScript.async = true
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${tenantConfig.tagManager.tagManagerId}`
      document.body.appendChild(gtagScript)
      const analyticsScript = document.createElement('script')
      analyticsScript.innerHTML = `
            window.dataLayer = window.dataLayer || []
            function gtag() {
                window.dataLayer.push(arguments)
            }
            gtag('js', new Date())
            gtag('config', '${tenantConfig.tagManager.tagManagerId}')
      `
      document.body.appendChild(analyticsScript)
    }
    // eslint-disable-next-line
  }, [tenantConfig])
}

export default useGoogleAnalytics
