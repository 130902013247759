export interface LoadingState {
  loading: boolean
}

export const SET_LOADING = 'SET_LOADING'

interface setLoading {
  type: typeof SET_LOADING
  payload: {
    loading: boolean
  }
}

export type LoadingActionTypes = setLoading
