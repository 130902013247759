import { BasePriceUnit } from '../../common/utility'
import { AccessControlType, LocationClass } from '../carpark-long-info/types'
import { AccessibleReason } from '../operating-hours/types'
import { Address, GeoMapLineString, LocationType, Point } from '../park-and-pay/types'

export const GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_REQUEST = 'GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_REQUEST'
export const GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_SUCCESS = 'GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_SUCCESS'

export const RESET_CONSUMER_FAVOURITE_CARPARK = 'RESET_CONSUMER_FAVOURITE_CARPARK'

export interface ConsumerFavouriteCarPark {
  id: string
  carParkId: string
  consumerId: string
}

export interface ConsumerFavouriteCarParkState {
  favouriteCarParkDetails: FavouriteCarParkDetails[] | null
  isRequestFinished: boolean
}

export interface FavouriteCarParkDetails {
  id: string
  code: string
  clientRef: string
  publicName: string
  publicId: string
  name: string
  operatorId: string
  basePriceLabel: string
  basePriceFee: number
  basePriceUnit: BasePriceUnit | null
  version: number
  clearance: number
  mainImageUrl: string | null
  mainSmallImageUrl: string | null
  timeZoneId: string
  address: Address
  directions: string
  gateImageUrl: string | null
  location: Point
  accessible: boolean
  accessibleReason?: AccessibleReason
  maxStayInMinutes: number
  endTimeMinutes: number
  locationClass: LocationClass
  accessControlType: AccessControlType
  geoMapType: LocationType
  geoMapPoint: Point
  geoMapLineString: GeoMapLineString
}

interface getConsumerFavouriteCarParkDetailsRequestAction {
  type: typeof GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_REQUEST
}

interface getConsumerFavouriteCarParkDetailsSuccessAction {
  type: typeof GET_CONSUMER_FAVOURITE_CARPARK_DETAILS_SUCCESS
  payload: {
    favouriteCarParkDetails: FavouriteCarParkDetails[] | null
  }
}

interface resetConsumerFavouriteCarParkAction {
  type: typeof RESET_CONSUMER_FAVOURITE_CARPARK
}

export type ConsumerFavouriteCarParkActionTypes =
  | getConsumerFavouriteCarParkDetailsRequestAction
  | getConsumerFavouriteCarParkDetailsSuccessAction
  | resetConsumerFavouriteCarParkAction
