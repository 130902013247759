import {
  GET_FACILITY_OPERATING_HOURS_REQUEST,
  GET_FACILITY_OPERATING_HOURS_SUCCESS,
  OperatingHoursActionTypes,
  OperatingHoursState,
} from './types'

const initialState: OperatingHoursState = {
  operatingHours: [],
}

export function operatingHoursReducer(
  state: OperatingHoursState = initialState,
  action: OperatingHoursActionTypes,
): OperatingHoursState {
  switch (action.type) {
    case GET_FACILITY_OPERATING_HOURS_REQUEST:
      return {
        ...state,
        operatingHours: [],
      }
    case GET_FACILITY_OPERATING_HOURS_SUCCESS:
      return {
        ...state,
        operatingHours: action.payload.data,
      }
    default:
      return state
  }
}
