import { PARK_API_CONFIG, TenantConfig } from '../../common/types'
import { RootState } from '../index'
import {
  AppConfigActionTypes,
  AppConfigState,
  ParkApiConfig,
  PaymentApiConfig,
  TENANT_CONFIG_REQUEST,
  TENANT_CONFIG_SUCCESS,
} from './types'

const initialState: AppConfigState = {
  tenantConfig: null,
  parkApiConfig: null,
  paymentApiConfig: null,
}

export function appConfigReducer(state: AppConfigState = initialState, action: AppConfigActionTypes): AppConfigState {
  function buildParkApiConfig(tenantConfig: TenantConfig): ParkApiConfig | null {
    if (tenantConfig.config.type === PARK_API_CONFIG) {
      return {
        coreUrl: tenantConfig.config.apiPath,
      }
    } else {
      return null
    }
  }

  function buildPaymentConfig(tenantConfig: TenantConfig): PaymentApiConfig | null {
    if (tenantConfig.config.type === PARK_API_CONFIG) {
      return {
        paymentApiUrl: tenantConfig.config.paymentApiPath ? tenantConfig.config.paymentApiPath : '/payment-api',
      }
    } else {
      return null
    }
  }

  switch (action.type) {
    case TENANT_CONFIG_REQUEST:
      return {
        ...state,
        tenantConfig: null,
      }
    case TENANT_CONFIG_SUCCESS:
      return {
        ...state,
        tenantConfig: action.payload.data,
        parkApiConfig: buildParkApiConfig(action.payload.data),
        paymentApiConfig: buildPaymentConfig(action.payload.data),
      }
    default:
      return state
  }
}

export const apiConfig = (state: RootState) => state.appConfigReducer.parkApiConfig
export const tenantConfig = (state: RootState) => state.appConfigReducer.tenantConfig

export const currentJurisdiction = (state: RootState) => {
  if (state.appConfigReducer.tenantConfig?.config?.type === PARK_API_CONFIG) {
    return state.appConfigReducer.tenantConfig?.config.jurisdictionCode
  } else {
    return null
  }
}

export const currentOperator = (state: RootState) => {
  if (state.appConfigReducer.tenantConfig?.config?.type === PARK_API_CONFIG) {
    return state.appConfigReducer.tenantConfig?.config.operatorId
  } else {
    return null
  }
}

export const currentTenant = (state: RootState) => {
  return state.appConfigReducer.tenantConfig?.tenant.id
}

export const currentApiKey = (state: RootState) => {
  return state.appConfigReducer.tenantConfig?.tenant.apiKey
}

export const currentUrl = (state: RootState) => {
  if (state.appConfigReducer.tenantConfig?.config?.type === PARK_API_CONFIG) {
    return state.appConfigReducer.tenantConfig?.config.apiPath
  } else {
    return null
  }
}

export const paymentUrl = (state: RootState) => {
  if (state.appConfigReducer.tenantConfig?.config?.type === PARK_API_CONFIG) {
    return state.appConfigReducer.tenantConfig?.config.paymentApiPath
  } else {
    return null
  }
}

export const currentFirebaseApiKey = (state: RootState) => {
  return state.appConfigReducer.tenantConfig?.firebase.apiKey
}

export const firebaseTenantId = (state: RootState) => {
  return state.appConfigReducer.tenantConfig?.firebaseAuth.tenantId
}
