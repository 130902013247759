export interface CalculateRefundResponse {
  allowCancel: boolean
  cancelDeadlineInstant: number
  refundAmount: number
}

export interface CancelBookingReq {
  refundAmount: number
}

export interface CancelBookingState {
  calculateRefundResponse: CalculateRefundResponse | null
}

export const CALCULATE_REFUND_API_RESOURCE_PATH =
  'operators/:operatorId/consumers/:consumerId/my-orders/:orderId:calculateRefund'
export const CALCULATE_PUBLIC_REFUND_API_RESOURCE_PATH = 'public/operators/:operatorId/orders/:orderId:calculateRefund'
export const CANCEL_BOOKING_API_RESOURCE_PATH = 'operators/:operatorId/consumers/:consumerId/my-orders/:orderId:cancel'
export const CANCEL_PUBLIC_BOOKING_API_RESOURCE_PATH = 'public/operators/:operatorId/orders/:orderId:cancel'

export const CALCULATE_REFUND_REQUEST = 'CALCULATE_REFUND_REQUEST'
export const CALCULATE_REFUND_SUCCESS = 'CALCULATE_REFUND_SUCCESS'
export const RESET_CALCULATED_REFUND = 'RESET_CALCULATED_REFUND'

export interface calculateRefundRequest {
  type: typeof CALCULATE_REFUND_REQUEST
}

export interface calculateRefundSuccess {
  type: typeof CALCULATE_REFUND_SUCCESS
  payload: {
    data: CalculateRefundResponse
  }
}
export interface resetCalculatedRefund {
  type: typeof RESET_CALCULATED_REFUND
}

export type CancelBookingActionTypes = calculateRefundRequest | calculateRefundSuccess | resetCalculatedRefund
