import {
  CALCULATE_REFUND_REQUEST,
  CALCULATE_REFUND_SUCCESS,
  CancelBookingActionTypes,
  CancelBookingState,
  RESET_CALCULATED_REFUND,
} from './types'

const initialState: CancelBookingState = {
  calculateRefundResponse: null,
}

export function cancelBookingReducer(
  state: CancelBookingState = initialState,
  action: CancelBookingActionTypes,
): CancelBookingState {
  switch (action.type) {
    case CALCULATE_REFUND_REQUEST:
      return {
        ...state,
        calculateRefundResponse: null,
      }
    case CALCULATE_REFUND_SUCCESS:
      return {
        ...state,
        calculateRefundResponse: action.payload.data,
      }
    case RESET_CALCULATED_REFUND:
      return initialState
    default:
      return state
  }
}
