import { GET_TERRITORIES_REQUEST, GET_TERRITORIES_SUCCESS, TerritoriesActionTypes, Territory } from './types'

export function getTerritoriesRequest(): TerritoriesActionTypes {
  return {
    type: GET_TERRITORIES_REQUEST,
  }
}

export function getTerritoriesSuccess(data: Territory[]): TerritoriesActionTypes {
  return {
    type: GET_TERRITORIES_SUCCESS,
    payload: {
      data: data,
    },
  }
}
