import { QuoteActionTypes } from './reducers'
import {
  GET_QUOTE_FAILURE,
  GET_QUOTE_REQUEST,
  GET_QUOTE_SUCCESS,
  ParkingQuoteDto,
  RESET_QUOTE,
  SET_QUOTE_EXPIRED,
} from './types'

export function getQuoteRequest(): QuoteActionTypes {
  return {
    type: GET_QUOTE_REQUEST,
  }
}

export function getQuoteSuccess(data: ParkingQuoteDto): QuoteActionTypes {
  return {
    type: GET_QUOTE_SUCCESS,
    payload: {
      quote: data,
    },
  }
}

export function getQuoteFailure(): QuoteActionTypes {
  return {
    type: GET_QUOTE_FAILURE,
  }
}

export function setQuoteExpired(): QuoteActionTypes {
  return {
    type: SET_QUOTE_EXPIRED,
  }
}

export function resetQuote(): QuoteActionTypes {
  return {
    type: RESET_QUOTE,
  }
}
