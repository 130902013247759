import {
  EmailVerificationActionTypes,
  EmailVerificationState,
  GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_REQUEST,
  GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_SUCCESS,
  GET_SESSION_BY_ID_REQUEST,
  GET_SESSION_BY_ID_SUCCESS,
  RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_REQUEST,
  RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_SUCCESS,
  RESEND_EMAIL_VERIFICATION_REQUEST,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_MOBILE_VERIFICATION_CODE_REQUEST,
  RESEND_MOBILE_VERIFICATION_CODE_SUCCESS,
  RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_REQUEST,
  RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_SUCCESS,
  UPDATE_EMAIL_VERIFICATION_REQUEST,
  UPDATE_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_REQUEST,
  UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_SUCCESS,
} from './types'

const initialState: EmailVerificationState = {
  session: null,
  emailVerificationResponse: null,
  mobileVerificationResponse: null,
}

export function emailVerificationReducer(
  state: EmailVerificationState = initialState,
  action: EmailVerificationActionTypes,
): EmailVerificationState {
  switch (action.type) {
    case RESEND_EMAIL_VERIFICATION_REQUEST:
      return {
        ...state,
        emailVerificationResponse: null,
      }
    case RESEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerificationResponse: action.payload.data,
      }
    case GET_SESSION_BY_ID_REQUEST:
      return {
        ...state,
        session: null,
      }
    case GET_SESSION_BY_ID_SUCCESS:
      return {
        ...state,
        session: action.payload.data,
      }
    case UPDATE_EMAIL_VERIFICATION_REQUEST:
      return {
        ...state,
        emailVerificationResponse: null,
      }
    case UPDATE_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerificationResponse: action.payload.data,
      }
    case RESEND_MOBILE_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        mobileVerificationResponse: null,
      }
    case RESEND_MOBILE_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        mobileVerificationResponse: action.payload.data,
      }
    case UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_REQUEST:
      return {
        ...state,
        mobileVerificationResponse: null,
      }
    case UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_SUCCESS:
      return {
        ...state,
        mobileVerificationResponse: action.payload.data,
      }
    case RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_REQUEST:
      return {
        ...state,
        emailVerificationResponse: null,
      }
    case RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_SUCCESS:
      return {
        ...state,
        emailVerificationResponse: action.payload.data,
      }
    case RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_REQUEST:
      return {
        ...state,
        mobileVerificationResponse: null,
      }
    case RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_SUCCESS:
      return {
        ...state,
        mobileVerificationResponse: action.payload.data,
      }
    case GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_REQUEST:
      return {
        ...state,
        mobileVerificationResponse: null,
      }
    case GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_SUCCESS:
      return {
        ...state,
        mobileVerificationResponse: action.payload.data,
      }
    default:
      return state
  }
}
