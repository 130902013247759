import { RootState } from '../index'
import {
  AppNotificationActionsTypes,
  AppNotificationState,
  CLEAR_CONSUMER_APP_NOTIFICATIONS_SUCCESS,
  DELETE_CONSUMER_APP_NOTIFICATIONS_SUCCESS,
  GET_ALL_CONSUMER_APP_NOTIFICATIONS_SUCCESS,
} from './types'

const initialState: AppNotificationState = {
  appNotifications: [],
}

export function appNotificationReducer(
  state: AppNotificationState = initialState,
  action: AppNotificationActionsTypes,
): AppNotificationState {
  switch (action.type) {
    case GET_ALL_CONSUMER_APP_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        appNotifications: action.payload.appNotifications,
      }
    case CLEAR_CONSUMER_APP_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        appNotifications: [],
      }
    case DELETE_CONSUMER_APP_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        appNotifications: action.payload.appNotifications,
      }
    default:
      return state
  }
}

export const currentAppNotification = (state: RootState) => {
  return state.appNotificationReducer.appNotifications
}
