import { TenantConfig } from '../../common/types'

export interface ParkApiConfig {
  coreUrl: string
}
export interface PaymentApiConfig {
  paymentApiUrl: string
}

export interface AppConfigState {
  tenantConfig: TenantConfig | null
  parkApiConfig: ParkApiConfig | null
  paymentApiConfig: PaymentApiConfig | null
}

export const TENANT_CONFIG_REQUEST = 'TENANT_CONFIG_REQUEST'
export const TENANT_CONFIG_SUCCESS = 'TENANT_CONFIG_SUCCESS'

interface TenantConfigRequestAction {
  type: typeof TENANT_CONFIG_REQUEST
}

interface TenantConfigSuccessAction {
  type: typeof TENANT_CONFIG_SUCCESS
  payload: {
    data: TenantConfig
  }
}

export type AppConfigActionTypes = TenantConfigRequestAction | TenantConfigSuccessAction
