export const GET_ENTITLEMENT_VEHICLE_REQUEST = 'GET_ENTITLEMENT_VEHICLE_REQUEST'
export const GET_ENTITLEMENT_VEHICLE_SUCCESS = 'GET_ENTITLEMENT_VEHICLE_SUCCESS'

export interface EntitlementVehicle {
  id: string | null
  lprCredentialValue: string
  makeCode: string
  plate: string
  plateIssuer: string
}

interface getEntitlementVehicleRequestAction {
  type: typeof GET_ENTITLEMENT_VEHICLE_REQUEST
}

interface getEntitlementVehicleSuccessAction {
  type: typeof GET_ENTITLEMENT_VEHICLE_SUCCESS
  payload: {
    vehicles: EntitlementVehicle[]
  }
}

export interface EntitlementVehicleState {
  vehicles: EntitlementVehicle[]
}

export type EntitlementVehicleActionTypes = getEntitlementVehicleRequestAction | getEntitlementVehicleSuccessAction
