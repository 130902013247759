import {
  AccessCredentialsActionTypes,
  AccessCredentialsState,
  GET_CONSUMER_ACCESS_CREDENTIALS_REQUEST,
  GET_CONSUMER_ACCESS_CREDENTIALS_SUCCESS,
} from './types'

const initialState: AccessCredentialsState = {
  accessCredentials: [],
}

export function accessCredentialsReducer(
  state: AccessCredentialsState = initialState,
  action: AccessCredentialsActionTypes,
): AccessCredentialsState {
  switch (action.type) {
    case GET_CONSUMER_ACCESS_CREDENTIALS_REQUEST:
      return {
        ...state,
        accessCredentials: [],
      }
    case GET_CONSUMER_ACCESS_CREDENTIALS_SUCCESS:
      return {
        ...state,
        accessCredentials: action.payload.data,
      }
    default:
      return state
  }
}
